/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContactFormCreateModel,
    ContactFormCreateModelFromJSON,
    ContactFormCreateModelToJSON,
    ContactFormDetailModel,
    ContactFormDetailModelFromJSON,
    ContactFormDetailModelToJSON,
    ContactFormDetailModelPagedDataModel,
    ContactFormDetailModelPagedDataModelFromJSON,
    ContactFormDetailModelPagedDataModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SearchModel,
    SearchModelFromJSON,
    SearchModelToJSON,
} from '../models';

export interface ContactFormFormIdGetRequest {
    formId: number;
}

export interface ContactFormPostRequest {
    contactFormCreateModel?: ContactFormCreateModel;
}

export interface ContactFormSearchPostRequest {
    searchModel?: SearchModel;
}

/**
 * 
 */
export class ContactFormApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async contactFormFormIdGetRaw(requestParameters: ContactFormFormIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContactFormDetailModel>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling contactFormFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contact-form/{formId}`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFormDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async contactFormFormIdGet(requestParameters: ContactFormFormIdGetRequest, initOverrides?: RequestInit): Promise<ContactFormDetailModel> {
        const response = await this.contactFormFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( ContactForm = contactform )
     */
    async contactFormPostRaw(requestParameters: ContactFormPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContactFormCreateModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contact-form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactFormCreateModelToJSON(requestParameters.contactFormCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFormCreateModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( ContactForm = contactform )
     */
    async contactFormPost(requestParameters: ContactFormPostRequest = {}, initOverrides?: RequestInit): Promise<ContactFormCreateModel> {
        const response = await this.contactFormPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async contactFormSearchPostRaw(requestParameters: ContactFormSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ContactFormDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/contact-form/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchModelToJSON(requestParameters.searchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactFormDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async contactFormSearchPost(requestParameters: ContactFormSearchPostRequest = {}, initOverrides?: RequestInit): Promise<ContactFormDetailModelPagedDataModel> {
        const response = await this.contactFormSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
