/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentModel,
    DocumentModelFromJSON,
    DocumentModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
} from '../models';

export interface DocumentDocumentIdDownloadGetRequest {
    documentId: number;
}

export interface DocumentDocumentIdGetRequest {
    documentId: number;
}

export interface DocumentMachineDocumentDocumentIdGetRequest {
    documentId: number;
}

export interface DocumentPostRequest {
    document?: Blob;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async documentDocumentCategoriesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/document-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Admin
     */
    async documentDocumentCategoriesGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.documentDocumentCategoriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async documentDocumentIdDownloadGetRaw(requestParameters: DocumentDocumentIdDownloadGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentDocumentIdDownloadGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/{documentId}/download`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async documentDocumentIdDownloadGet(requestParameters: DocumentDocumentIdDownloadGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentDocumentIdDownloadGetRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Lessor, Customer
     */
    async documentDocumentIdGetRaw(requestParameters: DocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentDocumentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin, Lessor, Customer
     */
    async documentDocumentIdGet(requestParameters: DocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentDocumentIdGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentMachineDocumentDocumentIdGetRaw(requestParameters: DocumentMachineDocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentMachineDocumentDocumentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/document/machine-document/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentMachineDocumentDocumentIdGet(requestParameters: DocumentMachineDocumentDocumentIdGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentMachineDocumentDocumentIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Lessor, Customer
     */
    async documentPostRaw(requestParameters: DocumentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor, Customer
     */
    async documentPost(requestParameters: DocumentPostRequest = {}, initOverrides?: RequestInit): Promise<DocumentModel> {
        const response = await this.documentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
