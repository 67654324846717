import { Col, Radio, Row } from "antd";
import Accordion from "../../../components/atomics/accordion";
import ListContainer from "../../../components/containers/list-container";
import { useEffect, useState } from "react";
import { useSecureService } from "../../../store/service-atoms";
import { CategoryModel, MachineSaleCommissionCreateModel, MachineSaleCommissionViewModel } from "../../../service";
import { t } from "../../../translate";
import MachineSaleCommissionModal from "../../../modals/machine-sale-commission";

const ListForSaleCommissionRates = () => {
    const [accordionItems, setAccordionItems] = useState<any[] | undefined>(undefined);
    const [allMachineCategory, setAllMachineCategories] = useState<CategoryModel[] | undefined>(undefined);
    const { doGetMachineSubcategory, doGetMachineCommissionsForSale } = useSecureService();
    const [showCommissionModal, setShowCommissionModal] = useState<boolean>(false);
    const [allMachineCommissions, setAllMachineCommissions] = useState<MachineSaleCommissionViewModel[] | undefined>(undefined);
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState<string>("");
    const [selectedMachineCommission, setSelectedMachineCommission] = useState<MachineSaleCommissionCreateModel | undefined>(undefined);

    useEffect(() => {
        const getCommissionRates = async () => {
            const result = await doGetMachineCommissionsForSale();
            if (result) setAllMachineCommissions(result);
        };
        const setSubCategories = async () => {
            const result = await doGetMachineSubcategory();
            if (result) setAllMachineCategories(result);
        };
        setSubCategories();
        getCommissionRates();
        // eslint-disable-next-line
    }, [showCommissionModal]);

    useEffect(() => {
        const AccordionItems = allMachineCategory?.map((category) => {
            const radios = category.subCategories?.map((sub) => {
                if (sub && sub.id && sub.name)
                    return CreateSubcategoryFields(
                        category.id,
                        sub.id,
                        sub.name,
                        allMachineCommissions?.find((val) => {
                            return val.subCategoryId === sub.id;
                        })
                    );
                else return undefined;
            });
            const returnObj = {
                title: category.name,
                key: category.id,
                children: (
                    <Row gutter={[16, 16]} key={rowKey} style={{ marginTop: "2%" }}>
                        {radios}
                    </Row>
                ),
                description: category.subCategories
                    ?.map((sub) => " " + sub.name)
                    .toString()
                    .trim(),
                imageUrl: "img" + category.id + ".png",
            };
            rowKey++;
            return returnObj;
        });
        setAccordionItems(AccordionItems);
        // eslint-disable-next-line
    }, [allMachineCommissions, allMachineCategory]);

    const CreateSubcategoryFields = (catId?: number, subId?: number, content?: string, commissions?: MachineSaleCommissionViewModel) => {
        const rentalTypeFieldStyle = {
            backgroundColor: "#e8e8e8",
            borderRadius: "0.4rem",
            height: "2rem",
            textAlign: "center" as const,
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "0.8%",
            color: "#949394",
            fontSize: "0.8rem",
        };

        let commissionModel: MachineSaleCommissionViewModel = {
            categoryId: catId,
            subCategoryId: subId,
            priceRate: 0,
        };

        return (
            <Col
                xs={23}
                lg={11}
                style={{
                    border: "1px solid #949394",
                    borderRadius: "1rem",
                    paddingInline: "1%",
                    paddingBlock: "1%",
                    marginInline: "2%",
                    marginBlock: "1%",
                }}
            >
                <Row justify="space-between" style={{ marginBottom: "2%", textAlign: "center" }}>
                    <Col>{content}</Col>
                    <Col>
                        <button
                            onClick={() => {
                                setSelectedMachineCommission(commissions ?? commissionModel);
                                setSelectedSubCategoryName(content ?? "");
                                setShowCommissionModal(true);
                            }}
                            style={{ backgroundColor: "transparent", border: "none", color: "#feb111", cursor: "pointer" }}
                        >
                            {commissions ? t("common.edit") : t("commissionRate-set-commission")}
                        </button>
                    </Col>
                </Row>
                <Row gutter={[4, 4]} justify="space-around" align="middle">
                    <Col md={8} xs={10} style={rentalTypeFieldStyle}>
                        {commissions ? t("machines.commission.for.sale") + ": " + commissions?.priceRate + "%" : "-"}
                    </Col>
                </Row>
            </Col>
        );
    };

    let rowKey = 0;

    return (
        <ListContainer>
            <h3 style={{ fontWeight: "bold", marginBottom: "5%" }}>{t("commissionRate-sale-page-title")}</h3>
            <Radio.Group>
                <Accordion items={accordionItems} />
            </Radio.Group>
            <MachineSaleCommissionModal
                commission={selectedMachineCommission}
                subCategoryName={selectedSubCategoryName}
                show={showCommissionModal}
                onCloseClick={() => {
                    setShowCommissionModal(false);
                }}
            />
        </ListContainer>
    );
};

export default ListForSaleCommissionRates;
