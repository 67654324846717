import { Row, Col } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../../components/containers/list-container";
import { useQuery } from "../../../../helpers/use-query";
import LessorCompanyInfoPart from "../../../../page-parts/view-lessor-company-info-part";
import { LessorCompanyDetailViewModel, LessorUserViewModel } from "../../../../service";
import { useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../../components/assets/left-arrow.svg";
import { ReactComponent as AuthorisedIcon } from "./../../../../components/assets/approve.svg";
import UpdateLessorAuthorizationModal from "../../../../modals/update-lessor-authorization-modal";
import _ from "lodash";
import DeleteAccountModal from "../../../../modals/delete-account-modal";
import { postloginMessageAtom } from "../../../../store/global-atoms";
import { useAtom } from "jotai";
import styles from "./view.module.scss";

type TableModel = {
    fullName?: string | null | undefined;
    isAuthorizedForSaleIcon?: ReactNode;
} & LessorUserViewModel;

type LessorOwnerInfo = {
    id: number,
    isDeleted: boolean, 
}

const castToTableModelArray = (data: TableModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        const tableModel: TableModel = {
            fullName: item.name + " " + item.lastname,
            isAuthorizedForSaleIcon: item.isAuthorisedForSale ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><AuthorisedIcon></AuthorisedIcon></div> : undefined,
            ...item,
        };
        return tableModel;
    });
    return newList;
};

const ViewLessorCompany = () => {
    const query = useQuery();
    const [companyUsers, setCompanyUsers] = useState<TableModel[] | undefined>(undefined);
    const companyId = query.get("compid");
    const [dataFetching, setDataFetching] = useState(true);
    const [lessorCompany, setLessorCompany] = useState<LessorCompanyDetailViewModel | undefined>(undefined);
    const [isBlocked, setIsBlocked] = useState<boolean | undefined>();
    const companyIdN = parseInt(companyId || "NaN");
    const userId = lessorCompany?.companyOwner?.id;
    const { doGetLessorCompanyUsers, doGetLessorBlocked, doBlockLessorCompany, doAuthorizeLessorsForSale } = useSecureService();
    const { doGetLessorCompanyDetail, doDeleteLessorUser } = useSecureService();
    const [showUpdateLessorAuthorizationModal, setShowUpdateLessorAuthorizationModal] = useState(false);
    const [lessorUsers, setLessorUsers] = useState<LessorUserViewModel[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [, setAlertMesssage] = useAtom(postloginMessageAtom);
    const [companyOwner, setCompanyOwner] = useState<LessorOwnerInfo | null>(null);
    useEffect(() => {
        if (!Number.isNaN(companyIdN)) {
            doGetLessorCompanyDetail(companyIdN).then((data) => {
                setLessorCompany(data);
            }).finally(() => {setDataFetching(false)});
        } else {
            setDataFetching(false);
        }

        //eslint-disable-next-line
    }, []);

    const fillFormsList = async () => {
        try {
            const result = await doGetLessorCompanyUsers(companyIdN);
            if (result !== null) {
                const owner = result?.find(x => x.isManager);
                if (owner?.id && owner.isDeleted !== undefined) {
                    setCompanyOwner({id: owner?.id, isDeleted: owner?.isDeleted});
                }
                setLessorUsers(result ?? []);
                setCompanyUsers(castToTableModelArray(result));
            }
        } catch {}
    };

    useEffect(() => {
        fillFormsList();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (userId) {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        }
        //eslint-disable-next-line
    }, [lessorCompany]);
    const columns = [
        {
            title: t("companies.table.nameLastName"),
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: t("companies.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("companies.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("companies.table.role"),
            dataIndex: "title",
            key: "title",
        },
        {
            title: t("companies.table.authorised-seller"),
            dataIndex: "isAuthorizedForSaleIcon",
            key: "isAuthorizedForSaleIcon",
        },
    ];
    const onBlockClick = async () => {
        if (userId === undefined) return;
        doBlockLessorCompany(userId).then(() => {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        });
    };
    const navigate = useNavigate();
    const onRentalMachineClick = () => {
        navigate("/rent/machines?compname=" + lessorCompany?.companyName);
    };
    const onSaleMachineClick = () => {
        navigate("/sale/machines?compname=" + lessorCompany?.companyName);
    };
    const onOffersClick = () => {
        navigate("/rent/offers?compname=" + lessorCompany?.companyName);
    };

    const onClickShowUpdateLessorAuth = () => {
        setShowUpdateLessorAuthorizationModal(true);
    }

    const onUpdateLessorAuthorization = async (lessors: number[]) => {
        const unauthorisedLessors = _.compact(lessorUsers?.map(e => {
            if (lessors.some(x => x === e.id)) {
                return undefined;
            } else {
                return e.id;
            }
        })); 

        const result = await doAuthorizeLessorsForSale(lessors, unauthorisedLessors, companyIdN);

        if (result) {
            setShowUpdateLessorAuthorizationModal(false);
            fillFormsList();
        } 

    }

    const onClickLessorDelete = () => {
        setShowDeleteModal(true);
    }

    const doDeleteLessor = async ()  => {
        if (companyOwner !== null) {
            const result = await doDeleteLessorUser(companyOwner.id);

            if (result) {
                setAlertMesssage({message: t('common.lessor-deleteSuccess'), type: 'success'});
                navigate(-1);
            }
        }
    }

    return (
        <ListContainer loading={dataFetching}>
            <Row justify="space-between" align="middle">
                <Col>
                    <div
                        style={{ marginBottom: "1rem", cursor: "pointer" }}
                        onClick={() => {
                            navigate("/lessor-company");
                        }}
                    >
                        <ArrowLeft style={{ marginBottom: "0.15rem" }}></ArrowLeft>
                        <span style={{ marginLeft: "0.75rem", fontWeight: "700", fontSize: "1.5rem" }}>{t("menu.listLessors")}</span>
                    </div>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Col style={{ float: "right" }}>
                                <SecondaryButton onClick={onSaleMachineClick} style={{marginRight: "0.5rem",  width: "inherit"}}>
                                    {t("companies.detail.sale.machines")}
                                </SecondaryButton>
                            </Col>
                        </Col>
                        <Col style={{ float: "right" }}>
                            <SecondaryButton onClick={onRentalMachineClick} style={{ width: "inherit" }}>
                                {t("companies.detail.rental.machines")}
                            </SecondaryButton>
                        </Col>
                    </Row>
                </Col>
                <Row justify="end" style={{width: '100%', paddingBottom: '16px'}}>
                    <Col className={styles['status-container']} hidden={!companyOwner?.isDeleted} style={{ float: "right" }} md={4} xs={24}>
                        <span>
                            {t("common.deletedStatus")}
                        </span>
                    </Col>
                </Row>
                <Col>
                    <LessorCompanyInfoPart
                        viewType="lessorCompany"
                        isBlocked={isBlocked}
                        lessorCompany={lessorCompany}
                        compId={companyIdN}
                        userId={userId || 0}
                        dataSource={companyUsers}
                        dataColumns={columns}
                        onClickOfferFormList={onOffersClick}
                        onBlockClick={onBlockClick}
                        onClickShowUpdateLessorAuth={onClickShowUpdateLessorAuth}
                        showDeleteLessorButton={!companyOwner?.isDeleted}
                        onClickLessorDelete={onClickLessorDelete}
                    ></LessorCompanyInfoPart>
                </Col>
                <UpdateLessorAuthorizationModal 
                    onCloseClick={() => {
                        setShowUpdateLessorAuthorizationModal(false);
                    } }
                    key={'lessor-auth-modal-' + showUpdateLessorAuthorizationModal}
                    show={showUpdateLessorAuthorizationModal}
                    lessors={lessorUsers} 
                    onSaveClick={onUpdateLessorAuthorization} />
                <DeleteAccountModal 
                    key={'delete-modal-' + showDeleteModal}
                    type={'manager'}
                    hasAnyOffer={lessorCompany?.hasAnyOffer}
                    onCloseClick={() => setShowDeleteModal(false)}
                    onDeleteClick={doDeleteLessor}
                    show={showDeleteModal}
                />
            </Row>
        </ListContainer>
    );
};

export default ViewLessorCompany;
