import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../../../components/containers/list-container";
import { useQuery } from "../../../../helpers/use-query";
import RenterCompanyInfoPart from "../../../../page-parts/view-renter-company-info-part";
import { RenterCompanyDetailViewModel } from "../../../../service";
import { useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../../components/assets/left-arrow.svg";
import { useAtom } from "jotai";
import { postloginMessageAtom } from "../../../../store/global-atoms";
import DeleteAccountModal from "../../../../modals/delete-account-modal";
import styles from "./view.module.scss"

const ViewRenterCompany = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const [renterCompany, setRenterCompany] = useState<RenterCompanyDetailViewModel | undefined>(undefined);
    const [isBlocked, setIsBlocked] = useState<boolean | undefined>();
    const companyIdN = parseInt(companyId || "NaN");
    const [dataFetching, setDataFetching] = useState(true);
    const { doGetLessorBlocked, doBlockLessorCompany, doDeleteRenterUser } = useSecureService();
    const { doGetRenterCompanyDetail } = useSecureService();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [, setAlertMesssage] = useAtom(postloginMessageAtom);

    useEffect(() => {
        if (!Number.isNaN(companyIdN)) {
            doGetRenterCompanyDetail(companyIdN).then((data) => {
                setRenterCompany(data);
            }).finally(() => {setDataFetching(false)});
        } else {
            setDataFetching(false);
        }

        //eslint-disable-next-line
    }, []);

    const userId = renterCompany?.id;
    useEffect(() => {
        if (userId) {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        }
        //eslint-disable-next-line
    }, []);

    const onBlockClick = async () => {
        if (userId === undefined) return;
        doBlockLessorCompany(userId).then(() => {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        });
    };
    const navigate = useNavigate();
    const onRequestsClick = () => {
        navigate("/rent/requests?compname=" + renterCompany?.companyName);
    };

    const onClickRenterDelete = () => {
        setShowDeleteModal(true);
    }

    const doDeleteRenter = async ()  => {
        const result = await doDeleteRenterUser(userId ?? -1);

        if (result) {
            setAlertMesssage({message: t('common.customer-deleteSuccess'), type: 'success'});
            navigate(-1);
        }
    }

    return (
        <ListContainer loading={dataFetching}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col>
                    <div
                        style={{ marginBottom: "1rem", cursor: "pointer" }}
                        onClick={() => {
                            navigate("/renter-company");
                        }}
                    >
                        <ArrowLeft style={{ marginBottom: "0.15rem" }}></ArrowLeft>
                        <span style={{ marginLeft: "0.75rem", fontWeight: "700", fontSize: "1.5rem" }}>{t("menu.listRenters")}</span>
                    </div>
                </Col>
                <Col hidden={renterCompany?.isDeleted !== true} key={'status-text-' + renterCompany?.isDeleted} className={styles['status-container']} xs={24} md={4}>
                    <Col className={styles['status-inner-container']} xs={8} md={24}>
                        <span className={styles['status-text']}>{t('common.deletedStatus')}</span>
                    </Col>
                </Col>
                <Col md={24}>
                    <RenterCompanyInfoPart
                        viewType="lessorCompany"
                        isBlocked={isBlocked}
                        renterCompany={renterCompany}
                        compId={companyIdN}
                        onClickRequestFormList={onRequestsClick}
                        userId={userId || 0}
                        onBlockClick={onBlockClick}
                        onClickRenterDelete={onClickRenterDelete}
                        isDeleted={renterCompany?.isDeleted ?? false}
                    ></RenterCompanyInfoPart>
                </Col>
            </Row>
            <DeleteAccountModal 
                key={'delete-modal-' + showDeleteModal}
                type={'customer'}
                hasAnyRequest={renterCompany?.hasAnyRequest}
                onCloseClick={() => setShowDeleteModal(false)}
                onDeleteClick={doDeleteRenter}
                show={showDeleteModal}
            />
        </ListContainer>
    );
};

export default ViewRenterCompany;
