import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import ListContainer from "../../../components/containers/list-container";
import BoxContainer from "../../../components/containers/box-container";
import { t } from "../../../translate";
import { useEffect, useRef, useState } from "react";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, CategoryModel, ReportMachinesModel } from "../../../service";
import { useAtom } from "jotai";
import { loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { CSVLink } from "react-csv";

interface FormValues {
    lessorCompanyIds: number[];
    CompanyInfoToDisplay: string[];
    machineCategoryIds: number[];
    MachineInfoToDisplay: string[];
    machineRequestedImageCount: number;
    MachinePriceInfoToDisplay: string[];
    MachineCurrencyInfoToDisplay: string[];
}

const MachinesReports = () => {
    const { Option } = Select;

    const CompnayInfoDisplayOptions = [
        { value: "lessorCompanyName", label: t("reports.machines-reports.companyInformation.companyName") },
        { value: "lessorCompanyContactName", label: t("reports.machines-reports.companyInformation.companyContact") },
        { value: "lessorCompanyPhone", label: t("reports.machines-reports.companyInformation.companyPhone") },
        { value: "lessorCompanyEmail", label: t("reports.machines-reports.companyInformation.companyMail") },
        {
            value: "lessorCompanyRegisteredMachineCount",
            label: t("reports.machines-reports.companyInformation.companyRegisteredMachineCount"),
        },
        { value: "lessorCompanyLocation", label: t("reports.machines-reports.companyInformation.companyLocation") },
        { value: "lessorCompanyCreationDate", label: t("reports.machines-reports.companyInformation.creationDate") },
    ];

    const ReportDateTime = `MachineReport - ${new Date().toLocaleString("tr-TR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    })}.csv`;
    // Define the options for the machine info checkbox field
    const machineInfoOptions = [
        { value: "machineId", label: t("reports.machines-reports.machineInformation.machineId") },
        { value: "machineBrand", label: t("reports.machines-reports.machineInformation.brand") },
        { value: "machineModel", label: t("reports.machines-reports.machineInformation.model") },
        { value: "machineApproval", label: t("reports.machines-reports.machineInformation.approval") },
        { value: "machineServiceRegion", label: t("reports.machines-reports.machineInformation.serviceRegion") },
        { value: "machineCreationDate", label: t("reports.machines-reports.machineInformation.creationDate") },
        { value: "machineModifiedDate", label: t("reports.machines-reports.machineInformation.modifiedDate") },
        { value: "machineApprovalDate", label: t("reports.machines-reports.machineInformation.approvalDate") },
    ];
    // Define the options for the price info checkbox field
    const priceInfoOptions = [
        { value: "hourly", label: t("reports.machines-reports.machinePriceInformation.pricePeriod.hourly") },
        { value: "daily", label: t("reports.machines-reports.machinePriceInformation.pricePeriod.daily") },
        { value: "monthly", label: t("reports.machines-reports.machinePriceInformation.pricePeriod.monthly") },
        { value: "yearly", label: t("reports.machines-reports.machinePriceInformation.pricePeriod.yearly") },
    ];

    const currencyInfoOptions = [
        { value: "TL", label: "TL" },
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
    ];

    const [loading] = useAtom(loadingAtom);
    const [buttonLoading, setButtonLoading] = useState<any>(false);
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [companies, setCompanies] = useState<undefined | BasicModel[]>(undefined);
    //eslint-disable-next-line
    const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>();
    const { doGetMachineSubcategory, doGetLessorCompanyShortList, doCreateReport } = useSecureService();
    const [allRows, setAllRows] = useState<any>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const createReport = [] as ReportMachinesModel;
    const [, setMessage] = useAtom(postloginMessageAtom);

    const exportClick = async (reportMachinesModel: ReportMachinesModel) => {
        const result = await doCreateReport(reportMachinesModel);
        if (result) {
            if (result.length === 0) {
                setButtonLoading(false);
                setMessage({
                    message: "Seçilen şirketin, seçilen makine türünde bir makinesi bulunmamaktadır.",
                    type: "error",
                });
            } else {
                const formattedResult = result.map((item) => ({
                    ...item,
                    lessorCompanyCreationDate: formatDate(item.lessorCompanyCreationDate),
                    machineCreationDate: formatDate(item.machineCreationDate),
                    machineModifiedDate: formatDate(item.machineModifiedDate),
                    machineApprovalDate: formatDate(item.machineApprovalDate),
                }));
                setAllRows(formattedResult);
                csvRef.current?.link.click();
                setMessage({
                    message: "Raporunuz Başarıyla Oluşturuldu",
                    type: "success",
                });
                setButtonLoading(false);
            }
        } else {
            setButtonLoading(false);
            setMessage({
                message: "Rapor Oluşturulurken Hata Oluştu",
                type: "error",
            });
        }
    };
    useEffect(() => {
        const getCompanies = async () => {
            const comps = await doGetLessorCompanyShortList();
            comps?.push({ id: 0, name: "Tümü" });
            setCompanies(comps?.reverse());
        };

        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            result?.push({ id: 0, name: "Tümü" });
            setAllCategories(result?.reverse());
        };
        getCategoriesAndSubcategories();
        getCompanies();
        // eslint-disable-next-line
    }, []);

    const onSelectChange = (value: number | undefined) => {
        setSelectedCompanyId(value);
    };

    function formatDate(date: Date | null | undefined): string {
        console.log(date);
        if (!date || isNaN(date.getTime())) return "Tarih Yok";
      
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
      
        return `${day}.${month}.${year}`;
      }

    const onFinish = (values: FormValues) => {
        setButtonLoading(true);
        createReport.lessorCompanyIds = values.lessorCompanyIds;
        createReport.lessorCompanyName = values.CompanyInfoToDisplay.includes("lessorCompanyName");
        createReport.lessorCompanyContactName = values.CompanyInfoToDisplay.includes("lessorCompanyContactName");
        createReport.lessorCompanyPhone = values.CompanyInfoToDisplay.includes("lessorCompanyPhone");
        createReport.lessorCompanyEmail = values.CompanyInfoToDisplay.includes("lessorCompanyEmail");
        createReport.lessorCompanyRegisteredMachineCount = values.CompanyInfoToDisplay.includes("lessorCompanyRegisteredMachineCount");
        createReport.lessorCompanyLocation = values.CompanyInfoToDisplay.includes("lessorCompanyLocation");
        createReport.lessorCompanyCreationDate = values.CompanyInfoToDisplay.includes("lessorCompanyCreationDate");
        createReport.machineCategoryIds = values.machineCategoryIds;
        createReport.machineId = values.MachineInfoToDisplay.includes("machineId");
        createReport.machineBrand = values.MachineInfoToDisplay.includes("machineBrand");
        createReport.machineModel = values.MachineInfoToDisplay.includes("machineModel");
        createReport.machineApproval = values.MachineInfoToDisplay.includes("machineApproval");
        createReport.machineServiceRegions = values.MachineInfoToDisplay.includes("machineServiceRegion");
        createReport.machineCreationDate = values.MachineInfoToDisplay.includes("machineCreationDate");
        createReport.machineModifiedDate = values.MachineInfoToDisplay.includes("machineModifiedDate");
        createReport.machineApprovalDate = values.MachineInfoToDisplay.includes("machineApprovalDate");
        createReport.machineRequestedImageCount = values.machineRequestedImageCount;
        createReport.machineHourlyPrice = values.MachinePriceInfoToDisplay.includes("hourly");
        createReport.machineDailyPrice = values.MachinePriceInfoToDisplay.includes("daily");
        createReport.machineMonthlyPrice = values.MachinePriceInfoToDisplay.includes("monthly");
        createReport.machineYearlyPrice = values.MachinePriceInfoToDisplay.includes("yearly");
        createReport.machineCurrencyTypeTL = values.MachineCurrencyInfoToDisplay.includes("TL");
        createReport.machineCurrencyTypeUSD = values.MachineCurrencyInfoToDisplay.includes("USD");
        createReport.machineCurrencyTypeEUR = values.MachineCurrencyInfoToDisplay.includes("EUR");
        exportClick(createReport);
        // Burada form verilerini excel tablosuna dönüştürmek için bir fonksiyon çağırılabilir
    };

    return (
        <ListContainer>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("reports.machines-reports.title")}</h3>
                    </Col>
                </Row>
            </BoxContainer>

            <Form onFinish={onFinish}>
                {/* Şirket Bilgileri */}
                <h3 style={{ fontSize: "1.25rem", fontWeight: "800" }}> {t("reports.machines-reports.companyInformation")}</h3>
                <Card>
                    <Form.Item
                        label={t("reports.machines-reports.companyInformation.selectCompany")}
                        name={"lessorCompanyIds"}
                        rules={[
                            { required: true, message: t("reports.machines-reports.companyInformation.selectCompanyRequiredFieldMessage") },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder={t("reports.machines-reports.companyInformation.selectCompanyPlaceHolder")}
                            onChange={onSelectChange}
                            loading={loading}
                        >
                            {companies?.map((company) => (
                                <Select.Option key={company.id} value={company.id}>
                                    {company.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("reports.machines-reports.companyInformation.companyInformationToDisplay")}
                        name={"CompanyInfoToDisplay"}
                        rules={[
                            {
                                required: true,
                                message: t("reports.machines-reports.companyInformation.companyInformationToDisplayRequiredFieldMessage"),
                            },
                        ]}
                    >
                        <Checkbox.Group options={CompnayInfoDisplayOptions} />
                    </Form.Item>
                </Card>

                {/* MAKİNE BİLGİLERİ */}

                <h3 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: 10 }}>
                    {" "}
                    {t("reports.machines-reports.machineInformation")}
                </h3>
                <Card>
                    <Form.Item
                        label={t("reports.machines-reports.machineInformation.selectMachineGroup")}
                        name={"machineCategoryIds"}
                        rules={[
                            {
                                required: true,
                                message: t("reports.machines-reports.machineInformation.selectMachineGroupRequiredFieldMessage"),
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder={t("reports.machines-reports.machineInformation.selectMachineGroupPlaceholder")}
                        >
                            {allCategories?.map((category) => (
                                <Option key={category.id} value={category.id}>
                                    {category.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("reports.machines-reports.machineInformation.machineOnformationToDisplay")}
                        name={"MachineInfoToDisplay"}
                        rules={[
                            {
                                required: true,
                                message: t("reports.machines-reports.machineInformation.machineOnformationToDisplayRequiredFieldMessage"),
                            },
                        ]}
                    >
                        <Checkbox.Group options={machineInfoOptions} />
                    </Form.Item>
                </Card>

                {/* MAKİNE GÖRSEL DURUMU */}
                <h3 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: 10 }}>
                    {" "}
                    {t("reports.machines-reports.machineImageInformation")}
                </h3>
                <Card>
                    <Form.Item
                        label={t("reports.machines-reports.machineImageInformation.machineImageInformationToDisplay")}
                        name={"machineRequestedImageCount"}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "reports.machines-reports.machineImageInformation.machineImageInformationToDisplayRequiredFieldMessage"
                                ),
                            },
                            { min: 0, max: 6 },
                        ]}
                    >
                        <Input
                            type="number"
                            min={0}
                            max={6}
                            placeholder={t("reports.machines-reports.machineImageInformation.machineImageInformationToDisplayPlaceholder")}
                        />
                    </Form.Item>
                </Card>

                {/* MAKİNE FİYAT BİLGİSİ */}
                <h3 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: 10 }}>
                    {" "}
                    {t("reports.machines-reports.machinePriceInformation")}
                </h3>
                <Card>
                    <Form.Item
                        label="Makine fiyat periyodunu seçiniz"
                        name={"MachinePriceInfoToDisplay"}
                        rules={[
                            {
                                required: true,
                                message: t("reports.machines-reports.machinePriceInformation.pricePeriodRequiredFieldMessage"),
                            },
                        ]}
                    >
                        <Checkbox.Group options={priceInfoOptions} />
                    </Form.Item>

                    <Form.Item
                        label={t("reports.machines-reports.machinePriceInformation.currency")}
                        name={"MachineCurrencyInfoToDisplay"}
                        rules={[
                            { required: true, message: t("reports.machines-reports.machinePriceInformation.currencyRequiredFiledMessage") },
                        ]}
                    >
                        <Checkbox.Group options={currencyInfoOptions} />
                    </Form.Item>

                    <Row justify="end" align="middle">
                        <CSVLink filename={ReportDateTime} ref={csvRef} data={allRows} separator={";"}></CSVLink>
                        <Button loading={buttonLoading} type="primary" htmlType="submit" onClick={() => {}}>
                            Rapor Oluştur
                        </Button>
                    </Row>
                </Card>
            </Form>
        </ListContainer>
    );
};

export default MachinesReports;
