/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthType {
    Email = 'Email',
    Google = 'Google',
    Facebook = 'Facebook'
}

export function AuthTypeFromJSON(json: any): AuthType {
    return AuthTypeFromJSONTyped(json, false);
}

export function AuthTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthType {
    return json as AuthType;
}

export function AuthTypeToJSON(value?: AuthType | null): any {
    return value as any;
}

