import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import BigboxContainer from "../components/containers/bigbox-container";
import { CityModel } from "../service";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { EditMachinePartProps } from "../viewmodels/edit-page-part-props";

const RequestServiceRegionsPart = (props: EditMachinePartProps) => {
    const { doGetCities } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [selectedCity, setSelectedCity] = useState<CityModel | undefined>(undefined);

    useEffect(() => {
        const getCities = async () => {
            const result = await doGetCities();
            setCities(result);
            /* props.form.setFieldsValue({
                serviceRegionsList:props.editedMachine?.district?.city?.name,
                districtsList:props.editedMachine?.district?.name
            }) */
        };
        getCities();

        //eslint-disable-next-line
    }, []);

    const onCitySelectionChange = (cityName: string) => {
        props.form.setFieldsValue({
            serviceRegionsList: cityName,
            districtsList: undefined,
        });
        const cty = cities?.find((cty) => cty.name === cityName);
        setSelectedCity(cty);
    };

    const onDistrictSelectionChange = (districtName: string) => {
        props.form.setFieldsValue({
            districtsList: districtName,
        });
    };

    return (
        <BigboxContainer title={t("machines.add.serviceRegionsTitle")}>
            <Form.Item
                name="city"
                label={t("machines.add-info.selectRegions")}
                rules={[{ required: true, message: t("common.generic-required-message") }]}
            >
                <Select onSelect={onCitySelectionChange} loading={loading}>
                    {cities?.map((city) => (
                        <Select.Option value={city.name}>{city.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="district"
                label={t("machines.add-info.selectDistrict")}
                rules={[{ required: true, message: t("common.generic-required-message") }]}
            >
                <Select onSelect={onDistrictSelectionChange} loading={loading}>
                    {selectedCity?.districts?.map((dist) => (
                        <Select.Option value={dist.name}>{dist.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </BigboxContainer>
    );
};

export default RequestServiceRegionsPart;
