import { Col, Form, message, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, DocumentCategory, DocumentModel, MachineDocumentViewModel, MachineRentalTypeModel } from "../../../service";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../../components/containers/list-container";
import MachineServiceRegionsPart from "../../../page-parts/machine-service-regions-part";
import MachineAdditionalInfoPart from "../../../page-parts/machine-additional-info-part";
import MachineMachineInfoPart from "../../../page-parts/machine-machine-info-part";
import MachineAdditionalServicesPart from "../../../page-parts/additional-services-part";

const AddMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetRentalTypes, doGetCurrencies } = useSecureService();
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const [selectedRentalTypeCounter, setSelectedRentalTypeCounter] = useState(0);
    const [showRentalTypeErrorMessage, setShowRentalTypeErrorMessage] = useState<boolean>(false);
    const [, setMessage] = useAtom(postloginMessageAtom);
    const navigate = useNavigate();

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);
    const otherDocs = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory !== "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        editMachine((mach) => {
            return {
                ...mach,
                lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                id: undefined,
            };
        });
        const getRentalTypes = async () => {
            const result = await doGetRentalTypes();
            setAvailableRentalTypes(result);
        };
        getRentalTypes();

        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };

        getCurrencies();
        //eslint-disable-next-line
    }, []);

    const checkFields = async (): Promise<boolean> => {
        var rentalTitleCheck = await form.getFieldValue(['rentalAdTitle']);
        console.log('dddd:', rentalTitleCheck);
        if (!rentalTitleCheck || rentalTitleCheck === ""){
            message.error(t("machines.add.AdLabel-error"));
            return false
        }

        return true;
    }

    const onMachineFormFinish = async (params: any) => {
        if ( !(await checkFields()) ) {
            return;
        }

        if(selectedRentalTypeCounter < 1) {
            setShowRentalTypeErrorMessage(true);
            return;
        }
        let rentalTypes: MachineRentalTypeModel[] = [];
        availableRentalTypes?.forEach((rtype) => {
            if (params["check" + rtype.name] && params["input" + rtype.name]) {
                let rental: MachineRentalTypeModel = {
                    currency: availableCurrencies?.find((x) => x.name === params["select-" + rtype.name]),
                    price: params["input" + rtype.name],
                    rentalType: {
                        id: rtype.id,
                        name: rtype.name,
                    },
                };
                rentalTypes.push(rental);
            }
        });
        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                attachmentsList: Array.isArray(params.attachmentsList) ? params.attachmentsList : [params.attachmentsList],
                otherAttachmentsName: params.otherAttachmentsName,
                rentalTypes: rentalTypes,
                documents: docs,
            };
        });
        navigate("/rent/machines/review/");
    };

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    const selectedRentalTypeCount = (valueToAdd: number) => {
        setSelectedRentalTypeCounter((oldVal) => { 
            if(oldVal + valueToAdd > 0 ) {
                setShowRentalTypeErrorMessage(false);
            };
            return oldVal + valueToAdd; 
        });
    }



    return (
        <ListContainer>
            <Form name="add-new-machine" form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineInfoPart uploadChange={uploadedFilesChanged} docs={pics} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalInfoPart showRentalTypeErrorMessage={showRentalTypeErrorMessage} selectedRentalTypeCount={selectedRentalTypeCount} uploadChange={uploadedFilesChanged} docs={otherDocs} form={form} />
                    </Col>
                    <Col xs={24} md={12}>
                        <MachineAdditionalServicesPart form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineServiceRegionsPart />
                    </Col>
                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton onClick={(e) => {
                                form
                                .validateFields(form.getFieldsError().map(e=>e.name))
                                .then(() => {
                                // Validation is successful
                                    if(selectedRentalTypeCounter < 1) {
                                        setShowRentalTypeErrorMessage(true);
                                        setMessage({
                                            type: "error",
                                            message: t("machines.add.errorMessage"),
                                        }); 
                                    }
                                })
                                .catch((errors) => {
                                // Errors in the fields
                                    if(selectedRentalTypeCounter < 1) {
                                        setShowRentalTypeErrorMessage(true);
                                    }
                                    setMessage({
                                        type: "error",
                                        message: t("machines.add.errorMessage"),
                                    }); 
                                });

                            } } htmlType="submit" style={{ marginTop: "1rem" }}>
                                {t("common.continue")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ListContainer>
    );
};

export default AddMachine;
