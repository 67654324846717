/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';
import {
    LoginResponseWithMessageModel,
    LoginResponseWithMessageModelFromJSON,
    LoginResponseWithMessageModelFromJSONTyped,
    LoginResponseWithMessageModelToJSON,
} from './LoginResponseWithMessageModel';

/**
 * 
 * @export
 * @interface LoginResponseWithMessageModelValidatedModel
 */
export interface LoginResponseWithMessageModelValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof LoginResponseWithMessageModelValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseWithMessageModelValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {LoginResponseWithMessageModel}
     * @memberof LoginResponseWithMessageModelValidatedModel
     */
    data?: LoginResponseWithMessageModel;
}

export function LoginResponseWithMessageModelValidatedModelFromJSON(json: any): LoginResponseWithMessageModelValidatedModel {
    return LoginResponseWithMessageModelValidatedModelFromJSONTyped(json, false);
}

export function LoginResponseWithMessageModelValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseWithMessageModelValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : LoginResponseWithMessageModelFromJSON(json['data']),
    };
}

export function LoginResponseWithMessageModelValidatedModelToJSON(value?: LoginResponseWithMessageModelValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': LoginResponseWithMessageModelToJSON(value.data),
    };
}

