import { Input, InputProps } from "antd";
import React, { useState } from "react";
import { restrictPositiveNumbers, } from "../../../utils/input-rules";

type MgPositiveNumberInputProps = {
    onChangeValue?: (val: string|undefined) => void;
} & InputProps;

const MgPositiveNumberInput = (props: MgPositiveNumberInputProps) => {
    const [value, setValue] = useState<string | undefined>(undefined);

    return (
        <Input
            allowClear
            style={{ width: "100%" }}
            value={value}
            onChange={(val) => {
                const inputValue = val.currentTarget.value;
                const result =  restrictPositiveNumbers(inputValue);
                setValue(result);
                if (result !== "") {
                    props.onChangeValue && props.onChangeValue(result);
                }else if(result !== value){
                    props.onChangeValue && props.onChangeValue(undefined)
                }
            }}
            {...props}
        />
    );
};

export default MgPositiveNumberInput;
