export const formatCurrency = (number: number | null | undefined): string => {
    if (number === null || number === undefined) return "";
    const formattedNumber = number?.toLocaleString("tr-TR", {
        style: "decimal",
        currency: "TRY",
        minimumFractionDigits: 2,
    });

    return `${formattedNumber} ₺`;
};

export const formatPriceInput = (price: string) => {

    if (price === '' || price.includes(',')) return price;

    price = price.replaceAll(".", "");
    let decimalPart = false;
    const priceArr = Array.from(price);
    var formattedPrice = priceArr.map((e, index) => {
        if (e === ','){ 
            decimalPart = true;
            return e;
        }
        else if (!decimalPart && index !== 0 && (priceArr.length - index) % 3 === 0) {
            return '.' + e;
        }
        return e;
    });
    return formattedPrice.join('');

}