import { LayoutProps, message } from "antd";
import { Layout } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { postloginMessageAtom } from "../../store/global-atoms";

export type BaseLayoutProps = {
    children: any;
} & LayoutProps;

const BaseLayout = (props: BaseLayoutProps) => {
    const [toastMessage] = useAtom(postloginMessageAtom);

    useEffect(() => {
        if (toastMessage.message === "") return;
        switch (toastMessage.type) {
            case "error":
                message.error(toastMessage.message);
                break;
            case "info":
                message.info(toastMessage.message);
                break;
            case "warning":
                message.warning(toastMessage.message);
                break;
            case "success":
                message.success(toastMessage.message);
                break;
            case "loading":
                message.loading(toastMessage.message);
                break;
        }
        toastMessage.message = "";
    }, [toastMessage]);
    return (
        <Layout {...props} style={{ backgroundImage: "linear-gradient(#FFF6E8, white)" }}>
            {props.children}
        </Layout>
    );
};

export default BaseLayout;
