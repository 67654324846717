/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorLogModel
 */
export interface ErrorLogModel {
    /**
     * 
     * @type {number}
     * @memberof ErrorLogModel
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    role?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ErrorLogModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    requestModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    ipAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    requestPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogModel
     */
    params?: string | null;
}

export function ErrorLogModelFromJSON(json: any): ErrorLogModel {
    return ErrorLogModelFromJSONTyped(json, false);
}

export function ErrorLogModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorLogModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'requestModel': !exists(json, 'requestModel') ? undefined : json['requestModel'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'requestPath': !exists(json, 'requestPath') ? undefined : json['requestPath'],
        'params': !exists(json, 'params') ? undefined : json['params'],
    };
}

export function ErrorLogModelToJSON(value?: ErrorLogModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'role': value.role,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'errorMessage': value.errorMessage,
        'requestModel': value.requestModel,
        'ipAddress': value.ipAddress,
        'requestPath': value.requestPath,
        'params': value.params,
    };
}

