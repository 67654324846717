import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import { useQuery } from "../../../helpers/use-query";
import RequestMachineInfoPart from "../../../page-parts/request-machine-info-part";
import { ModifiedRequestDetailModel, postloginMessageAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, DocumentCategory, DocumentModel, RequestFormEditModel } from "../../../service";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import BigboxContainer from "../../../components/containers/bigbox-container";
import TextArea from "antd/lib/input/TextArea";
import AdditionalServicesPart from "../../../page-parts/additional-services-part";
import RequestServiceRegionsPart from "../../../page-parts/request-service-regions-part";
import ListContainer from "../../../components/containers/list-container";
import { useAtom } from "jotai";
import { MachinegoDocumentUploader } from "../../../components/atomics/machinego-document-uploader";

const EditRequest = () => {
    const query = useQuery();
    const reqId = query.get("reqid");
    const [form] = Form.useForm();
    const [editedRequest, editRequest] = useState<ModifiedRequestDetailModel | undefined>(undefined);
    const [fuelTypes, setFuelTypes] = useState<BasicModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);

    const { doGetRequestDetail, doUpdateRequest, doGetAttachments, doGetFuelTypes,doGetMachineSubcategory, doGetCities } = useSecureService();
    const navigate = useNavigate();
    useEffect(() => {
        if (reqId) {
            const reqIdN = parseInt(reqId);
            if (!Number.isNaN(reqIdN))
                doGetRequestDetail(reqIdN).then((data) => {
                    let editedData = data as ModifiedRequestDetailModel & ExtendedMachineDetailModel;
                    editedData.fromDateRaw = moment(data?.requestDateFrom);
                    editedData.toDateRaw = moment(data?.requestDateTo);
                    editedData.fuelTypeName = data?.fuelType?.name || "";
                    editedData.subcategoryName = editedData.subCategory?.name || "";
                    editedData.subCategoryOther = editedData.subCategoryOther || undefined;
                    editedData.categoryName = editedData.subCategory?.category?.name || "";
                    const attachmentsList = editedData.attachments?.map((atc) => atc.name);
                    form.setFieldsValue({
                        ...editedData,
                        ...editedData.machineFeatures,
                        ...editedData.additionalServices,
                        attachmentsList,
                        district: editedData.district?.name,
                        districtId: editedData.district?.id,
                        city: editedData.district?.city?.name,
                        machineId: editedData.machineId,
                    });
                    editRequest((oldVal) => editedData);
                });
        }
        const getFuelTypes = async () => {
            const fuels = await doGetFuelTypes();
            setFuelTypes(fuels);
        };
        getFuelTypes();
        //eslint-disable-next-line
    }, []);

    const onRequestFormFinish = async (params: any) => {
        let attachments: number[] = [];
        let documents: number[] = [];
        editedRequest?.documents?.forEach((item) => {
            if (item && item.id) documents.push(item.id);
        });

        const cities = await doGetCities();
        const city = cities?.find((cty) => cty.name === params.city);
        const district = city?.districts?.find((dst) => dst.name === params.district);
        const allCategories = await doGetMachineSubcategory();
        const category = allCategories?.find((ctg) => ctg.name === params.categoryName);
        const subCategory = category?.subCategories?.find((sbctg) => sbctg.name === params.subcategoryName);
        const availableAtchs = await doGetAttachments(subCategory?.id || 0);
        params.attachmentsList?.forEach((item: string) => {
            const atc: BasicModel | undefined = availableAtchs?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        const model: RequestFormEditModel = {
            ...editedRequest,
            ...params,
            documents: documents,
            districtId: district?.id,
            id: parseInt(reqId || "0"),
            subCategoryId: subCategory?.id,
            subCategoryOther: params.subCategoryOther,
            additionalServices: { ...params },
            attachments: attachments,
            attachmentsOther: editedRequest?.attachmentsOther,
            description: params.description,
            machineFeatures: {
                ...params,
                fuelTypeId: fuelTypes?.find((x) => x.name === params.fuelTypeName)?.id,
            },
        };
        const result = await doUpdateRequest(model);
        if (result) navigate("/rent/requests");
    };

    const dateChanged = (val: RangeValue<moment.Moment>) => {
        editRequest({
            ...editedRequest,
            fromDateRaw: val?.[0],
            toDateRaw: val?.[1],
            requestDateFrom: val?.[0]?.toDate(),
            requestDateTo: val?.[1]?.toDate(),
        });
    };

    const handleUploadChange = (data: DocumentModel[]) => {
        editRequest((oldVal) => {
            return {
                ...oldVal,
                documents: data,
            };
        });
    };

    return (
        <ListContainer
            role="admin"
            unreadCount={0}
            title={t("requests.edit.title")}
            description={t("requests.edit.description")}
        >
            <Form name="edit-request" initialValues={editedRequest} form={form} onFinish={onRequestFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <RequestMachineInfoPart
                            fromDate={editedRequest?.fromDateRaw}
                            toDate={editedRequest?.toDateRaw}
                            form={form}
                            onDateChange={dateChanged}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <BigboxContainer title={t("machines.add.additionalInfoTitle")}>
                            <Form.Item label={t("machines.add.notesLabel")} name="description">
                                <TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t("machines.add.notesPlaceholder")} />
                            </Form.Item>
                            <MachinegoDocumentUploader
                                title={t("requests.docs.title")}
                                multiple
                                docType={DocumentCategory.Other}
                                defaultDocumentFiles={editedRequest?.documents}
                                unsupportedFileError={() =>
                                    setErrorMessage({
                                        message: t("common.unsupportedFile"),
                                        type: "error",
                                    })
                                }
                                onFilesChange={handleUploadChange}
                            />
                        </BigboxContainer>
                    </Col>
                    <Col xs={24} md={12}>
                        <AdditionalServicesPart isRequest={true} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <RequestServiceRegionsPart form={form} />
                    </Col>
                    <Col xs={6} offset={9}>
                        <Form.Item>
                            <PrimaryButton htmlType="submit" style={{ marginTop: "1rem" }}>
                                {t("common.save")}
                            </PrimaryButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ListContainer>
    );
};

export default EditRequest;
