/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SaleRequestStatus,
    SaleRequestStatusFromJSON,
    SaleRequestStatusFromJSONTyped,
    SaleRequestStatusToJSON,
} from './SaleRequestStatus';

/**
 * 
 * @export
 * @interface SaleRequestFormSearchModel
 */
export interface SaleRequestFormSearchModel {
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    subCategoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    renterCompanyId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SaleRequestFormSearchModel
     */
    creationDateFrom?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    districtId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormSearchModel
     */
    cityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormSearchModel
     */
    nameSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormSearchModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {SaleRequestStatus}
     * @memberof SaleRequestFormSearchModel
     */
    saleRequestStatus?: SaleRequestStatus;
}

export function SaleRequestFormSearchModelFromJSON(json: any): SaleRequestFormSearchModel {
    return SaleRequestFormSearchModelFromJSONTyped(json, false);
}

export function SaleRequestFormSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleRequestFormSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'renterCompanyId': !exists(json, 'renterCompanyId') ? undefined : json['renterCompanyId'],
        'creationDateFrom': !exists(json, 'creationDateFrom') ? undefined : (json['creationDateFrom'] === null ? null : new Date(json['creationDateFrom'])),
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'nameSurname': !exists(json, 'nameSurname') ? undefined : json['nameSurname'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'saleRequestStatus': !exists(json, 'saleRequestStatus') ? undefined : SaleRequestStatusFromJSON(json['saleRequestStatus']),
    };
}

export function SaleRequestFormSearchModelToJSON(value?: SaleRequestFormSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'id': value.id,
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'renterCompanyId': value.renterCompanyId,
        'creationDateFrom': value.creationDateFrom === undefined ? undefined : (value.creationDateFrom === null ? null : value.creationDateFrom.toISOString()),
        'districtId': value.districtId,
        'cityId': value.cityId,
        'nameSurname': value.nameSurname,
        'companyName': value.companyName,
        'saleRequestStatus': SaleRequestStatusToJSON(value.saleRequestStatus),
    };
}

