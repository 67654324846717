import { Checkbox, Form, Input, message, Row } from "antd";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import CardContainer from "../components/containers/card-container";
import PreloginLayout from "../components/containers/prelogin-layout";
import { t } from "../translate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSecureService } from "../store/service-atoms";
import { useAtom } from "jotai";
import { loadingAtom } from "../store/global-atoms";
import styles from "../styles/activate.module.scss";

const Activate = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const passwordResetToken = searchParams.get("token");
    const { doPostPasswordReset } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const onFormSubmit = async (parameters: { password: string }) => {
        if (id === null || passwordResetToken === null) return;
        const requestBody = {
            id: +id,
            password: parameters.password,
            token: passwordResetToken,
        };

        const result = await doPostPasswordReset(requestBody);

        if (!!result) {
            message.success(t("activate.success"), 2, () => {
                navigate("/");
            });
        } else {
            message.error(t("activate.error"));
        }
    };

    return (
        <PreloginLayout footer={t("copyright")}>
            <CardContainer title={t("activate.cardTitle")}>
                <Form name="activate" layout="vertical" onFinish={onFormSubmit}>
                    <Form.Item
                        name="password"
                        label={t("activate.password")}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t("activate.passwordConfirm")}
                        dependencies={["password"]}
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(t("activate.matchPasswordError")));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                            },
                        ]}
                    >
                        <Checkbox className={styles.linkedCheckbox}>
                            <a href="/agreement_tr.html" target="_blank">
                                {t("agreement.link")}
                            </a>
                            <span style={{ overflowWrap: "break-word" }}> {t("agreement.label")}</span>
                        </Checkbox>
                    </Form.Item>
                    <Row justify="space-between">
                        <Form.Item>
                            <PrimaryButton loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                {t("activate.primary")}
                            </PrimaryButton>
                        </Form.Item>
                    </Row>
                </Form>
            </CardContainer>
        </PreloginLayout>
    );
};

export default Activate;
