import React from "react";
import ListContainer from "../../../components/containers/list-container";
import BoxContainer from "../../../components/containers/box-container";
import { Col, Row } from "antd";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import * as XLSX from "xlsx";

const GenerateExcelReport = () => {
    const { doGenerateUserSessionReport } = useSecureService();

    const fetchReport = async () => {
        try {
            const response = await doGenerateUserSessionReport();
            if (response && response.length > 0) {
                generateExcelFile(response);
            } else {
                alert("No data available for download.");
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            alert("Failed to fetch the report. Check console for details.");
        }
    };

    const generateExcelFile = (data: any[]) => {
        try {
            // 1. Verileri Excel formatına uygun hale getirin
            const worksheetData = data.map((row) => ({
                "User ID": row.userId,
                Role: row.userRole,
                Email: row.userEmail,
                Name: row.userName,
                Surname: row.userSurname,
                "Mobile Phone": row.userMobilePhone,
                "Last Login Date": row.lastLoginDate,
                "Last Login Time": row.lastLoginTime,
                "Last Logout Date": row.lastLogoutDate,
                "Last Logout Time": row.lastLogoutTime,
                "Total Time Last Logged In": row.totalTimeLastLoggedIn,
                "User Creating Date": row.userCreatingDate,
                "User Creating Time": row.userCreatingTime,
                "Time Elapsed Since Last Login": row.timeElapsedSinceLastLogin,
                "Total AFK Duration": row.totalAfkDuration,
                "Total Session Duration": row.totalSessionDuration,
                Status: row.userStatus,
                "OTP Status": row.otpStatus,
                "Total Login": row.totalLogin,
                "Mobile Login": row.mobileLogin,
                "Desktop Login": row.desktopLogin,
                "Other Login": row.otherLogin,
                "Facebook Login": row.facebookLogin,
                "Google Login": row.googleLogin,
                "Email Login": row.emailLogin,
                "GSM Login": row.gsmLogin,
            }));

            // 2. Worksheet ve Workbook oluştur
            const worksheet = XLSX.utils.json_to_sheet(worksheetData, { header: [] });

            // 4. Sütun başlıkları (header) için 3. satıra ekleyelim
            const headers = [
                "User ID",
                "Role",
                "Email",
                "Name",
                "Surname",
                "Mobile Phone",
                "Last Login Date",
                "Last Login Time",
                "Last Logout Date",
                "Last Logout Time",
                "Total Time Last Logged In",
                "User Creating Date",
                "User Creating Time",
                "Time Elapsed Since Last Login",
                "Total AFK Duration",
                "Total Session Duration",
                "Status",
                "OTP Status",
                "Total Login",
                "Mobile Login",
                "Desktop Login",
                "Other Login",
                "Facebook Login",
                "Google Login",
                "Email Login",
                "GSM Login",
            ];

            // Headers'ı 3. satıra ekleyelim
            headers.forEach((header, index) => {
                const col = String.fromCharCode(65 + index); // Col A, B, C ... Z
                worksheet[`${col}1`] = { v: header, s: { fill: { fgColor: { rgb: "D3D3D3" } }, alignment: { horizontal: "center" } } };
            });

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "User Session Report");
            XLSX.writeFile(workbook, "user_session_report.xlsx");
        } catch (error) {
            console.error("Error generating Excel file:", error);
            alert("Failed to generate Excel file. Check console for details.");
        }
    };
    return (
        <ListContainer>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("menu.generateUserSessionReport")}</h3>
                    </Col>
                </Row>
            </BoxContainer>
            <h1>Rapor Oluştur</h1>
            <button onClick={fetchReport}>Rapor Oluştur ve İndir</button>
        </ListContainer>
    );
};

export default GenerateExcelReport;
