/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SaleShowcasePreviewModel,
    SaleShowcasePreviewModelFromJSON,
    SaleShowcasePreviewModelToJSON,
    SaleShowcaseUpdateModel,
    SaleShowcaseUpdateModelFromJSON,
    SaleShowcaseUpdateModelToJSON,
    SaleShowcaseViewModel,
    SaleShowcaseViewModelFromJSON,
    SaleShowcaseViewModelToJSON,
} from '../models';

export interface SaleShowcasePostRequest {
    saleShowcaseUpdateModel?: Array<SaleShowcaseUpdateModel>;
}

export interface SaleShowcasePreviewPostRequest {
    saleShowcasePreviewModel?: Array<SaleShowcasePreviewModel>;
}

/**
 * 
 */
export class SaleShowcaseApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async saleShowcaseGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SaleShowcaseViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-showcase`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SaleShowcaseViewModelFromJSON));
    }

    /**
     * Admin
     */
    async saleShowcaseGet(initOverrides?: RequestInit): Promise<Array<SaleShowcaseViewModel>> {
        const response = await this.saleShowcaseGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async saleShowcasePostRaw(requestParameters: SaleShowcasePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-showcase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.saleShowcaseUpdateModel?.map(SaleShowcaseUpdateModelToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async saleShowcasePost(requestParameters: SaleShowcasePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.saleShowcasePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async saleShowcasePreviewPostRaw(requestParameters: SaleShowcasePreviewPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SaleShowcaseViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-showcase/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.saleShowcasePreviewModel?.map(SaleShowcasePreviewModelToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SaleShowcaseViewModelFromJSON));
    }

    /**
     * Admin
     */
    async saleShowcasePreviewPost(requestParameters: SaleShowcasePreviewPostRequest = {}, initOverrides?: RequestInit): Promise<Array<SaleShowcaseViewModel>> {
        const response = await this.saleShowcasePreviewPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
