/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineRentalTypeCreateModel,
    MachineRentalTypeCreateModelFromJSON,
    MachineRentalTypeCreateModelFromJSONTyped,
    MachineRentalTypeCreateModelToJSON,
} from './MachineRentalTypeCreateModel';

/**
 * 
 * @export
 * @interface BatchMachineInfoUpdate
 */
export interface BatchMachineInfoUpdate {
    /**
     * 
     * @type {number}
     * @memberof BatchMachineInfoUpdate
     */
    machineId?: number;
    /**
     * 
     * @type {Array<MachineRentalTypeCreateModel>}
     * @memberof BatchMachineInfoUpdate
     */
    rentalTypes?: Array<MachineRentalTypeCreateModel> | null;
}

export function BatchMachineInfoUpdateFromJSON(json: any): BatchMachineInfoUpdate {
    return BatchMachineInfoUpdateFromJSONTyped(json, false);
}

export function BatchMachineInfoUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchMachineInfoUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'rentalTypes': !exists(json, 'rentalTypes') ? undefined : (json['rentalTypes'] === null ? null : (json['rentalTypes'] as Array<any>).map(MachineRentalTypeCreateModelFromJSON)),
    };
}

export function BatchMachineInfoUpdateToJSON(value?: BatchMachineInfoUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineId': value.machineId,
        'rentalTypes': value.rentalTypes === undefined ? undefined : (value.rentalTypes === null ? null : (value.rentalTypes as Array<any>).map(MachineRentalTypeCreateModelToJSON)),
    };
}

