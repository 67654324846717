import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

export type UsernameInputProps = {
    validationMessage?: string;
    placeholder?: string;
    label?: string;
};

const SmsInput = (props: UsernameInputProps) => {
    return (
        <Form.Item name="sms" rules={[{ required: true, message: props.validationMessage }]}>
            <Input
                style={{ width: "100%" }}
                type="password"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={props.placeholder}
            />
        </Form.Item>
    );
};

export default SmsInput;
