import { Col, Form, message, Row, Select, Space, TablePaginationConfig, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import ListContainer from "../../../components/containers/list-container";
import { DistrictModel, SaleRequestFormSearchModel, SaleRequestFormViewModel, SaleRequestStatus, SubCategoryModel } from "../../../service/models";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { CSVLink } from "react-csv";
import { useAtom } from "jotai";
import { loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import DataTable from "../../../components/atomics/data-table";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { ReactComponent as CsvDownload } from "../../../components/assets/download-csv.svg";
import { Data } from "react-csv/components/CommonPropTypes";
import { moment } from "../../../helpers/moment";
import SaleRequestListFilter from "../../../components/containers/sale-requests-filter";
import styles from "./sale-request.module.scss"
import { useForm } from "antd/lib/form/Form";
import { get2SaleMachineMustField } from "../../../utils/machine-utils";
import { ReactComponent as Approve } from "../../../components/assets/approve.svg";



type TableModel = {
    nameLastName: string;
    districtAndCity: string;
    subCategoryAndOther?: {};
    subCategoryName?: string;
    statusWithCountStr?: string;
    offerFormIds?: string;
    category?: string | null;
    allAttachments?: string | null;
    creationDateString?: string;
    requestDateFromString?: string;
    requestDateToString?: string;
    Documents?: string;
    TalepDurumu?: string;
} & SaleRequestFormViewModel;

const castToTableModelArray = (data: SaleRequestFormViewModel[] | null | undefined, forCsv?: boolean) => {

    if (forCsv) {
        const newList = data?.map((item) => {
            const tableModel: TableModel = {
                id: item.id,
                nameLastName: item.name + " " + item.lastName,
                companyName: item.companyName,
                email: item.email,
                phone: item.phone,
                districtAndCity: item.district?.name + " " + item.district?.city?.name,
                creationDateString: moment(item.creationDate),
                description: item.description,
                TalepDurumu: item.status === SaleRequestStatus.Contacted ? t('common.Contacted') : t('common.NotContacted'),
                subCategoryName: (item.subCategory?.name ?? item.subCategoryOther) ?? "",
                ...item,
            };
            delete tableModel.subCategory;
            delete tableModel.creationDate;
            delete tableModel.subCategoryAndOther;
            delete tableModel.district;
            delete tableModel.fuelType;
            delete tableModel.status;
            return tableModel;
        });
        return newList;
    }
    else {
        const newList = data?.map((item) => {

            const tableModel: TableModel = {
                id: item.id,
                nameLastName: item.name + " " + item.lastName,
                companyName: item.companyName,
                email: item.email,
                phone: item.phone,
                districtAndCity: item.district?.name + " " + item.district?.city?.name,
                creationDateString: moment(item.creationDate),
                subCategoryAndOther: {
                    name: item.subCategory?.name,
                    subCategoryOther: item.subCategoryOther,
                },
                description: item.description,
                status: item.status, // 
                ...item,
            };
            delete tableModel.TalepDurumu;
            return tableModel;
        });
        return newList;
    }
    
};

const SaleRequests = () => {
    const { doGetSaleRequests, doUpdateSaleRequestStatus } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [requests, setRequests] = useState<TableModel[] | undefined>(undefined);
    const [dataFetching, setDataFetching] = useState(true);
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const [filterObj, setFilterObj] = useState<{
        filterModel: SaleRequestFormSearchModel;
    }>({
        filterModel: {},
    });
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [form] = useForm();

    const getMustField = (mustFieldNumber: number, record: any) => {
        const feature = get2SaleMachineMustField({subCategory: record.subCategory});

        if (mustFieldNumber === 1 && feature[0].mustFieldName !== '') {
            if (feature[0].mustFieldName !== 'drivingEquipment' && feature[0].mustFieldName !== 'fuelType') {
                return (
                    <>
                        <p className={styles.mustField}>{
                            (t('common.minimum') + ' ' + (t('must-fields.' + feature[0].mustFieldName))) + ' ' + (record['min' + (String(feature[0].mustFieldName).charAt(0).toUpperCase() + String(feature[0].mustFieldName).slice(1)).trim()] ?? '-')
                        }</p>
                            <p className={styles.mustField}>{(t('common.maximum') + ' ' + t('must-fields.' + feature[0].mustFieldName) + ' ' + (record['max' + (String(feature[0].mustFieldName).charAt(0).toUpperCase() + String(feature[0].mustFieldName).slice(1)).trim()] ?? '-'))
                        }</p>
                    </>)
            } else if (feature[0].mustFieldName === 'fuelType') {
                return <span className={styles.mustField}>{(t('must-fields.fuelType')) + ' ' + (record['fuelType'].name ?? '-')}</span>
            } else if (feature[0].mustFieldName === 'drivingEquipment') {
                return <span className={styles.mustField}>{(t('must-fields.drivingEquipment')) + ' ' + (record['drivingEquipment'] ?? '-')}</span>
            } else {
                return <span className={styles.mustField}>{"-"}</span>
            }
        } else if (mustFieldNumber === 2 && feature[1].mustFieldName !== '') {
            if (feature[1].mustFieldName !== 'drivingEquipment' && feature[1].mustFieldName !== 'fuelType') {
                return (
                    <>
                        <p className={styles.mustField}>{
                            (t('common.minimum') + ' ' + (t('must-fields.' + feature[1].mustFieldName))) + ' ' + (record['min' + (String(feature[1].mustFieldName).charAt(0).toUpperCase() + String(feature[1].mustFieldName).slice(1)).trim()] ?? '-')
                        }</p>
                            <p className={styles.mustField}>{(t('common.maximum') + ' ' + t('must-fields.' + feature[1].mustFieldName) + ' ' + (record['max' + (String(feature[1].mustFieldName).charAt(0).toUpperCase() + String(feature[1].mustFieldName).slice(1)).trim()] ?? '-'))
                        }</p>
                    </>)
            } else if (feature[1].mustFieldName === 'fuelType') {
                return <span className={styles.mustField}>{(t('must-fields.fuelType')) + ' ' + (record['fuelType'].name ?? '-')}</span>
            } else if (feature[1].mustFieldName === 'drivingEquipment') {
                return <span className={styles.mustField}>{(t('must-fields.drivingEquipment')) + ' ' + (record['drivingEquipment'] ?? '-')}</span>
            } else {
                return <span className={styles.mustField}>{"-"}</span>
            }
        }
        return <span className={styles.mustField}>{"-"}</span>
    } 

    const columns = [
        {
            title: t("requests.table.requestId"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("requests.table.nameLastName"),
            dataIndex: "nameLastName",
            key: "nameLastName",
        },
        {
            title: t("requests.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("requests.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("requests.table.companyName"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("requests.table.machineCategory"),
            dataIndex: "subCategory",
            key: "subCategory",
            render: (val: SubCategoryModel, record: any) => <span>{val.category?.name}</span>,
        },
        {
            title: t("requests.table.machineName"),
            dataIndex: "subCategoryAndOther",
            key: "subCategoryAndOther",
            render: (val: any, record: any) => <span>{record.subCategory.name}</span>,
        },
        {
            title: t("sale-requests.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: any, record: any) => <span>{record.brand}</span>,
        },
        {
            title: t("sale-requests.table.date"),
            dataIndex: "creationDateString",
            key: "creationDateString",
        },
        {
            title: t("sale-requests.table.minPrice"),
            dataIndex: "minPrice",
            key: "minPrice",
        },
        {
            title: t("sale-requests.table.maxPrice"),
            dataIndex: "maxPrice",
            key: "maxPrice",
        },
        {
            title: <span className={styles.productionYearTitle}>{t("sale-requests.table.productionYear")}</span>,
            dataIndex: "productionYear",
            key: "productionYear",
            render: (val: any, record: TableModel) => {
                return <span className={styles.productionYearTitle}>{(record.minProductionYear ?? '-') + ' / ' + (record.maxProductionYear ?? '-')}</span>
            }
        },
        {
            title: <span className={styles.mustFieldTitle}>{t("sale-requests.table.machineFeature1")}</span>,
            dataIndex: "machine-feature-1",
            key: "machine-feature-1",
            render: (val: any, record: any) => {
                return getMustField(1, record);
            }
        },
        {
            title: <span className={styles.mustFieldTitle}>{t("sale-requests.table.machineFeature2")}</span>,
            dataIndex: "machine-feature-2",
            key: "machine-feature-2",
            render: (val: any, record: any) => {
                return getMustField(2, record);            }
        },
        {
            title: <span className={styles.productionYearTitle}>{t("requests.table.description")}</span>,
            dataIndex: "description",
            key: "description",
            ellipsis: false,
            render: (val: string | undefined) => {
                return (
                    <Tooltip title={val}>
                        <div className={styles.description}>
                            <span>{val}</span>
                        </div>
                    </Tooltip>
                );

            },
        },
        {
            title: t("requests.table.district"),
            dataIndex: "district",
            key: "district",
            render: (dist: DistrictModel) => (
                <span>
                    {dist.name}
                    <br></br>
                    {dist.city?.name}
                </span>
            ),
        },
        {
            title: <span style={{display: 'flex',  textAlign: 'center'}}>{t("requests.table.status")}</span>,
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}><span>{t('common.' + status)}</span></div> // TODO satılık talep için statüler enum olarak be de tanımlanacak
                );
            },
        },
        {
            title: <span className={styles.tableTitle}>{t("sale-requests.edit-status-title")}</span>,
            key: "action",
            render: (text: string, record: any) => (
                <Space size="middle" className={styles.editContainer}>
                    <div className={styles.editContainer}>
                        <Form.Item
                            noStyle
                            name={'status-' + record.id}
                        >
                            <Select placeholder={record.status === SaleRequestStatus.Contacted ? t('common.Contacted') : t('common.NotContacted')} className={styles.select} options={[
                                {label: t('common.NotContacted'), value: SaleRequestStatus.NotContacted},
                                {label: t('common.Contacted'), value: SaleRequestStatus.Contacted}
                            ]} />
                        </Form.Item>
                    </div>
                    <Tooltip className={styles.edit} title={t("common.save")}>
                        <Approve onClick={async () => {
                            var res = await doUpdateSaleRequestStatus({id: record.id, saleRequestStatus: form.getFieldValue('status-' + record.id)});
                            if (res) {
                                setPagination({...pagination});
                            } else {
                                setMessage({type: 'error', message: t('sale-requests.edit-error')});
                            }
                            form.resetFields();
                        }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current, pageSize:tablePagination.pageSize   };
        });
    };

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                if (requests === undefined) {
                    setDataFetching(true);
                }
                const result = await doGetSaleRequests({
                    id: filterObj.filterModel.id,
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    categoryId: filterObj?.filterModel?.categoryId,
                    subCategoryId: filterObj?.filterModel?.subCategoryId,
                    cityId: filterObj?.filterModel?.cityId,
                    creationDateFrom: filterObj?.filterModel?.creationDateFrom,
                    saleRequestStatus: filterObj?.filterModel.saleRequestStatus,
                    companyName: filterObj?.filterModel?.companyName,
                    nameSurname: filterObj?.filterModel?.nameSurname,
                });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setRequests(castToTableModelArray(result?.data));
                }
            } catch (err: any) {
                message.error(err.toString());
            } finally {
                setDataFetching(false);
            }
        };
        fillFormsList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doGetSaleRequests({ pageNo: 1, pageSize: itemCount });
        const exportData = castToTableModelArray(result?.data, true);
        if (exportData?.length !== undefined && exportData?.length > 0) {
            setAllRows(exportData);
            csvRef.current?.link.click();
        }
    };

    return (
        <ListContainer loading={dataFetching}>
            <SaleRequestListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: {...params?.searchFilterModel},
                    });
                }}
            ></SaleRequestListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.requests")} - <strong> ({t("common.total")} {itemCount})</strong></Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="requestforms.csv" ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <br />
            <Form form={form}>
                <DataTable
                    onChange={handleChange}
                    columns={columns}
                    dataSource={requests}
                    loading={loading}
                    pagination={{ ...pagination, total: itemCount,  showSizeChanger: true,   pageSizeOptions: ['10', '20', '50', '100', '250', '500']  }}
                />              
            </Form>
        </ListContainer>
    );
};

export default SaleRequests;
