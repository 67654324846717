/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    Int32ValidatedModel,
    Int32ValidatedModelFromJSON,
    Int32ValidatedModelToJSON,
    NotificationDetailModel,
    NotificationDetailModelFromJSON,
    NotificationDetailModelToJSON,
} from '../models';

export interface NotificationAllReceiverIdGetRequest {
    receiverId: number;
}

export interface NotificationCountReceiverIdGetRequest {
    receiverId: number;
}

export interface NotificationReceiverIdGetRequest {
    receiverId: number;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Customer, Admin, Lessor
     */
    async notificationAllReceiverIdGetRaw(requestParameters: NotificationAllReceiverIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NotificationDetailModel>>> {
        if (requestParameters.receiverId === null || requestParameters.receiverId === undefined) {
            throw new runtime.RequiredError('receiverId','Required parameter requestParameters.receiverId was null or undefined when calling notificationAllReceiverIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/all/{receiverId}`.replace(`{${"receiverId"}}`, encodeURIComponent(String(requestParameters.receiverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationDetailModelFromJSON));
    }

    /**
     * Customer, Admin, Lessor
     */
    async notificationAllReceiverIdGet(requestParameters: NotificationAllReceiverIdGetRequest, initOverrides?: RequestInit): Promise<Array<NotificationDetailModel>> {
        const response = await this.notificationAllReceiverIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer, Admin, Lessor
     */
    async notificationCountReceiverIdGetRaw(requestParameters: NotificationCountReceiverIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        if (requestParameters.receiverId === null || requestParameters.receiverId === undefined) {
            throw new runtime.RequiredError('receiverId','Required parameter requestParameters.receiverId was null or undefined when calling notificationCountReceiverIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/count/{receiverId}`.replace(`{${"receiverId"}}`, encodeURIComponent(String(requestParameters.receiverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Customer, Admin, Lessor
     */
    async notificationCountReceiverIdGet(requestParameters: NotificationCountReceiverIdGetRequest, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.notificationCountReceiverIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer, Admin, Lessor
     */
    async notificationReceiverIdGetRaw(requestParameters: NotificationReceiverIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NotificationDetailModel>>> {
        if (requestParameters.receiverId === null || requestParameters.receiverId === undefined) {
            throw new runtime.RequiredError('receiverId','Required parameter requestParameters.receiverId was null or undefined when calling notificationReceiverIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/{receiverId}`.replace(`{${"receiverId"}}`, encodeURIComponent(String(requestParameters.receiverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationDetailModelFromJSON));
    }

    /**
     * Customer, Admin, Lessor
     */
    async notificationReceiverIdGet(requestParameters: NotificationReceiverIdGetRequest, initOverrides?: RequestInit): Promise<Array<NotificationDetailModel>> {
        const response = await this.notificationReceiverIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
