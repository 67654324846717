/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SaleRequestStatus,
    SaleRequestStatusFromJSON,
    SaleRequestStatusFromJSONTyped,
    SaleRequestStatusToJSON,
} from './SaleRequestStatus';

/**
 * 
 * @export
 * @interface SaleRequestUpdateStatusRequestModel
 */
export interface SaleRequestUpdateStatusRequestModel {
    /**
     * 
     * @type {number}
     * @memberof SaleRequestUpdateStatusRequestModel
     */
    id: number;
    /**
     * 
     * @type {SaleRequestStatus}
     * @memberof SaleRequestUpdateStatusRequestModel
     */
    saleRequestStatus: SaleRequestStatus;
}

export function SaleRequestUpdateStatusRequestModelFromJSON(json: any): SaleRequestUpdateStatusRequestModel {
    return SaleRequestUpdateStatusRequestModelFromJSONTyped(json, false);
}

export function SaleRequestUpdateStatusRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleRequestUpdateStatusRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'saleRequestStatus': SaleRequestStatusFromJSON(json['saleRequestStatus']),
    };
}

export function SaleRequestUpdateStatusRequestModelToJSON(value?: SaleRequestUpdateStatusRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'saleRequestStatus': SaleRequestStatusToJSON(value.saleRequestStatus),
    };
}

