import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../components/assets/left-arrow.svg";
import { ReactComponent as YellowCircle } from "./../../../components/assets/yellowCircle.svg";

const ApprovalDetail = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const navigate = useNavigate();
    const { doGetMachine, doApproveMachine } = useSecureService();
    const [dataFetching, setDataFetching] = useState(true);
    const [rentalDataFetched, setRentalDataFetched] = useState(false);
    const [machineFetched, setMachineFetched] = useState(false);
    useEffect(() => {
        editMachine(undefined);
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                }).finally(() => {setMachineFetched(true);});
        }
        //eslint-disable-next-line
    }, []);
    const onDataFetched = () => {
        setRentalDataFetched(true);
    };

    useEffect(() => {
        if (rentalDataFetched && machineFetched) {
            setDataFetching(false);
        }
    }, [rentalDataFetched,  machineFetched]);
    return (
        <ListContainer loading={dataFetching}>
            <Row justify="space-between" align="middle">
                <Col>
                    <div style={{marginBottom: '1rem', cursor: 'pointer'}} onClick={() => {
                        navigate('/rent/pending-approval');
                    }}>
                        <ArrowLeft style={{marginBottom: '0.15rem'}}></ArrowLeft>
                        <span style={{marginLeft: '0.75rem', fontWeight: '700', fontSize: '1.5rem'}}>{t("rent.pending-approval-view.title")}</span>
                    </div>
                </Col>
                <Col  md={12} lg={16}>
                    <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
                        <div style={{height: '3rem', alignItems: 'center', background: 'white',  display: 'flex', justifyContent: 'center', width: '18rem', marginBottom: '1rem', border: '1px solid rgba(148, 147, 148, 0.2)', borderRadius: '12px'}}>
                            <div style={{display: 'flex', alignContent: 'center'}}>
                                <YellowCircle style={{transform: 'scale(0.7)'}}></YellowCircle>
                            </div>
                            <span style={{marginLeft: '0.5rem', color: '#FEB111', fontWeight: '800', fontSize: '1rem'}}>{t("machine.status.waiting")}</span>
                        </div>
                        {editedMachine?.lessorCompanyId !== undefined && editedMachine?.lessorCompanyId !== null && (
                            <div style={{width: '20rem'}}>
                                <SecondaryButton
                                    onClick={() => {
                                        navigate("/lessor-company/view?compid=" + editedMachine.lessorCompanyId, { replace: true });
                                    }}
                                    style={{ marginRight: "0.5rem", width: '100%'}}
                                >
                                    {t("companies.detail.view")}
                                </SecondaryButton>
                            </div>
                            
                        )}
                        <div style={{width: '10rem'}}>
                            <PrimaryButton
                                onClick={() => {
                                    navigate("/rent/pending-approval/edit?macid=" + machineId, { replace: true });
                                }}
                                style={{ marginRight: "0.5rem", width: '100%'}}
                            >
                                {t("common.edit")}
                            </PrimaryButton>
                        </div>
                        <div style={{width: '12rem'}}> 
                            <PrimaryButton
                                onClick={async () => { 
                                    if(machineId ) await doApproveMachine( {machineIds: [Number.parseInt(machineId)]}); 
                                    navigate('/rent/pending-approval');
                                }}
                                style={{ marginRight: '0.5rem', width: '100%'}}
                            >
                                {t("pendingApproval.primaryButtonText.SingleSelection")}
                            </PrimaryButton>
                        </div>

                    </div>
                </Col>
            </Row>
            <Row gutter={[4, 4]}>
                <Col xs={24} md={12}>
                    <ViewMachineInfoPart key={'machineInfo-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalInfoPart dataFetched={onDataFetched} key={'machineAdditionalInfo-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart key={'machineService-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewServiceRegionsPart key={'machineServiceRegion-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
            </Row>
        </ListContainer>
    );
};

export default ApprovalDetail;
