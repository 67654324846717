/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';
import {
    UserAfkSession,
    UserAfkSessionFromJSON,
    UserAfkSessionFromJSONTyped,
    UserAfkSessionToJSON,
} from './UserAfkSession';
import {
    UserDeviceInformation,
    UserDeviceInformationFromJSON,
    UserDeviceInformationFromJSONTyped,
    UserDeviceInformationToJSON,
} from './UserDeviceInformation';

/**
 * 
 * @export
 * @interface UserSession
 */
export interface UserSession {
    /**
     * 
     * @type {number}
     * @memberof UserSession
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    sessionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSession
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserSession
     */
    isLessor?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserSession
     */
    loginTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserSession
     */
    logoutTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserSession
     */
    lastHeartbeat?: Date | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof UserSession
     */
    sessionDuration?: TimeSpan;
    /**
     * 
     * @type {Array<UserAfkSession>}
     * @memberof UserSession
     */
    afkSessions?: Array<UserAfkSession> | null;
    /**
     * 
     * @type {UserDeviceInformation}
     * @memberof UserSession
     */
    userDeviceInformation?: UserDeviceInformation;
}

export function UserSessionFromJSON(json: any): UserSession {
    return UserSessionFromJSONTyped(json, false);
}

export function UserSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'isLessor': !exists(json, 'isLessor') ? undefined : json['isLessor'],
        'loginTime': !exists(json, 'loginTime') ? undefined : (new Date(json['loginTime'])),
        'logoutTime': !exists(json, 'logoutTime') ? undefined : (json['logoutTime'] === null ? null : new Date(json['logoutTime'])),
        'lastHeartbeat': !exists(json, 'lastHeartbeat') ? undefined : (json['lastHeartbeat'] === null ? null : new Date(json['lastHeartbeat'])),
        'sessionDuration': !exists(json, 'sessionDuration') ? undefined : TimeSpanFromJSON(json['sessionDuration']),
        'afkSessions': !exists(json, 'afkSessions') ? undefined : (json['afkSessions'] === null ? null : (json['afkSessions'] as Array<any>).map(UserAfkSessionFromJSON)),
        'userDeviceInformation': !exists(json, 'userDeviceInformation') ? undefined : UserDeviceInformationFromJSON(json['userDeviceInformation']),
    };
}

export function UserSessionToJSON(value?: UserSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sessionId': value.sessionId,
        'userId': value.userId,
        'isLessor': value.isLessor,
        'loginTime': value.loginTime === undefined ? undefined : (value.loginTime.toISOString()),
        'logoutTime': value.logoutTime === undefined ? undefined : (value.logoutTime === null ? null : value.logoutTime.toISOString()),
        'lastHeartbeat': value.lastHeartbeat === undefined ? undefined : (value.lastHeartbeat === null ? null : value.lastHeartbeat.toISOString()),
        'sessionDuration': TimeSpanToJSON(value.sessionDuration),
        'afkSessions': value.afkSessions === undefined ? undefined : (value.afkSessions === null ? null : (value.afkSessions as Array<any>).map(UserAfkSessionToJSON)),
        'userDeviceInformation': UserDeviceInformationToJSON(value.userDeviceInformation),
    };
}

