import { Col, Input, Row} from "antd";
import { useAtom } from "jotai";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { loadingAtom, postloginMessageAtom} from "../store/global-atoms";
import { t } from "../translate";
import { MachineCommissionCreateModel, MachineCommissionViewModel } from "../service";
import { useEffect, useState } from "react";
import { useSecureService } from "../store/service-atoms";
import { restrictPositiveNumbersInRange } from "../utils/input-rules";

type MachineCommissionModalProps = {
    commission?: MachineCommissionViewModel;
    subCategoryName?: string;
} & ModalLayoutProps;
const MachineCommissionModal = (props: MachineCommissionModalProps) => {
    const [loading] = useAtom(loadingAtom);
    const { doSetMachineCommissionForRent } = useSecureService();
    const [hourlyRate, setHourlyRate] = useState<string | undefined>(props.commission?.hourlyRate?.toString());
    const [dailyRate, setDailyRate] = useState<string | undefined>(props.commission?.dailyRate?.toString());
    const [yearlyRate, setYearlyRate] = useState<string | undefined>(props.commission?.yearlyRate?.toString());
    const [monthlyRate, setMonthlyRate] = useState<string | undefined>(props.commission?.monthlyRate?.toString());
    const [, setMessage] = useAtom(postloginMessageAtom);

    useEffect(() => {
        setHourlyRate(props.commission?.hourlyRate?.toString());
        setDailyRate(props.commission?.dailyRate?.toString());
        setYearlyRate(props.commission?.yearlyRate?.toString());
        setMonthlyRate(props.commission?.monthlyRate?.toString());
        //eslint-disable-next-line
    }, [props.show]);


    const onContinueClick = async () => {
        let machineCommissionCreateModel: MachineCommissionCreateModel = {
            subCategoryId: props.commission?.subCategoryId,
            categoryId: props.commission?.categoryId,
            hourlyRate: parseFloat(hourlyRate ?? "0"),
            dailyRate: parseFloat(dailyRate ?? "0"),
            yearlyRate: parseFloat(yearlyRate ?? "0"),
            monthlyRate: parseFloat(monthlyRate ?? "0")
        }
        const result = await doSetMachineCommissionForRent(machineCommissionCreateModel);
        if (result) {
            setMessage({
                message: t("common.success"),
                type: "success",
            });
            props.onCloseClick(false);
        }
    };
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={props.subCategoryName + t("menu.commissionRate")}>
                <div style={{ border: "1px solid #949394", borderRadius: "1rem", paddingTop: "3%" }}>
                    <Col style={{ marginLeft: "2%" }}>
                        <h3>{t("common.periods")}</h3>
                    </Col>
                    <Col
                        style={{ backgroundColor: "#f3f3f3", padding: 10, borderBottomLeftRadius: "1rem", borderBottomRightRadius: "1rem" }}
                    >
                        <Row gutter={[16, 16]} wrap={false} justify="center" align="middle">
                            <Col>
                                <Row wrap={false} gutter={[16, 16]} style={{ marginBlock: "2%" }}>
                                    <Col style={{ marginLeft: "2%" }}>{t("common.hourly")}</Col>
                                    <Col md={6}>
                                        <Input
                                            style={{ height: "45px" }}
                                            onChange={(val) => {
                                                var num = restrictPositiveNumbersInRange(val.target.value, 0, 100);
                                                if (num) setHourlyRate(num?.toString());
                                                else if (val.currentTarget.value === '') setHourlyRate('0');
                                            }}
                                            suffix="%"
                                            value={hourlyRate}
                                        ></Input>
                                    </Col>
                                </Row>
                                <Row wrap={false} gutter={[16, 16]} style={{ marginBlock: "2%" }}>
                                    <Col style={{ marginLeft: "2%" }}>{t("common.daily")}</Col>
                                    <Col md={6}>
                                        <Input
                                            style={{ height: "45px" }}
                                            onChange={(val) => {
                                                var num = restrictPositiveNumbersInRange(val.target.value, 0, 100);
                                                if (num) setDailyRate(num?.toString());
                                                else if (val.currentTarget.value === '') setDailyRate('0');
                                            }}
                                            suffix="%"
                                            value={dailyRate}
                                        ></Input>
                                    </Col>
                                </Row>
                                <Row wrap={false} gutter={[16, 16]} style={{ marginBlock: "2%" }}>
                                    <Col style={{ marginLeft: "2%" }}>{t("common.monthly")}</Col>
                                    <Col md={6}>
                                        <Input
                                            style={{ height: "45px" }}
                                            onChange={(val) => {
                                                var num = restrictPositiveNumbersInRange(val.target.value, 0, 100);
                                                if (num) setMonthlyRate(num?.toString());
                                                else if (val.currentTarget.value === '') setMonthlyRate('0');
                                            }}
                                            suffix="%"
                                            value={monthlyRate}
                                        ></Input>
                                    </Col>
                                </Row>
                                <Row wrap={false} gutter={[16, 16]} style={{ marginBlock: "2%" }}>
                                    <Col style={{ marginLeft: "2%" }}>{t("common.yearly")}</Col>
                                    <Col md={6}>
                                        <Input
                                            style={{ height: "45px" }}
                                            onChange={(val) => {
                                                var num = restrictPositiveNumbersInRange(val.target.value, 0, 100);
                                                if (num) setYearlyRate(num?.toString());
                                                else if (val.currentTarget.value === '') setYearlyRate('0');
                                            }}
                                            suffix="%"
                                            value={yearlyRate}
                                        ></Input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </div>

                <Col span={18} offset={3}>
                    <PrimaryButton loading={loading} onClick={onContinueClick}>
                        {t("common.accept")}
                    </PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};
export default MachineCommissionModal;
