import { Row, Col } from "antd";
import { useAtom } from "jotai";
import { moment } from "../helpers/moment";
import { EventHandler, useEffect, useState } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import DataTable from "../components/atomics/data-table";
import BigboxContainer from "../components/containers/bigbox-container";
import { loadingAtom } from "../store/global-atoms";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";
import { useSecureService } from "../store/service-atoms";
import { CityModel } from "../service";

type LessorCompanyInfoPartProps = {
    compId: number;
    userId: number;
    dataSource: any;
    dataColumns: any;
    isBlocked: boolean | undefined;
    onBlockClick: EventHandler<any>;
    onEditClick?: EventHandler<any>;
    onClickOfferFormList?: EventHandler<any>;
    onClickRequestFormList?: EventHandler<any>;
    onClickShowUpdateLessorAuth?: () => void; 
    onClickLessorDelete: () => void;
    showDeleteLessorButton?: boolean;
} & ViewPartProps;

const LessorCompanyInfoPart = (props: LessorCompanyInfoPartProps) => {
    const serviceRegionsList = props?.lessorCompany?.serviceRegions?.map((data) => data.name);
    const categories = props.lessorCompany?.categories?.map((cat) => cat.name);
    const location = props.lessorCompany?.location?.name;
    const [loading] = useAtom(loadingAtom);
    const { doGetExactCities } = useSecureService();
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [selectedLocation, setSelectedLocation] = useState<string | null>();

    useEffect(() => {
        doGetExactCities().then((result) => {
            setCities(result);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cities) {
            let selectedLoc: any = cities
                ?.map((city) => {
                    if (city.districts?.find((dist) => dist.name === location)) {
                        return city.name;
                    } else {
                        return "";
                    }
                })
                .join("");
            setSelectedLocation(selectedLoc);
        }
        //eslint-disable-next-line
    }, [cities, location]);

    return (
        <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
                <BigboxContainer title={t("companyDetail.view.mainUser")}>
                    <Row>
                        <Col xs={24} md={12}>
                            <BasicModelViewer
                                md={12}
                                objKey={t("lessorCompany.view.fullName")}
                                value={props.lessorCompany?.companyOwner?.name + " " + props.lessorCompany?.companyOwner?.lastname}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <BasicModelViewer objKey={t("lessorCompany.view.phone")} value={props.lessorCompany?.companyOwner?.phone} />
                        </Col>
                        <Col xs={24} md={12}>
                            <BasicModelViewer objKey={t("lessorCompany.view.email")} value={props.lessorCompany?.companyOwner?.email} />
                        </Col>
                        <Col xs={24} md={12}>
                            <BasicModelViewer objKey={t("lessorCompany.view.title")} value={props.lessorCompany?.companyOwner?.title} />
                        </Col>
                    </Row>
                </BigboxContainer>
            </Col>
            <Col xs={24} md={12}>
                <BigboxContainer title={t("companyDetail.view.companyUsers")}>
                    <DataTable columns={props.dataColumns} dataSource={props.dataSource} loading={loading} pagination={false} />
                    <PrimaryButton style={{width : '60%', marginTop: '24px'}} onClick={props.onClickShowUpdateLessorAuth}>{t('companies.table.authorise-seller')}</PrimaryButton>
                </BigboxContainer>
            </Col>
            <Col xs={24}>
                <BigboxContainer title={t("companyDetail.view.companyInfoDetail")}>
                    <Row>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer md={24} objKey={t("lessorCompany.view.companyID")} value={props.lessorCompany?.id || "-"} />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.companyName")}
                                value={props.lessorCompany?.companyName || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.adress")}
                                value={props.lessorCompany?.address ? props.lessorCompany?.address : "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.sector")}
                                value={categories ? categories?.join(", ") : "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.serviceRegions")}
                                value={serviceRegionsList ? serviceRegionsList?.join(", ") : "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.location")}
                                value={location ? location + ", " + selectedLocation : "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.mainActivityFiels")}
                                value={props.lessorCompany?.mainServiceActivityFields || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.totalMachineCount")}
                                value={props.lessorCompany?.totalMachines || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.registeredMachineCount")}
                                value={props.lessorCompany?.machineCount || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.employeeCount")}
                                value={props.lessorCompany?.employeeCount || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.taxIdentityNo")}
                                value={props.lessorCompany?.taxIdentityNo || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.taxOffice")}
                                value={props.lessorCompany?.taxOffice || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.taxCity")}
                                value={props.lessorCompany?.taxOfficeCity?.name || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.iban")}
                                value={props.lessorCompany?.iban ? props.lessorCompany.iban : "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.offerCount")}
                                value={props.lessorCompany?.offerCount || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.acceptedOfferCount")}
                                value={props.lessorCompany?.acceptedOfferCount || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("lessorCompany.view.lastOfferDate")}
                                value={moment(props.lessorCompany?.lastOfferDate) || "-"}
                            />
                        </Col>
                    </Row>
                </BigboxContainer>
            </Col>
            <Col xs={24}>
                <div style={{ display: "flex",  width: "100%", gap: "1rem" }}>
                    {props.isBlocked === false ? (
                        <SecondaryButton hidden={!props.showDeleteLessorButton} onClick={props.onBlockClick} negative>
                            {t("common.block")}
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton hidden={!props.showDeleteLessorButton} onClick={props.onBlockClick}>{t("common.active")}</SecondaryButton>
                    )}
                    <PrimaryButton style={!props.showDeleteLessorButton ? {width: '50%'} : undefined} onClick={props?.onClickOfferFormList}>{t("lessorCompany.view.listOffers")}</PrimaryButton>
                </div>
                <Col hidden={!props.showDeleteLessorButton} style={{display: 'flex', justifyContent:'center'}} xs={24}>
                    <Col xs={24} md={12}>
                        <PrimaryButton style={{backgroundColor: 'red', borderColor: 'transparent'}} onClick={props.onClickLessorDelete}>{t("lessorCompany.view.lessorDelete")}</PrimaryButton>
                    </Col>
                </Col>
            </Col>
        </Row>
    );
};
export default LessorCompanyInfoPart;
