import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../components/assets/left-arrow.svg";
import ViewSaleMachineInfoPart from "../../../page-parts/view-sale-machine-info-part";
import BoxContainer from "../../../components/containers/box-container";
import styles from "../../../styles/machine-detail.module.scss";
import MachineSaleAdditionalInfoPart from "../../../page-parts/view-sale-additional-info-part";
import MachineSalePricePart from "../../../page-parts/view-sale-price-part";
import MachineSaleStatisticPart from "../../../page-parts/view-sale-statistic-part";

const ViewSaleMachine = () => {
    const query = useQuery();
    const location = useLocation();

    const machineId = query.get("macid");
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const [dataFetching, setDataFetching] = useState(true);
    const navigate = useNavigate();
    const [showUnapproveButton, setShowUnapproveButton] = useState(false);
    const { doGetMachine, doUnApproveSaleMachine } = useSecureService();
    useEffect(() => {
        editMachine(undefined); //global state'te kalan eski makine datasını uçurmak için.
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN)
                    .then((data) => {
                        editMachine(data);
                        setShowUnapproveButton(data?.isMachineRemovedFromSale ? false : true);
                    })
                    .finally(() => {
                        setDataFetching(false);
                    });
        } else {
            setDataFetching(false);
        }
        //eslint-disable-next-line
    }, []);

    return (
        <ListContainer loading={dataFetching}>
            <Col>
                <div
                    style={{ marginBottom: "1rem", cursor: "pointer" }}
                    onClick={() => {
                        if (location.pathname.includes("removed")) navigate("/sale/removed");
                        else navigate("/sale/machines");
                    }}
                >
                    <ArrowLeft style={{ marginBottom: "0.15rem" }}></ArrowLeft>
                    <span style={{ marginLeft: "0.75rem", fontWeight: "700", fontSize: "1.5rem" }}>
                        {location.pathname.includes("removed") ? t("menu.removed-machines") : t("sale.machines-view.title")}
                    </span>
                </div>
            </Col>
            <BoxContainer shadowless marginless>
                <Row justify="space-between" align="middle">
                    <Col>
                        {editedMachine?.isMachineForSale ? (
                            <>
                                {editedMachine.isApprovedForSale && editedMachine.isMachineRemovedFromSale ? (
                                    <div className={styles.machineRemovedStatus}>{t("machine.status.removedFromSale")}</div>
                                ) : editedMachine.isApprovedForSale ? (
                                    <div className={styles.machineApprovedStatus}>{t("machine.status.approved")}</div>
                                ) : (
                                    <div className={styles.machineWaitingStatus}>{t("machine.status.waiting")}</div>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col style={{ float: "right" }}>
                        <Row gutter={[8, 0]}>
                            {editedMachine?.rentalTypes && editedMachine?.rentalTypes?.length > 0 && (
                                <Col>
                                    <SecondaryButton
                                        onClick={() => {
                                            navigate("/rent/machines/view?macid=" + machineId);
                                        }}
                                    >
                                        {t("machine.view.show.rental.machine")}
                                    </SecondaryButton>
                                </Col>
                            )}
                            {editedMachine?.lessorCompanyId !== undefined && editedMachine?.lessorCompanyId !== null && (
                                <Col>
                                    <SecondaryButton
                                        onClick={() => {
                                            navigate("/lessor-company/view?compid=" + editedMachine.lessorCompanyId, { replace: true });
                                        }}
                                        style={{ width: "20rem" }}
                                    >
                                        {t("companies.detail.view")}
                                    </SecondaryButton>
                                </Col>
                            )}
                            <Col hidden={!showUnapproveButton}>
                                <SecondaryButton
                                    onClick={async () => {
                                        if (machineId !== null) {
                                            await doUnApproveSaleMachine({ machineIds: [Number.parseInt(machineId)] });
                                            navigate("/sale/machines");
                                        }
                                    }}
                                    style={{ width: "10rem" }}
                                >
                                    {t("machine.view.removeMachineFromPublishList")}
                                </SecondaryButton>
                            </Col>
                            {!editedMachine?.isMachineRemovedFromSale && (
                                <Col>
                                    <PrimaryButton
                                        onClick={() => {
                                            navigate("/sale/machines/edit?macid=" + machineId, { replace: true });
                                        }}
                                        style={{ width: "10rem" }}
                                    >
                                        {t("common.edit")}
                                    </PrimaryButton>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <ViewSaleMachineInfoPart viewType="machine" machine={editedMachine} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <MachineSaleAdditionalInfoPart viewType="machine" machine={editedMachine} />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSalePricePart viewType="machine" machine={editedMachine} />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSaleStatisticPart key={'statistic-' + machineId} macId={parseInt(machineId ?? '0')} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BoxContainer>
        </ListContainer>
    );
};

export default ViewSaleMachine;
