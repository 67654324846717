/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineListModel,
    MachineListModelFromJSON,
    MachineListModelFromJSONTyped,
    MachineListModelToJSON,
} from './MachineListModel';

/**
 * 
 * @export
 * @interface ShowCaseViewModel
 */
export interface ShowCaseViewModel {
    /**
     * 
     * @type {MachineListModel}
     * @memberof ShowCaseViewModel
     */
    machine?: MachineListModel;
    /**
     * 
     * @type {number}
     * @memberof ShowCaseViewModel
     */
    rank?: number;
}

export function ShowCaseViewModelFromJSON(json: any): ShowCaseViewModel {
    return ShowCaseViewModelFromJSONTyped(json, false);
}

export function ShowCaseViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCaseViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machine': !exists(json, 'machine') ? undefined : MachineListModelFromJSON(json['machine']),
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function ShowCaseViewModelToJSON(value?: ShowCaseViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineListModelToJSON(value.machine),
        'rank': value.rank,
    };
}

