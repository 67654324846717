import { Col, DatePicker, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, OfferStatus, RequestFormSearchModel } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
import moment from "moment";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";
import { restrictPositiveNumbersInRange } from "../../utils/input-rules";

type RequestsFilterProps = {
    onChange?: (params: RequestsFilterViewModel | undefined) => void;
    children?: any;
    isAdmin?: boolean;
};

type RequestsFilterViewModel = {
    searchFilterModel?: RequestFormSearchModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    companyName?: string | null;
    cityName?: string | null;
    reqId?: number;
    reqDate?: moment.Moment;
    offerStatus?: OfferStatus[] | null | undefined;
};

const requestStatusOptions = {
    InReview: { id: 0, text: t("request-status.InReview") },
    Rejected: { id: 1, text: t("request-status.Rejected") },
    Approved: { id: 2, text: t("request-status.Approved") },
    Suggested: { id: 3, text: t("request-status.Suggested") },
    Offered: { id: 4, text: t("request-status.Offered") },
    Deal: { id: 5, text: t("request-status.Deal") },
    InProgress: { id: 6, text: t("request-status.InProgress") },
    Completed: { id: 7, text: t("request-status.Completed") },
    Expired: { id: 8, text: t("request-status.Expired") },
    PreRegistration: { id: 9, text: t("request-status.PreRegistration") },
};

const RequestListFilter = (props: RequestsFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [city, setCity] = useState<BasicModel[] | undefined>(undefined);
    const [filterModel, setFilterModel] = useState<RequestsFilterViewModel>();
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetCities } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const getCityNames = async () => {
            const result = await doGetCities();
            setCity(result);
        };
        getCityNames();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: undefined,
                    categoryId: selectedCategory?.id,
                    requestDateFrom: undefined,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onCitySelected = async (selectedCityName?: string) => {
        let SelectedCity: BasicModel | undefined;
        city?.find((ct) => {
            SelectedCity = city?.find((br) => {
                return br.name === selectedCityName;
            });
            return SelectedCity !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                cityName: SelectedCity?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    cityId: SelectedCity?.id,
                },
            };
        });
    };

    const requestCreationDateChange = (val: moment.Moment) => {
        let prodYear: Date | undefined = moment().toDate();
        if (!moment(val).parsingFlags().nullInput) prodYear = moment(val).toDate();
        else prodYear = undefined;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    creationDateFrom: prodYear,
                },
            };
        });
    };
    const requestDateChange = (val: moment.Moment) => {
        let prodYear: Date | undefined = moment().toDate();
        if (!moment(val).parsingFlags().nullInput) prodYear = moment(val).toDate();
        else prodYear = undefined;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestDateFrom: prodYear,
                },
            };
        });
    };
    const requestIdChange = (val: number) => {
        let requestId: number | undefined = undefined;
        requestId = val;
        setFilterModel((filter) => {
            return {
                ...filter,
                reqId: requestId,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    id: requestId,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    const nameSurnameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    nameSurname: val,
                },
            };
        });
    };

    const onRequestStatusChange = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    status: [val],
                },
            };
        });
    };

    
    const onRequestStatusClear = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    status: null,
                },
            };
        });
    };
    

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                placeholder={t("machines.add.category")}
                                value={filterModel?.categoryName}
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                                options={allCategories?.map((mc) => ({ label: mc.name, value: mc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                placeholder={t("machines.add.subcategory")}
                                value={filterModel?.subCategoryName}
                                loading={loading}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                                options={availableSubcategories?.map((msc) => ({ label: msc.name, value: msc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                onClear={onCitySelected}
                                placeholder={t("requests.filter.city")}
                                value={filterModel?.cityName}
                                loading={loading}
                                onSelect={onCitySelected}
                                options={city?.map((msc) => ({ label: msc.name, value: msc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestCreationDate")}
                                format="DD-MM-YYYY"
                                onChange={(val) => {
                                    requestCreationDateChange(moment(val));
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestDateFrom")}
                                onChange={(val) => {
                                    requestDateChange(moment(val));
                                }}
                            />
                        </Col>
                        <Col xs={12} md={2}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestId")}
                                value={filterModel?.searchFilterModel?.id || undefined}
                                onKeyDown={(event) => {
                                    const re = /[+-]/;
                                    if (re.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                onChange={(val) => {
                                    console.log('val:' + val);
                                    if (val) {
                                        var num = restrictPositiveNumbersInRange(val.toString() ?? undefined, 0, Infinity);
                                        requestIdChange(num ?? Number.parseInt(""));
                                    } else {
                                        requestIdChange(Number.parseInt(""));
                                    }
                                    console.log('req id:' +( num ?? Number.parseInt("")));
                                 
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.companyName")}
                                value={filterModel?.searchFilterModel?.companyName || undefined}
                                onChange={(val) => {
                                    companyNameChange(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.nameSurname")}
                                value={filterModel?.searchFilterModel?.nameSurname || undefined}
                                onChange={(val) => {
                                    nameSurnameChange(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                allowClear
                                placeholder={t("request.filter.isActive")}
                                style={{ width: "100%" }}
                                showSearch
                                onSelect={(value: any) => onRequestStatusChange(value)}
                                onClear={() => onRequestStatusClear(undefined)}
                            >
                                {Object.values(requestStatusOptions).map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.text}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RequestListFilter;
