import { ReactElement } from "react";
import PostloginLayout from "./postlogin-layout";

export type ListContainerProps = {
    role?: string;
    unreadCount?: number;
    children?: any;
    title?: string;
    description?: string;
    helperButtons?: ReactElement[] | undefined;
    onlyHeader?: boolean;
    loading?:boolean;
};

const ListContainer = (props: ListContainerProps) => {
    return <PostloginLayout loading={props.loading}>{props.children}</PostloginLayout>;
};

export default ListContainer;
