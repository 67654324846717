/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './DeviceType';
import {
    LoginMethodType,
    LoginMethodTypeFromJSON,
    LoginMethodTypeFromJSONTyped,
    LoginMethodTypeToJSON,
} from './LoginMethodType';

/**
 * 
 * @export
 * @interface UserDeviceInformation
 */
export interface UserDeviceInformation {
    /**
     * 
     * @type {number}
     * @memberof UserDeviceInformation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceInformation
     */
    sessionId?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof UserDeviceInformation
     */
    deviceType?: DeviceType;
    /**
     * 
     * @type {LoginMethodType}
     * @memberof UserDeviceInformation
     */
    loginMethod?: LoginMethodType;
}

export function UserDeviceInformationFromJSON(json: any): UserDeviceInformation {
    return UserDeviceInformationFromJSONTyped(json, false);
}

export function UserDeviceInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeviceInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'deviceType': !exists(json, 'deviceType') ? undefined : DeviceTypeFromJSON(json['deviceType']),
        'loginMethod': !exists(json, 'loginMethod') ? undefined : LoginMethodTypeFromJSON(json['loginMethod']),
    };
}

export function UserDeviceInformationToJSON(value?: UserDeviceInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sessionId': value.sessionId,
        'deviceType': DeviceTypeToJSON(value.deviceType),
        'loginMethod': LoginMethodTypeToJSON(value.loginMethod),
    };
}

