import { Col, message, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import ListContainer from "../../../../components/containers/list-container";
import { LessorCompanyViewModel, RenterCompanySearchFilterModel, RenterCompanyViewModel, RenterStatus} from "../../../../service/models";
import { PaginationType, useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";
import { CSVLink } from "react-csv";
import { useAtom } from "jotai";
import { companiesAtom, loadingAtom } from "../../../../store/global-atoms";
import DataTable from "../../../../components/atomics/data-table";
import SecondaryButton from "../../../../components/atomics/buttons/secondary-button";
import { ReactComponent as CsvDownload } from "../../../../components/assets/download-csv.svg";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { moment } from "../../../../helpers/moment";
import RenterListFilter from "../../../../components/containers/renter-list-filter";
import { Data } from "react-csv/components/CommonPropTypes";

type TableModel = {
    districtAndCity?: string;
    nameLastName?: string;
    creationDateString?: string;
    lastRequestDateString?: string;
} & RenterCompanyViewModel;

const castToTableModelArray = (data: LessorCompanyViewModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        item.acceptedOfferCount = item.acceptedOfferCount ? item.acceptedOfferCount : 0;
        let cities = item?.serviceRegions?.map((cty) => {
            return cty.name;
        });
        const tableModel: TableModel = {
            districtAndCity: cities?.join(", "),
            nameLastName: item.name + " " + item.lastname,
            creationDateString: moment(item.creationDate),
            lastRequestDateString: moment(item.lastOfferDate),
            ...item,
        };
        delete tableModel.creationDate;
        delete tableModel.lastRequestDate;
        delete tableModel.serviceRegions;
        return tableModel;
    });
    return newList;
};
const Requests = () => {
    const navigate = useNavigate();
    const { doSearchRenterCompanies } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [, editCompany] = useAtom(companiesAtom);
    const [renters, setRenters] = useState<TableModel[] | undefined>(undefined);
    const [filterObj, setFilterObj] = useState<{
        filterModel: RenterCompanySearchFilterModel | undefined;
    }>({
        filterModel: {},
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const [itemCount, setItemCount] = useState<number | undefined>(1);

    const columns = [
        {
            title: t("companies.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("companies.table.nameLastName"),
            dataIndex: "nameLastName",
            key: "nameLastName",
        },
        {
            title: t("companies.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("companies.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("companies.table.companyName"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("companies.table.creationDate"),
            dataIndex: "creationDateString",
            key: "creationDateString",
        },
        {
            title: t("companies.table.offerCount"),
            dataIndex: "offerCount",
            key: "offerCount",
        },
        {
            title: t("companies.table.acceptedOfferCount"),
            dataIndex: "acceptedOfferCount",
            key: "acceptedOfferCount",
        },
        {
            title: t("companies.table.lastOfferDate"),
            dataIndex: "lastRequestDateString",
            key: "lastRequestDateString",
        },
        {
            title: t("requests.table.isRenterUserActive"),
            dataIndex: "status",
            key: "status",
            render: (val: RenterStatus) => <> {val === RenterStatus.Deleted ? t("requests.table.deleted") : val === RenterStatus.Active ? t("requests.table.isFullRegister") : t("requests.table.isPreRegister")}</>,
        },
        {
            title: t("common.action"),
            key: "action",
            render: (text: string, record: any) => (
                <Space size="middle" style={{width: '100%' , display: 'flex', justifyContent: 'center'}}>
                    <Tooltip title={t("common.edit")}>
                        <EditOutlined onAuxClick={() => {
                            editCompany(record);
                        }} onClick={() => viewClickHandler(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current,pageSize:tablePagination.pageSize   };
        });
    };

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                const result = await doSearchRenterCompanies({
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    filter: filterObj.filterModel,
                });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setRenters(castToTableModelArray(result?.data));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillFormsList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchRenterCompanies({ pageNo: 1, pageSize: itemCount });
        const exportData = castToTableModelArray(result?.data);
        if (exportData?.length !== undefined && exportData?.length > 0) {
            setAllRows(exportData);
            csvRef.current?.link.click();
        }
    };

    const viewClickHandler = (record: any) => {
        editCompany(record);
        navigate("/renter-company/view?compid=" + record.id);
    };
    return (
        <ListContainer>
            <RenterListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params?.searchFilterModel,
                    });
                }}
            ></RenterListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.renter.list")} - <strong> ({t("common.total")} {itemCount})</strong></Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="requestforms.csv" ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <DataTable
                onChange={handleChange}
                columns={columns}
                dataSource={renters}
                loading={loading}
                pagination={{ ...pagination, total: itemCount , showSizeChanger: true,   pageSizeOptions: ['10', '20', '50', '100', '250', '500'] }}
            />
        </ListContainer>
    );
};

export default Requests;
