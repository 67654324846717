import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import LightTitle from "../components/atomics/light-title";
import { SaleMachineStatisticViewModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import BorderlessContainer from "../components/containers/borderless-container";

export interface MachineSaleStatisticPartProps {
    macId: number;
}

const MachineSaleStatisticPart = (props: MachineSaleStatisticPartProps) => {
    const { doGetSaleMachineStatic } = useSecureService();
    const [statistic, setStatistic] = useState<SaleMachineStatisticViewModel | null>(null); 

    useEffect(() => {
        const getServiceData = async () => {
            const result = await doGetSaleMachineStatic(props.macId);
            setStatistic(result ?? null);
        };

        getServiceData();
        // eslint-disable-next-line
    }, []);

    return (
        <BorderlessContainer title={t("sale.machine.statistic-part.header")}>
            <Row gutter={[16, 16]}>
                <Col md={24} xs={24}>
                    <Row justify="space-between">
                        <Col>
                            <LightTitle>{t("sale.machine.statistic-part.detail-view")}</LightTitle>
                        </Col>
                        <Col>{statistic?.totalMachineSaleDetailView}</Col>
                    </Row>
                    <Row justify="space-between">
                        <Col>
                            <LightTitle>{t("sale.machine.statistic-part.lessor-view")}</LightTitle>
                        </Col>
                        <Col>{statistic?.totalMachineSaleLessorView}</Col>
                    </Row>
                </Col>
            </Row>
            
        </BorderlessContainer>
    );
};

export default MachineSaleStatisticPart;
