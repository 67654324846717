/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RenterStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}

export function RenterStatusFromJSON(json: any): RenterStatus {
    return RenterStatusFromJSONTyped(json, false);
}

export function RenterStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterStatus {
    return json as RenterStatus;
}

export function RenterStatusToJSON(value?: RenterStatus | null): any {
    return value as any;
}

