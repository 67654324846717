import { Col, Input, InputNumber, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {  BasicModel, LessorCompanySearchFilterModel, LessorCompanySearchSortField, LessorStatus } from "../../service/models";
import { allCategories } from "../../store/all-categories";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
import { useSecureService } from "../../store/service-atoms";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";
import { restrictPositiveNumbersInRange } from "../../utils/input-rules";
//import styles from '../styles/filter-components.module.scss';
type LessorListFilterProps = {
    onChange?: (params: LessorListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
};

type LessorListFilterViewModel = {
    searchFilterModel?: LessorCompanySearchFilterModel;
    companyName?: string | null;
    cityName?: string | null;
    categoryName?: string | null;
    sortBy: {
        field: LessorCompanySearchSortField;
        sortAscending: boolean;
    };
};

const RenterListFilter = (props: LessorListFilterProps) => {
    const [loading] = useAtom(loadingAtom);
    const [city, setCity] = useState<BasicModel[] | undefined>(undefined);
    const {  doGetCities } = useSecureService();
    const [filterModel, setFilterModel] = useState<LessorListFilterViewModel>({
        sortBy: {
            
            field: LessorCompanySearchSortField.MachineCount,
            sortAscending: true,
        },
    });

    useEffect(() => {
        const getCityNames = async () => {
            const result = await doGetCities();
            setCity(result);
        };
        getCityNames();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    
    const companyIdChange = (val: string | number) => {
        let compId: number | undefined = undefined;
        if (typeof val === "number") compId = val;
        setFilterModel((filt) => {
            return {
                ...filt,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    id:compId,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };


    const nameLastnameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    nameLastname: val,
                },
            };
        });
    };

    const onCityClear = ()=> {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    serviceRegions: null,
                },
            };
        });
    };

    const onCitySelected = async (selectedCityName?: string) => {
        let SelectedCity: BasicModel | undefined;
        city?.find(() => {
            SelectedCity = city?.find((br) => {
                return br.name === selectedCityName;
            });
            return SelectedCity !== undefined;
        });
        setFilterModel((filt:any) => {
            return {
                ...filt,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    serviceRegions: [SelectedCity?.id],
                },
            };
        });
    };

    const onLessorStatusChange = async (val?: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    lessorStatus: val === t("companies.table.status.option-active") ? LessorStatus.Active : val === t("companies.table.status.option-deleted") ? LessorStatus.Deleted : undefined,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={3}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("lessors.filter.id")}
                                value={filterModel.searchFilterModel?.id || undefined}
                                onKeyDown={(event) => {
                                    const re = /[+-]/;
                                    if (re.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                onChange={(val) => {
                                    var num = restrictPositiveNumbersInRange(val ? val.toString() : undefined, 0, Infinity);
                                    companyIdChange(num ?? "");
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("lessors.filter.companyName")}
                                    value={filterModel.searchFilterModel?.companyName || undefined}
                                    onChange={(val) => {
                                        companyNameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                        <Col xs={12} md={3}>
                            <MgCharacterSelect 
                                allowClear
                                value={filterModel.categoryName}
                                style={{ width: "100%" }}
                                showSearch
                                placeholder={t("lessors.filter.category")}
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                                options={
                                    allCategories?.map((mc) => (
                                       {label:mc.name, value: mc.name}
                                    ))
                                }
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("lessors.filter.nameLastname")}
                                    value={filterModel.searchFilterModel?.nameLastname || undefined}
                                    onChange={(val) => {
                                        nameLastnameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                        <Col xs={12} md={4}>
                            <MgCharacterSelect 
                                allowClear
                                value={filterModel?.cityName}
                                style={{ width: "100%" }}
                                showSearch
                                placeholder={t("lessors.filter.serviceRegion")}
                                loading={loading}
                                onSelect={onCitySelected}
                                onClear={onCityClear}
                                options={
                                    city?.map((mc) => (
                                       {label:mc.name, value: mc.name}
                                    ))
                                }
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <MgCharacterSelect
                                allowClear
                                placeholder={t("lessor.filter.isActive")}
                                style={{ width: "100%" }}
                                showSearch
                                onSelect={(value: any) => onLessorStatusChange(value)}
                                onClear={() => onLessorStatusChange(undefined)}
                                options={
                                    [
                                        {label: t("companies.table.status.option-active"), value: t("companies.table.status.option-active")},
                                        {label: t("companies.table.status.option-deleted"), value: t("companies.table.status.option-deleted")}
                                    ]
                                }
                            >
                            </MgCharacterSelect>
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RenterListFilter;
