/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineSaleListSearchSortField {
    ModifiedDateDesc = 'ModifiedDateDesc',
    ModifiedDateAsc = 'ModifiedDateAsc',
    PriceAsc = 'PriceAsc',
    PriceDesc = 'PriceDesc',
    SaleShowCases = 'SaleShowCases'
}

export function MachineSaleListSearchSortFieldFromJSON(json: any): MachineSaleListSearchSortField {
    return MachineSaleListSearchSortFieldFromJSONTyped(json, false);
}

export function MachineSaleListSearchSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSaleListSearchSortField {
    return json as MachineSaleListSearchSortField;
}

export function MachineSaleListSearchSortFieldToJSON(value?: MachineSaleListSearchSortField | null): any {
    return value as any;
}

