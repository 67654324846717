import { Col, message, Row, TablePaginationConfig } from "antd";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as CsvDownload } from "../../../components/assets/download-csv.svg";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import DataTable from "../../../components/atomics/data-table";
import ListContainer from "../../../components/containers/list-container";
import { BasicModel, OfferFormSearchModel, OfferFormViewModel, RequestFormOfferViewModel } from "../../../service/models";
import { loadingAtom, requestFormAtom } from "../../../store/global-atoms";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";

import { Link, useNavigate } from "react-router-dom";
import RequestAndOfferStatus from "../../../components/atomics/request-and-offer-status";
import OfferListFilter from "../../../components/containers/offers-filter";
import { moment } from "../../../helpers/moment";
import { useQuery } from "../../../helpers/use-query";
import { Data } from "react-csv/components/CommonPropTypes";

type TableModel = {
    fromAndTo?: string;
    districtAndCity?: string;
    bidCount: number;
    requestFormId?: number;
    allComments?: string;
    machineNames?: string;
    allBids?: string;
    allPaymentTypes?: string;
    billingTimeOption?: string;
    creationDateString?: string;
    expireDateString?: string;
    approvementDateString?: string;
} & OfferFormViewModel;

const castToTableModelArray = (data: OfferFormViewModel[] | null | undefined, forCsv?: boolean) => {

    if (forCsv) {
        const newList = data?.map((item) => {
            let comments = item.comments?.map((com) => {
                return com.comment;
            });
            let machines = item.machines?.map((mac) => {
                return mac.name;
            });
            let bids = item.bids?.map((bid) => {
                return bid.bid;
            });
            let paymentTypes = item.paymentTypes?.map((typ) => {
                return typ.name;
            });
            let billingTimeOption = item.billingTimeOptions?.map((opt) => {
                return opt.name;
            });
            const tableModel: TableModel = {
                districtAndCity: item.requestForm?.district?.name + " " + item.requestForm?.district?.city?.name,
                fromAndTo:
                    item.requestForm?.requestDateFrom?.toLocaleDateString() + " - " + item.requestForm?.requestDateTo?.toLocaleDateString(),
                bidCount: item.bids !== null && item.bids !== undefined ? item.bids.length : 0,
                requestFormId: item.requestForm?.id,
                allComments: comments?.join(", "),
                machineNames: machines?.join(", "),
                allBids: bids?.join(", "),
                allPaymentTypes: paymentTypes?.join(", "),
                billingTimeOption: billingTimeOption?.join(", "),
                creationDateString: moment(item.creationDate),
                expireDateString: moment(item.expireDate),
                approvementDateString: moment(item.approvementDate),
                ...item,
            };

            delete tableModel.creationDate;
            delete tableModel.expireDate;
            delete tableModel.approvementDate;
            delete tableModel.requestForm;
            delete tableModel.comments;
            delete tableModel.machines;
            delete tableModel.bids;
            delete tableModel.paymentTypes;
            delete tableModel.billingTimeOptions;
            return tableModel;
        });
        return newList;
    }

    else {
        const newList = data?.map((item) => {
            const tableModel: TableModel = {
                districtAndCity: item.requestForm?.district?.name + " " + item.requestForm?.district?.city?.name,
                fromAndTo:
                    item.requestForm?.requestDateFrom?.toLocaleDateString() + " - " + item.requestForm?.requestDateTo?.toLocaleDateString(),
                bidCount: item.bids !== null && item.bids !== undefined ? item.bids.length : 0,
                /* nameLastName:item.name+" "+item.lastName,
            companyName:item.companyName,
            email:item.email,
            phone:item.phone,
            
            
            creationDate:item.creationDate,
            subCategoryName:item.subCategory?.name,
            allAttachments:attachmentsValue?.join(', '),
            description:item.description, */

                /*  dailyWorkingTime:item.dailyWorkingTime,
            machineCount:item.machineCount,
            ...item.machineFeatures,
            ...item.additionalServices, */
                ...item,
            };
            return tableModel;
        });
        return newList;
    }
    
};
const Offers = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const compName = query.get("compname");
    const { doSearchOffers } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [, ViewRequest] = useAtom(requestFormAtom);
    const [offers, setOffers] = useState<TableModel[] | undefined>(undefined);
    const [dataFetching, setDataFetching] = useState(true);
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const [filterObj, setFilterObj] = useState<{
        filterModel: OfferFormSearchModel;
    }>({
        filterModel: {
            lessorCompanyName: compName,
        },
    });

    const columns = [
        {
            title: t("offers.table.id"),
            dataIndex: "id",
            key: "id",
            render: (id: string, record: any) => {
                return(
                    <Link onClick={() => {
                        rowClickHandler(record);
                    }} onAuxClick={() => {ViewRequest(record)}} to={''}>
                        {id}
                    </Link>
                );
            },
        },
        {
            title: t("offers.table.requestId"),
            dataIndex: "requestForm",
            key: "requestFormId",
            render: (val: RequestFormOfferViewModel) => <>{val.id}</>,
        },
        {
            title: t("offers.table.machineId"),
            dataIndex: "machines",
            key: "machines",
            render: (val: BasicModel[], record: any) => <>{record.requestForm.machineId}</>,
        },
        {
            title: t("offers.table.company"),
            dataIndex: "lessorCompanyName",
            key: "lessorCompanyName",
            render: (lessorCompanyName: string) => <>{lessorCompanyName}</>,
        },
        {
            title: t("offers.table.machineCount"),
            dataIndex: "requestForm",
            key: "machineCount",
            render: (val: RequestFormOfferViewModel) => <>{val.machineCount}</>,
        },
        {
            title: t("offers.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.machineName"),
            dataIndex: "requestForm",
            key: "category",
            render: (val: RequestFormOfferViewModel) => <>{val.subCategory?.name}</>,
        },
        {
            title: t("offers.table.district"),
            dataIndex: "districtAndCity",
            key: "district",
        },
        {
            title: t("offers.table.dateRange"),
            dataIndex: "fromAndTo",
            key: "fromAndTo",
        },
        {
            title: t("offers.table.bid"),
            dataIndex: "bidCount",
            key: "bidCount",
            render: (val: number) => <>{val}</>,
        },
        {
            title: t("offers.table.rentalStatus"),
            dataIndex: "status",
            key: "status",
            render: (val: string) => {
                return <RequestAndOfferStatus showRequestStatus={false} showOfferStatus={true} offerStatus={val}></RequestAndOfferStatus>;
            },
        },
        {
            title: t("offers.table.status"),
            dataIndex: "requestForm",
            key: "requestStatus",
            render: (val: RequestFormOfferViewModel) => {
                return (
                    <RequestAndOfferStatus
                        showRequestStatus={true}
                        showOfferStatus={false}
                        requestStatus={val.status}
                    ></RequestAndOfferStatus>
                );
            },
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current ,pageSize:tablePagination.pageSize  };
        });
    };

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                if (offers === undefined) {
                    setDataFetching(true);
                }
                const result = await doSearchOffers({
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    categoryId: filterObj?.filterModel?.categoryId,
                    subCategoryId: filterObj?.filterModel?.subCategoryId,
                    cityId: filterObj?.filterModel?.cityId,
                    creationDateFrom: filterObj?.filterModel?.creationDateFrom,
                    requestDateFrom: filterObj?.filterModel?.requestDateFrom,
                    status: filterObj?.filterModel?.status,
                    lessorCompanyName: filterObj?.filterModel?.lessorCompanyName,
                    offerId : filterObj?.filterModel?.offerId,
                    requestId: filterObj?.filterModel?.requestId,
                    requestStatus: filterObj?.filterModel?.requestStatus
                });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setOffers(castToTableModelArray(result?.data));
                }
            } catch (err: any) {
                message.error(err.toString());
            } finally {
                setDataFetching(false);
            }
        };
        fillFormsList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchOffers({ pageNo: 1, pageSize: itemCount });
        const exportData = castToTableModelArray(result?.data, true);
        if (exportData?.length !== undefined && exportData?.length > 0) {
            setAllRows(exportData);
            csvRef.current?.link.click();
        }
    };
    const rowClickHandler = async (record: any) => {
        ViewRequest(record);
        navigate("/rent/offers/view?offerid=" + record.id + "&reqid=" + record.requestForm.id);
    };

    return (
        <ListContainer loading={dataFetching}>
            <OfferListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params?.searchFilterModel || {},
                    });
                }}
            ></OfferListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.offers")} - <strong> ({t("common.total")} {itemCount})</strong></Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="requestforms.csv" ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <br />
            <DataTable
                onRowClick={rowClickHandler}
                onChange={handleChange}
                columns={columns}
                dataSource={offers}
                loading={loading}
                pagination={{ ...pagination, total: itemCount, showSizeChanger: true,   pageSizeOptions: ['10', '20', '50', '100', '250', '500']  }}
            />
        </ListContainer>
    );
};

export default Offers;
