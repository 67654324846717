/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    SaleRequestStatus,
    SaleRequestStatusFromJSON,
    SaleRequestStatusFromJSONTyped,
    SaleRequestStatusToJSON,
} from './SaleRequestStatus';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface SaleRequestFormViewModel
 */
export interface SaleRequestFormViewModel {
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SaleRequestFormViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {SubCategoryModel}
     * @memberof SaleRequestFormViewModel
     */
    subCategory?: SubCategoryModel;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    subCategoryOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    brand?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minProductionYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxProductionYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {DistrictModel}
     * @memberof SaleRequestFormViewModel
     */
    district?: DistrictModel;
    /**
     * 
     * @type {SaleRequestStatus}
     * @memberof SaleRequestFormViewModel
     */
    status?: SaleRequestStatus;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minOperatingWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxOperatingWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minOperationHour?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minSpraySpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxSpraySpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minTankVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxTankVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minLiftingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxLiftingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxOperationHour?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minCarryingCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxCarryingCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minOperatingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxOperatingHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minOperationMileage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxOperationMileage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minNoMinalCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxNoMinalCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minEnginePower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxEnginePower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minMaxPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxMaxPower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minTankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxTankCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minMaxLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxMaxLoadCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    minFlightTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaleRequestFormViewModel
     */
    maxFlightTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestFormViewModel
     */
    drivingEquipment?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof SaleRequestFormViewModel
     */
    fuelType?: BasicModel;
}

export function SaleRequestFormViewModelFromJSON(json: any): SaleRequestFormViewModel {
    return SaleRequestFormViewModelFromJSONTyped(json, false);
}

export function SaleRequestFormViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleRequestFormViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'subCategory': !exists(json, 'subCategory') ? undefined : SubCategoryModelFromJSON(json['subCategory']),
        'subCategoryOther': !exists(json, 'subCategoryOther') ? undefined : json['subCategoryOther'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'minProductionYear': !exists(json, 'minProductionYear') ? undefined : json['minProductionYear'],
        'maxProductionYear': !exists(json, 'maxProductionYear') ? undefined : json['maxProductionYear'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'district': !exists(json, 'district') ? undefined : DistrictModelFromJSON(json['district']),
        'status': !exists(json, 'status') ? undefined : SaleRequestStatusFromJSON(json['status']),
        'minOperatingWeight': !exists(json, 'minOperatingWeight') ? undefined : json['minOperatingWeight'],
        'maxOperatingWeight': !exists(json, 'maxOperatingWeight') ? undefined : json['maxOperatingWeight'],
        'minOperationHour': !exists(json, 'minOperationHour') ? undefined : json['minOperationHour'],
        'minSpraySpeed': !exists(json, 'minSpraySpeed') ? undefined : json['minSpraySpeed'],
        'maxSpraySpeed': !exists(json, 'maxSpraySpeed') ? undefined : json['maxSpraySpeed'],
        'minTankVolume': !exists(json, 'minTankVolume') ? undefined : json['minTankVolume'],
        'maxTankVolume': !exists(json, 'maxTankVolume') ? undefined : json['maxTankVolume'],
        'minLiftingHeight': !exists(json, 'minLiftingHeight') ? undefined : json['minLiftingHeight'],
        'maxLiftingHeight': !exists(json, 'maxLiftingHeight') ? undefined : json['maxLiftingHeight'],
        'maxOperationHour': !exists(json, 'maxOperationHour') ? undefined : json['maxOperationHour'],
        'minCarryingCapacity': !exists(json, 'minCarryingCapacity') ? undefined : json['minCarryingCapacity'],
        'maxCarryingCapacity': !exists(json, 'maxCarryingCapacity') ? undefined : json['maxCarryingCapacity'],
        'minOperatingHeight': !exists(json, 'minOperatingHeight') ? undefined : json['minOperatingHeight'],
        'maxOperatingHeight': !exists(json, 'maxOperatingHeight') ? undefined : json['maxOperatingHeight'],
        'minOperationMileage': !exists(json, 'minOperationMileage') ? undefined : json['minOperationMileage'],
        'maxOperationMileage': !exists(json, 'maxOperationMileage') ? undefined : json['maxOperationMileage'],
        'minNoMinalCapacity': !exists(json, 'minNoMinalCapacity') ? undefined : json['minNoMinalCapacity'],
        'maxNoMinalCapacity': !exists(json, 'maxNoMinalCapacity') ? undefined : json['maxNoMinalCapacity'],
        'minHeight': !exists(json, 'minHeight') ? undefined : json['minHeight'],
        'maxHeight': !exists(json, 'maxHeight') ? undefined : json['maxHeight'],
        'minEnginePower': !exists(json, 'minEnginePower') ? undefined : json['minEnginePower'],
        'maxEnginePower': !exists(json, 'maxEnginePower') ? undefined : json['maxEnginePower'],
        'minMaxPower': !exists(json, 'minMaxPower') ? undefined : json['minMaxPower'],
        'maxMaxPower': !exists(json, 'maxMaxPower') ? undefined : json['maxMaxPower'],
        'minVolume': !exists(json, 'minVolume') ? undefined : json['minVolume'],
        'maxVolume': !exists(json, 'maxVolume') ? undefined : json['maxVolume'],
        'minTankCapacity': !exists(json, 'minTankCapacity') ? undefined : json['minTankCapacity'],
        'maxTankCapacity': !exists(json, 'maxTankCapacity') ? undefined : json['maxTankCapacity'],
        'minLoadCapacity': !exists(json, 'minLoadCapacity') ? undefined : json['minLoadCapacity'],
        'maxLoadCapacity': !exists(json, 'maxLoadCapacity') ? undefined : json['maxLoadCapacity'],
        'minMaxLoadCapacity': !exists(json, 'minMaxLoadCapacity') ? undefined : json['minMaxLoadCapacity'],
        'maxMaxLoadCapacity': !exists(json, 'maxMaxLoadCapacity') ? undefined : json['maxMaxLoadCapacity'],
        'minFlightTime': !exists(json, 'minFlightTime') ? undefined : json['minFlightTime'],
        'maxFlightTime': !exists(json, 'maxFlightTime') ? undefined : json['maxFlightTime'],
        'drivingEquipment': !exists(json, 'drivingEquipment') ? undefined : json['drivingEquipment'],
        'fuelType': !exists(json, 'fuelType') ? undefined : BasicModelFromJSON(json['fuelType']),
    };
}

export function SaleRequestFormViewModelToJSON(value?: SaleRequestFormViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'subCategory': SubCategoryModelToJSON(value.subCategory),
        'subCategoryOther': value.subCategoryOther,
        'brand': value.brand,
        'minProductionYear': value.minProductionYear,
        'maxProductionYear': value.maxProductionYear,
        'minPrice': value.minPrice,
        'maxPrice': value.maxPrice,
        'description': value.description,
        'district': DistrictModelToJSON(value.district),
        'status': SaleRequestStatusToJSON(value.status),
        'minOperatingWeight': value.minOperatingWeight,
        'maxOperatingWeight': value.maxOperatingWeight,
        'minOperationHour': value.minOperationHour,
        'minSpraySpeed': value.minSpraySpeed,
        'maxSpraySpeed': value.maxSpraySpeed,
        'minTankVolume': value.minTankVolume,
        'maxTankVolume': value.maxTankVolume,
        'minLiftingHeight': value.minLiftingHeight,
        'maxLiftingHeight': value.maxLiftingHeight,
        'maxOperationHour': value.maxOperationHour,
        'minCarryingCapacity': value.minCarryingCapacity,
        'maxCarryingCapacity': value.maxCarryingCapacity,
        'minOperatingHeight': value.minOperatingHeight,
        'maxOperatingHeight': value.maxOperatingHeight,
        'minOperationMileage': value.minOperationMileage,
        'maxOperationMileage': value.maxOperationMileage,
        'minNoMinalCapacity': value.minNoMinalCapacity,
        'maxNoMinalCapacity': value.maxNoMinalCapacity,
        'minHeight': value.minHeight,
        'maxHeight': value.maxHeight,
        'minEnginePower': value.minEnginePower,
        'maxEnginePower': value.maxEnginePower,
        'minMaxPower': value.minMaxPower,
        'maxMaxPower': value.maxMaxPower,
        'minVolume': value.minVolume,
        'maxVolume': value.maxVolume,
        'minTankCapacity': value.minTankCapacity,
        'maxTankCapacity': value.maxTankCapacity,
        'minLoadCapacity': value.minLoadCapacity,
        'maxLoadCapacity': value.maxLoadCapacity,
        'minMaxLoadCapacity': value.minMaxLoadCapacity,
        'maxMaxLoadCapacity': value.maxMaxLoadCapacity,
        'minFlightTime': value.minFlightTime,
        'maxFlightTime': value.maxFlightTime,
        'drivingEquipment': value.drivingEquipment,
        'fuelType': BasicModelToJSON(value.fuelType),
    };
}

