import { message } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { ReactElement, useEffect } from "react";
import BaseLayout from "./base-layout";
import styles from "../../styles/PreloginLayout.module.css";
import { preloginMessageAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";

export type PreloginLayoutProps = {
    children: any;
    header?: string | ReactElement;
    footer?: string | ReactElement;
};

const PreloginLayout = (props: PreloginLayoutProps) => {
    const [toastMessage] = useAtom(preloginMessageAtom);

    useEffect(() => {
        if (toastMessage.message === "") return;
        switch (toastMessage.type) {
            case "error":
                message.error(toastMessage.message);
                break;
            case "info":
                message.info(toastMessage.message);
                break;
            case "warning":
                message.warning(toastMessage.message);
                break;
            case "success":
                message.success(toastMessage.message);
                break;
            case "loading":
                message.loading(toastMessage.message);
                break;
        }
        toastMessage.message = "";
    }, [toastMessage]);

    return (
        <BaseLayout className={styles.baseLayout}>
            <Header className={styles.header}>
                <img className={styles.logo} src="./assets/logo.png" alt="Machinego Logo"></img>
                <>{props.header}</>
            </Header>
            <Content className={styles.content}>{props.children}</Content>
            <Footer className={styles.footer}>{props.footer}</Footer>
        </BaseLayout>
    );
};

export default PreloginLayout;
