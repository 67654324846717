import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { DropdownWithInput } from "../components/atomics/inputs/dropdown-with-input";
import {
    BladeWidth,
    BomLength,
    BreakingInfo,
    CameraResolution,
    CarryingCapacity,
    CleaningCapacity,
    CompressionWidth,
    ConcretePumpingCapacity,
    DrivingEquipment,
    ElevatorTypeId,
    EngineBrand,
    EnginePower,
    FlightDistance,
    FlightTime,
    FloorCount,
    FreeAirOutput,
    Frequency,
    FuelTypeId,
    GarbageCollectionBin,
    HasCabin,
    HasPlatform,
    HasTowerElevationSystem,
    Height,
    LiftingHeight,
    LightningCapacity,
    LoadCapacity,
    MaxDepth,
    MaxFeedingSize,
    MaxHeight,
    MaxLoadCapacity,
    MaxPower,
    MaxPressure,
    MaxWidth,
    MinPower,
    NominalCapacity,
    OperatingCapacity,
    OperatingHeight,
    OperatingLength,
    OperatingWeight,
    OperationHour,
    OperationMileage,
    OperationWidth,
    PlatformLength,
    PlatformWidth,
    SpraySpeed,
    TankCapacity,
    TankVolume,
    UsageFields,
    Volume,
    WaterTankCapacity,
    Width,
    WorkingRadius,
    WorkingSpeed,
} from "../components/atomics/form-items";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, CategoryModel } from "../service";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t, trLocale } from "../translate";
import { EditRequestPartProps } from "../viewmodels/edit-page-part-props";
import { RangeValue } from "rc-picker/lib/interface";
import IncrementsInput from "../components/atomics/inputs/increments-input";

const { Option } = Select;

type RequestMachineInfoPartProps = {
    fromDate?: moment.Moment | null;
    toDate?: moment.Moment | null;
    onDateChange?: (param: RangeValue<moment.Moment>) => void;
} & EditRequestPartProps;

const RequestMachineInfoPart = (props: RequestMachineInfoPartProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [availableAttachments, setAvailableAttachments] = useState<BasicModel[] | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<CategoryModel | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<BasicModel | undefined>(undefined);
    const [selectedMachineId, setSelectedMachineId] = useState<string | undefined>("");
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetAttachments } = useSecureService();
    const { RangePicker } = DatePicker;

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentCategory = props.form.getFieldValue("categoryName") as string | undefined;
        onMachineCategorySelected(currentCategory, true);
        const currentSub = props.form.getFieldValue("subcategoryName") as string | undefined;
        onSubcategorySelected(currentSub, true);
        setSelectedMachineId(props.form.getFieldValue("machineId"))
        //eslint-disable-next-line
    }, [props.fromDate]);

    const onMachineCategorySelected = async (selectedCategoryName: string | undefined, initial?: boolean) => {
        setSelectedSubcategory(undefined);
        const selectedCat = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setSelectedCategory(selectedCat);
        setAvailableSubcategories(selectedCat?.subCategories);
        setAvailableAttachments(undefined);
        if (!initial) {
            props.form.setFieldsValue({
                subcategoryName: undefined,
                brandName: undefined,
                attachmentsList: [],
            });
        }
    };

    const onSubcategorySelected = async (selectedSubcategoryName: string | undefined, initial?: boolean) => {
        const currentCategory = props.form.getFieldValue("categoryName") as string | undefined;
        const selectedCat = allCategories?.find((cat) => cat.name === currentCategory);
        const selectedSubcat = selectedCat?.subCategories?.find((sub) => sub.name === selectedSubcategoryName);
        setSelectedSubcategory(selectedSubcat);
        if (!initial) {
            props.form.setFieldsValue({
                attachmentsList: [],
                otherAttachmentsName: undefined,
                subCategoryOther: undefined,
            });
        }
        if (selectedSubcat && selectedSubcat.id) {
            const availableAtchs = await doGetAttachments(selectedSubcat?.id);
            setAvailableAttachments(availableAtchs);
        }
    };

    const dateChanged = (val: RangeValue<moment.Moment>) => {
        props.onDateChange && props.onDateChange(val);
    };

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current < moment().endOf("day");
    }

    const editedMachine: any = undefined;
    let datePickerVal: RangeValue<moment.Moment> = [moment(), moment()];
    if (props.fromDate && props.toDate) datePickerVal = [props.fromDate, props.toDate];
    return (
        <BigboxContainer title={t("machines.add.machineInfoTitle")}>
            <Row gutter={16}>
                <Col xs={24}>
                    <Form.Item
                        name="categoryName"
                        label={t("machines.add.category")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Select showSearch loading={loading} onSelect={(item: string) => onMachineCategorySelected(item)}>
                            {allCategories?.map((mc) => (
                                <Option value={mc.name}>{mc.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name="subcategoryName"
                        label={t("machines.add.subcategory")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Select showSearch loading={loading} onSelect={(item: string) => onSubcategorySelected(item)}>
                            {availableSubcategories?.map((msc) => (
                                <Option value={msc.name}>{msc.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {selectedSubcategory?.name === "Diğer " && (
                    <Col xs={24}>
                        <Form.Item rules={[{ required: true, message: t("common.generic-required-message") }]} name="subCategoryOther">
                            <Input
                                placeholder={t("request.edit.other.subCategory.placeholder")}
                                style={{ height: "3.5rem" }}
                                onChange={(e: any) => {
                                    props.form.setFieldsValue({
                                        subCategoryOther: e.target.value,
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
                {selectedMachineId && (
                    <Col xs={24}>
                        <Form.Item
                            name="machineId"
                            label={t("machines.id")}
                            rules={[{ required: true, message: t("common.generic-required-message") }]}
                        >
                            <InputNumber type={"number"} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} md={24}>
                    <span>{t("request.location-and-date.selectdates")}</span>
                    <RangePicker
                        value={datePickerVal}
                        placement="topRight"
                        onChange={dateChanged}
                        locale={trLocale}
                        format="DD-MM-YYYY"
                        disabledDate={disabledDate}
                        size="large"
                        style={{ width: "100%", height: "48px", background: "white", marginBottom: "1.5rem" }}
                    ></RangePicker>
                </Col>

                {selectedSubcategory && (
                    <>
                        {OperationMileage(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationMileage, true)}
                        {OperationHour(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationHour, true)}
                        {DrivingEquipment(selectedCategory?.id, selectedSubcategory.id, editedMachine?.drivingEquipment, true)}
                        {EnginePower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.enginePower, true)}
                        {OperatingWeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingWeight, true)}
                        {TankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankCapacity, true)}
                        {OperatingHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingHeight, true)}
                        {OperatingLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingLength, true)}
                        {OperationWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationWidth, true)}
                        {OperatingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingCapacity, true)}
                        {UsageFields(selectedCategory?.id, selectedSubcategory.id, editedMachine?.usageFields, true)}
                        {HasCabin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasCabin, true)}
                        {EngineBrand(selectedCategory?.id, selectedSubcategory.id, editedMachine?.engineBrand, true)}
                        {HasPlatform(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasPlatform, true)}
                        {HasTowerElevationSystem(
                            selectedCategory?.id,
                            selectedSubcategory.id,
                            editedMachine?.hasTowerElevationSystem,
                            true
                        )}
                        {LightningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.lightningCapacity, true)}
                        {CarryingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.carryingCapacity, true)}
                        {Width(selectedCategory?.id, selectedSubcategory.id, editedMachine?.width, true)}
                        {Volume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.volume, true)}
                        {Height(selectedCategory?.id, selectedSubcategory.id, editedMachine?.height, true)}
                        {FloorCount(selectedCategory?.id, selectedSubcategory.id, editedMachine?.floorCount, true)}
                        {BladeWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bladeWidth, true)}
                        {Frequency(selectedCategory?.id, selectedSubcategory.id, editedMachine?.frequency, true)}
                        {MinPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.minPower, true)}
                        {MaxPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPower, true)}
                        {MaxLoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxLoadCapacity, true)}
                        {NominalCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.nominalCapacity, true)}
                        {WaterTankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.waterTankCapacity, true)}
                        {MaxFeedingSize(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxFeedingSize, true)}
                        {WorkingSpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingSpeed, true)}
                        {CleaningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.cleaningCapacity, true)}
                        {GarbageCollectionBin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.garbageCollectionBin, true)}
                        {BreakingInfo(selectedCategory?.id, selectedSubcategory.id, editedMachine?.breakingInfo, true)}
                        {BomLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bomLength, true)}
                        {MaxHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxHeight, true)}
                        {MaxDepth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxDepth, true)}
                        {MaxWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxWidth, true)}
                        {CompressionWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.compressionWidth, true)}
                        {PlatformLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformLength, true)}
                        {PlatformWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformWidth, true)}
                        {ConcretePumpingCapacity(
                            selectedCategory?.id,
                            selectedSubcategory.id,
                            editedMachine?.concretePumpingCapacity,
                            true
                        )}
                        {MaxPressure(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPressure, true)}
                        {FreeAirOutput(selectedCategory?.id, selectedSubcategory.id, editedMachine?.freeAirOutput, true)}
                        {SpraySpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.spraySpeed, true)}
                        {TankVolume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankVolume, true)}
                        {LoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.loadCapacity, true)}
                        {FlightTime(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightTime, true)}
                        {FlightDistance(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightDistance, true)}
                        {WorkingRadius(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingRadius, true)}
                        {LiftingHeight(selectedCategory?.id, selectedSubcategory.id, undefined, true)}
                        <FuelTypeId
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.fuelType?.name}
                            prelogin
                        />
                        <CameraResolution
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.cameraResolution?.name}
                            prelogin
                        />
                        <ElevatorTypeId
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.elevatorType?.name}
                            prelogin
                        />
                        <Col xs={24} md={24}>
                            <DropdownWithInput
                                data={availableAttachments}
                                required={false}
                                dropdownValidationMessage={t("common.generic-required-message")}
                                formItemLabel={t("machines.add-new.attachments")}
                                formItemName="attachmentsList"
                                loading={loading}
                                inputName="otherAttachmentsName"
                                inputLabel={t("machines.add-new.otherAttachmentInputlabel")}
                                inputValidationMessage={t("common.generic-required-message")}
                                otherChoiceValue="Diğer"
                                multiple
                            />
                        </Col>
                    </>
                )}

                <Col xs={24} md={12}>
                    <Form.Item
                        name="dailyWorkingTime"
                        label={t("requests.edit.workingHours")}
                        initialValue={editedMachine?.dailyWorkingTime}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Select loading={loading}>
                            <Option value={8}>8</Option>
                            <Option value={16}>16</Option>
                            <Option value={24}>24</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="machineCount" label={t("requests.edit.createCount")}>
                        <IncrementsInput min={1} max={50} />
                    </Form.Item>
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default RequestMachineInfoPart;
