/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSessionReport
 */
export interface UserSessionReport {
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userRole?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userSurname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userMobilePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    lastLoginDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    lastLoginTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    lastLogoutDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    lastLogoutTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    totalTimeLastLoggedIn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userCreatingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userCreatingTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    timeElapsedSinceLastLogin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    totalAfkDuration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    totalSessionDuration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    userStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSessionReport
     */
    otpStatus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    totalLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    mobileLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    desktopLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    otherLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    facebookLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    googleLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    emailLogin?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSessionReport
     */
    gsmLogin?: number;
}

export function UserSessionReportFromJSON(json: any): UserSessionReport {
    return UserSessionReportFromJSONTyped(json, false);
}

export function UserSessionReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSessionReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userRole': !exists(json, 'userRole') ? undefined : json['userRole'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'userSurname': !exists(json, 'userSurname') ? undefined : json['userSurname'],
        'userMobilePhone': !exists(json, 'userMobilePhone') ? undefined : json['userMobilePhone'],
        'lastLoginDate': !exists(json, 'lastLoginDate') ? undefined : json['lastLoginDate'],
        'lastLoginTime': !exists(json, 'lastLoginTime') ? undefined : json['lastLoginTime'],
        'lastLogoutDate': !exists(json, 'lastLogoutDate') ? undefined : json['lastLogoutDate'],
        'lastLogoutTime': !exists(json, 'lastLogoutTime') ? undefined : json['lastLogoutTime'],
        'totalTimeLastLoggedIn': !exists(json, 'totalTimeLastLoggedIn') ? undefined : json['totalTimeLastLoggedIn'],
        'userCreatingDate': !exists(json, 'userCreatingDate') ? undefined : json['userCreatingDate'],
        'userCreatingTime': !exists(json, 'userCreatingTime') ? undefined : json['userCreatingTime'],
        'timeElapsedSinceLastLogin': !exists(json, 'timeElapsedSinceLastLogin') ? undefined : json['timeElapsedSinceLastLogin'],
        'totalAfkDuration': !exists(json, 'totalAfkDuration') ? undefined : json['totalAfkDuration'],
        'totalSessionDuration': !exists(json, 'totalSessionDuration') ? undefined : json['totalSessionDuration'],
        'userStatus': !exists(json, 'userStatus') ? undefined : json['userStatus'],
        'otpStatus': !exists(json, 'otpStatus') ? undefined : json['otpStatus'],
        'totalLogin': !exists(json, 'totalLogin') ? undefined : json['totalLogin'],
        'mobileLogin': !exists(json, 'mobileLogin') ? undefined : json['mobileLogin'],
        'desktopLogin': !exists(json, 'desktopLogin') ? undefined : json['desktopLogin'],
        'otherLogin': !exists(json, 'otherLogin') ? undefined : json['otherLogin'],
        'facebookLogin': !exists(json, 'facebookLogin') ? undefined : json['facebookLogin'],
        'googleLogin': !exists(json, 'googleLogin') ? undefined : json['googleLogin'],
        'emailLogin': !exists(json, 'emailLogin') ? undefined : json['emailLogin'],
        'gsmLogin': !exists(json, 'gsmLogin') ? undefined : json['gsmLogin'],
    };
}

export function UserSessionReportToJSON(value?: UserSessionReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userRole': value.userRole,
        'userEmail': value.userEmail,
        'userName': value.userName,
        'userSurname': value.userSurname,
        'userMobilePhone': value.userMobilePhone,
        'lastLoginDate': value.lastLoginDate,
        'lastLoginTime': value.lastLoginTime,
        'lastLogoutDate': value.lastLogoutDate,
        'lastLogoutTime': value.lastLogoutTime,
        'totalTimeLastLoggedIn': value.totalTimeLastLoggedIn,
        'userCreatingDate': value.userCreatingDate,
        'userCreatingTime': value.userCreatingTime,
        'timeElapsedSinceLastLogin': value.timeElapsedSinceLastLogin,
        'totalAfkDuration': value.totalAfkDuration,
        'totalSessionDuration': value.totalSessionDuration,
        'userStatus': value.userStatus,
        'otpStatus': value.otpStatus,
        'totalLogin': value.totalLogin,
        'mobileLogin': value.mobileLogin,
        'desktopLogin': value.desktopLogin,
        'otherLogin': value.otherLogin,
        'facebookLogin': value.facebookLogin,
        'googleLogin': value.googleLogin,
        'emailLogin': value.emailLogin,
        'gsmLogin': value.gsmLogin,
    };
}

