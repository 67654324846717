/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserSession,
    UserSessionFromJSON,
    UserSessionToJSON,
    UserSessionReport,
    UserSessionReportFromJSON,
    UserSessionReportToJSON,
} from '../models';

export interface UserSessionHeartbeatPostRequest {
    userId?: number;
    isLessor?: boolean;
    isAfk?: boolean;
    afkStartTime?: Date;
}

/**
 * 
 */
export class UserSessionApi extends runtime.BaseAPI {

    /**
     */
    async userSessionGenerateUserSessionReportGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserSessionReport>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/UserSession/generate-user-session-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserSessionReportFromJSON));
    }

    /**
     */
    async userSessionGenerateUserSessionReportGet(initOverrides?: RequestInit): Promise<Array<UserSessionReport>> {
        const response = await this.userSessionGenerateUserSessionReportGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userSessionGetOnlineSessisonsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserSession>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/UserSession/get-online-sessisons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserSessionFromJSON));
    }

    /**
     */
    async userSessionGetOnlineSessisonsGet(initOverrides?: RequestInit): Promise<Array<UserSession>> {
        const response = await this.userSessionGetOnlineSessisonsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userSessionHeartbeatPostRaw(requestParameters: UserSessionHeartbeatPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.isLessor !== undefined) {
            queryParameters['isLessor'] = requestParameters.isLessor;
        }

        if (requestParameters.isAfk !== undefined) {
            queryParameters['isAfk'] = requestParameters.isAfk;
        }

        if (requestParameters.afkStartTime !== undefined) {
            queryParameters['afkStartTime'] = (requestParameters.afkStartTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/UserSession/heartbeat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userSessionHeartbeatPost(requestParameters: UserSessionHeartbeatPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.userSessionHeartbeatPostRaw(requestParameters, initOverrides);
    }

}
