/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSpan,
    TimeSpanFromJSON,
    TimeSpanFromJSONTyped,
    TimeSpanToJSON,
} from './TimeSpan';

/**
 * 
 * @export
 * @interface UserAfkSession
 */
export interface UserAfkSession {
    /**
     * 
     * @type {number}
     * @memberof UserAfkSession
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAfkSession
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAfkSession
     */
    sessionId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserAfkSession
     */
    afkStartTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserAfkSession
     */
    afkEndTime?: Date | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof UserAfkSession
     */
    afkDuration?: TimeSpan;
}

export function UserAfkSessionFromJSON(json: any): UserAfkSession {
    return UserAfkSessionFromJSONTyped(json, false);
}

export function UserAfkSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAfkSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'afkStartTime': !exists(json, 'afkStartTime') ? undefined : (new Date(json['afkStartTime'])),
        'afkEndTime': !exists(json, 'afkEndTime') ? undefined : (json['afkEndTime'] === null ? null : new Date(json['afkEndTime'])),
        'afkDuration': !exists(json, 'afkDuration') ? undefined : TimeSpanFromJSON(json['afkDuration']),
    };
}

export function UserAfkSessionToJSON(value?: UserAfkSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'sessionId': value.sessionId,
        'afkStartTime': value.afkStartTime === undefined ? undefined : (value.afkStartTime.toISOString()),
        'afkEndTime': value.afkEndTime === undefined ? undefined : (value.afkEndTime === null ? null : value.afkEndTime.toISOString()),
        'afkDuration': TimeSpanToJSON(value.afkDuration),
    };
}

