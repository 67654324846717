import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import BigboxContainer from "../components/containers/bigbox-container";
import { CityModel } from "../service";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

const MachineServiceRegionsPart = () => {
    const { doGetCities } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);

    useEffect(() => {
        const getCities = async () => {
            const result = await doGetCities();
            setCities(result);
        };
        getCities();

        //eslint-disable-next-line
    }, []);

    return (
        <BigboxContainer title={t("machines.add.serviceRegionsTitle")}>
            <Form.Item
                name="serviceRegionsList"
                label={t("machines.add-info.selectRegions")}
                rules={[{ required: true, message: t("common.generic-required-message") }]}
            >
                <Select mode="multiple" loading={loading}>
                    {cities?.map((city) => (
                        <Select.Option value={city.name}>{city.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </BigboxContainer>
    );
};

export default MachineServiceRegionsPart;
