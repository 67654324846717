import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styles from "../../styles/btn.module.scss";

type SecondaryButtonProps = {
    negative?: boolean;
} & ButtonProps;
const SecondaryButton = (props: SecondaryButtonProps) => {
    const negativeStyle = props.negative
        ? {
              color: "red",
              ...props.style,
          }
        : props.style;
    return (
        <Button {...props} type="default" className={styles.secondaryBtn} style={negativeStyle}>
            {props.children}
        </Button>
    );
};
export default SecondaryButton;
