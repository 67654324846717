import tr from "./locales/tr/common.json";
//import en from './locales/en/common.json';
import trLocale from "antd/es/date-picker/locale/tr_TR";
let activeLang: any = tr;
export function t(targetKey: string) {
    try {
        return activeLang[targetKey].toString();
    } catch (err: any) {
        return targetKey;
    }
}
export { trLocale };
