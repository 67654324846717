/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentCategory {
    Photo = 'Photo',
    Insurance = 'Insurance',
    VehicleLicense = 'VehicleLicense',
    Expertise = 'Expertise',
    PeriodicMaintenance = 'PeriodicMaintenance',
    Ndt = 'NDT',
    OperatorLicenses = 'OperatorLicenses',
    Other = 'Other'
}

export function DocumentCategoryFromJSON(json: any): DocumentCategory {
    return DocumentCategoryFromJSONTyped(json, false);
}

export function DocumentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentCategory {
    return json as DocumentCategory;
}

export function DocumentCategoryToJSON(value?: DocumentCategory | null): any {
    return value as any;
}

