import { Collapse } from "antd";
import styles from "../styles/accordion-payment.module.scss";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";


type AccordionProps = {
    activeKey?: string | number;
    index?: number;
    children: any;
    panelHeader:string|null|undefined;
    changeActiveAccordion?: (index: number, open: boolean) => void;
};

const AccordionPayment = (props: AccordionProps) => {
    const { Panel } = Collapse;
    let actkey = props.activeKey as string | number ;//<<<Error here 
    const Header = (
        <div className={styles.accordionItemHeader}>
                <div>
                    {props.panelHeader}
                </div>
            </div>
    )

    return (
        <Collapse
            onChange={() => {
                if(props.index !== undefined && props.changeActiveAccordion) { // version of only 1 panel can be open at a time
                    props.changeActiveAccordion(props.index, !(props.index === props.activeKey));
                }
            }}
            expandIconPosition="right"
            activeKey={actkey}
            bordered={false}
            accordion
            className={styles.accordionCollapse}
            expandIcon={(panelProps) => {
                if (panelProps.isActive) return <Minus className={styles.expandIcons} />;
                else return <Plus className={styles.expandIcons} />;
            }}
        >
          <Panel className={styles.accordionItemPanel} header={Header} key={props.index ?? actkey}>{props.children}</Panel> 
        </Collapse>
    );
};

export default AccordionPayment;
