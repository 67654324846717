import { Col, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, MachineSearchFilterModel, MachineSearchSortField } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
import { useSecureService } from "../../store/service-atoms";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";
import MgPositiveNumberInput from "../atomics/inputs/mg-positive-number-input";

type PendingApprovalFilterProps = {
    onChange?: (params: PendingApprovalFilterModal) => void;
    children?: any;
    isAdmin?: boolean;
};

type PendingApprovalFilterModal = {
    searchFilterModel?: MachineSearchFilterModel;
    categoryName?: string | null;
    brandName?: string | null;
    subCategoryName?: string | null;
    productionYear?: string | null;
    detail?: string | null;
    company?: string | null;
    sortBy: {
        field: MachineSearchSortField;
        sortAscending: boolean;
    };
};

const PendingApprovalFilter = (props: PendingApprovalFilterProps) => {
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetBrandsByCategory } = useSecureService();
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [availableBrands, setAvailableBrands] = useState<BasicModel[] | undefined>(undefined);
    const [filterModel, setFilterModel] = useState<PendingApprovalFilterModal>({
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        if (selectedCategory && selectedCategory.id) {
            const brands = await doGetBrandsByCategory(selectedCategory.id);
            setAvailableBrands(brands);
        } else {
            setAvailableBrands(undefined);
        }
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                brandName: undefined,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: undefined,
                    brandId: undefined,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onBrandSelected = async (selectedBrandName?: string) => {
        let selectedBrand: BasicModel | undefined;
        availableBrands?.find((category) => {
            selectedBrand = availableBrands?.find((br) => {
                return br.name === selectedBrandName;
            });
            return selectedBrand !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                brandName: selectedBrand?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    brandId: selectedBrand?.id,
                },
            };
        });
    };

    const onModelChanged = async (newModelName?: string) => {
        if (newModelName !== null) {
            setFilterModel((filter) => {
                return {
                    ...filter,
                    searchFilterModel: {
                        ...filter.searchFilterModel,
                        modelName: newModelName,
                    },
                };
            });
        }
    };

    const productionYearChange = (val: string | number) => {
        let prodYear: number | undefined = undefined;
        if (typeof val === "number") prodYear = val;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    productionYearFrom: prodYear,
                    productionYearTo: prodYear,
                },
            };
        });
    };

    const machineIdChange = (val: string|undefined) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    searchKeyword: val,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem", alignItems: "center" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    <Row gutter={[12, 12]} justify="space-between">
                        <Col xs={12} md={3}>
                            <MgCharacterSelect
                                loading={loading}
                                placeholder={t("machines.add.category")}
                                value={filterModel?.categoryName}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                                options={allCategories?.map((mc) => ({ label: mc.name, value: mc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <MgCharacterSelect
                                loading={loading}
                                value={filterModel?.subCategoryName}
                                placeholder={t("machines.add.subcategory")}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                                options={availableSubcategories?.map((msc) => ({ label: msc.name, value: msc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                style={{ width: "100%" }}
                                loading={loading}
                                value={filterModel?.brandName}
                                showSearch
                                placeholder={t("machines.add.brand")}
                                onClear={onBrandSelected}
                                onSelect={onBrandSelected}
                                options={availableBrands?.map((msc) => ({ label: msc.name, value: msc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                placeholder={t("machines.add.modelName")}
                                value={filterModel.searchFilterModel?.modelName || undefined}
                                onChange={(val) => {
                                    onModelChanged(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                        <InputNumber
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                                controls={false}
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.productionYear")}
                                value={filterModel.searchFilterModel?.productionYearFrom || undefined}
                                onChange={(val) => {
                                    const pattern = /^\+?(0|[1-9]\d*)$/
                                    if(val === null || val === undefined || pattern.test(val.toString()) || val.toString() === '')
                                        productionYearChange(val);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <MgPositiveNumberInput
                                allowClear
                                placeholder={t("machines.add.machineIdLabel")}
                                onChangeValue={(val) => {
                                    machineIdChange(val);
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("machines.add.companyName")}
                                    value={filterModel.searchFilterModel?.companyName || undefined}
                                    onChange={(val) => {
                                        companyNameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default PendingApprovalFilter;
