import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import {
    BasicModel,
    CategoryModel,
    LessorCompanyDetailViewModel,
    MachineDetailModel,
    OfferFormViewModel,
    RequestFormViewModel,
} from "../service/models";
import { LoginResponseModel } from "../service/models/LoginResponseModel";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";

export type ToastMessage = {
    message: string;
    type: "info" | "error" | "warning" | "success" | "loading";
};
export type ModifiedMachineDetailModel = {
    otherBrandName?: string | null;
    otherAttachmentsName?: string | null;
} & MachineDetailModel;

type SuccessType = "machine" | "company";
const userStorage = createJSONStorage<LoginResponseModel|undefined>(()=>localStorage);
export const userAtom = atomWithStorage("user", undefined, userStorage);
export const loadingAtom = atom(false);
export const roleAtom = atom<string>("");
export const successTypeAtom = atom<SuccessType>("machine");
export const elevatorTypesAtom = atom<Array<BasicModel> | undefined>(undefined);
export const fuelTypesAtom = atom<Array<BasicModel> | undefined>(undefined);
export const cameraResolutionTypesAtom = atom<Array<BasicModel> | undefined>(undefined);
export const machineEditAtom = atom<ModifiedMachineDetailModel | undefined>({});
export const machineCategoryAtom = atom<CategoryModel[] | undefined>(undefined);
export const machineBrandsAtom = atom<BasicModel[] | null | undefined>(undefined);
export const machineAttachmentsAtom = atom<BasicModel[] | undefined>(undefined);
export const citiesAtom = atom<BasicModel[] | undefined>(undefined);
export const companiesAtom = atom<BasicModel[] | undefined>(undefined);
export const preloginMessageAtom = atom<ToastMessage>({
    message: "",
    type: "info",
});
export interface ModifiedRequestDetailModel extends RequestFormViewModel {
    fromDateRaw?: moment.Moment | null;
    toDateRaw?: moment.Moment | null;
    machines?: BasicModel[] | undefined;
    fuelTypeName?: string;
}
export const postloginMessageAtom = atom<ToastMessage>({
    message: "",
    type: "info",
});

export const requestFormAtom = atom<ModifiedRequestDetailModel | undefined>(undefined);
export const offerFormAtom = atom<OfferFormViewModel | undefined>(undefined);
export const extendedMachineDetailAtom = atom<ExtendedMachineDetailModel | undefined>(undefined);
export const lessorCompanyDetailAtom = atom<LessorCompanyDetailViewModel | undefined>(undefined);

