/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferStatus {
    Suggested = 'Suggested',
    Offered = 'Offered',
    Rejected = 'Rejected',
    Approved = 'Approved',
    Accepted = 'Accepted',
    Expired = 'Expired',
    Declined = 'Declined',
    Deal = 'Deal',
    PaymentDeclined = 'PaymentDeclined'
}

export function OfferStatusFromJSON(json: any): OfferStatus {
    return OfferStatusFromJSONTyped(json, false);
}

export function OfferStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferStatus {
    return json as OfferStatus;
}

export function OfferStatusToJSON(value?: OfferStatus | null): any {
    return value as any;
}

