import DataTable from "../components/atomics/data-table";
import { OfferFormViewModel } from "../service";
import { t } from "../translate";
import { moment } from "../helpers/moment";
import BigboxContainer from "../components/containers/bigbox-container";

type SuggestedOffersPartProps = {
    offers?: OfferFormViewModel[] | null;
    onRow?: (record: OfferFormViewModel | undefined) => void;
};

const SuggestedOffersPart = (props: SuggestedOffersPartProps) => {
    const cols = [
        {
            title: t("offers.table.lessorCompanyId"),
            dataIndex: "lessorCompanyId",
            key: "lessorCompanyId",
        },
        {
            title: t("offers.table.company"),
            dataIndex: "company",
            key: "company",
        },
        {
            title: t("offers.table.redirectionDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
    ];

    const onRowHandler = (record: any, rowIndex: number | undefined) => {
        return {
            onClick: (event: any) => {
                if (props.onRow) props.onRow(record);
            },
        };
    };

    return (
        <BigboxContainer title={t("requests.view.suggestedFirms")}>
            {props.offers && <DataTable onRow={onRowHandler} dataSource={props.offers} columns={cols} pagination={false} />}
        </BigboxContainer>
    );
};

export default SuggestedOffersPart;
