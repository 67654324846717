import { Card, CardProps } from "antd";
import styles from "../styles/components.module.scss";

export type BoxContainerProps = {
    shadowless?: boolean;
    marginless?: boolean;
} & CardProps;

const BoxContainer = (props: BoxContainerProps) => {
    let activeStyle = `${styles.box}`;
    if (!props.shadowless) activeStyle += ` ${styles.boxShadow}`;
    if (!props.marginless) activeStyle += ` ${styles.boxMargin}`;
    return (
        <Card className={activeStyle} {...props}>
            {props.children}
        </Card>
    );
};

export default BoxContainer;
