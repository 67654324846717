import { OfferStatus, RequestStatus } from "../../service";
import { t } from "../../translate";

type RequestAndOfferStatusProps = {
    requestStatus?: RequestStatus | string | null;
    offerStatus?: OfferStatus | string | null;
    showRequestStatus: boolean;
    showOfferStatus: boolean;
    count?: number;
    big?: boolean;
    role?: string;
};
enum StatusType {
    Request,
    Offer,
}

const RequestAndOfferStatus = (props: RequestAndOfferStatusProps) => {
    type ForeAndBackgroundColor = {
        status: string;
        foreground: string;
        background: string;
    };

    const foreAndBackgroundColorsForRequestStatus: ForeAndBackgroundColor[] = [
        {
            status: RequestStatus.Approved,
            foreground: "#35B934",
            background: "#E1F5E1",
        },
        {
            status: RequestStatus.Completed,
            foreground: "#949394",
            background: "#94939426",
        },
        {
            status: RequestStatus.Deal,
            foreground: "#4698F9",
            background: "#E3F0FE",
        },
        {
            status: RequestStatus.Expired,
            foreground: "#949394",
            background: "#94939426",
        },
        {
            status: RequestStatus.InProgress,
            foreground: "#F7B656",
            background: "#F7B65626",
        },
        {
            status: RequestStatus.InReview,
            foreground: "#19B1BB",
            background: "#DDF3F5",
        },
        {
            status: RequestStatus.Offered,
            foreground: "#DB55F1",
            background: "#FAE6FD",
        },
        {
            status: RequestStatus.Rejected,
            foreground: "#FC5A5A",
            background: "#FFE7E7",
        },
        {
            status: RequestStatus.Suggested,
            foreground: "#A1C555",
            background: "#ECF2E0",
        },
        {
            status: RequestStatus.PreRegistration,
            foreground: "#111111",
            background: "#DDF3F5",
        },
    ];

    const foreAndBackgroundColorsForOfferStatus: ForeAndBackgroundColor[] = [
        {
            status: OfferStatus.Deal,
            foreground: "#12CF80",
            background: "#DCF8EC",
        },
        {
            status: OfferStatus.Approved,
            foreground: "#35B934",
            background: "#E1F5E1",
        },
        {
            status: OfferStatus.Declined,
            foreground: "#FC5A5A",
            background: "#FFE7E7",
        },
        {
            status: OfferStatus.Accepted,
            foreground: "#4698F9",
            background: "#E3F0FE",
        },
        {
            status: OfferStatus.Expired,
            foreground: "#949394",
            background: "#94939426",
        },
        {
            status: OfferStatus.Offered,
            foreground: "#DB55F1",
            background: "#FAE6FD",
        },
        {
            status: OfferStatus.PaymentDeclined,
            foreground: "#FC5A5A",
            background: "#FFE7E7",
        },
        {
            status: OfferStatus.Rejected,
            foreground: "#FC5A5A",
            background: "#FFE7E7",
        },
        {
            status: OfferStatus.Suggested,
            foreground: "#A1C555",
            background: "#ECF2E0",
        },
    ];

    const activeStyle = (status: string, type: StatusType) => {
        var tag: ForeAndBackgroundColor | undefined = {
            status: "",
            foreground: "",
            background: "",
        };

        if (type === StatusType.Request) tag = foreAndBackgroundColorsForRequestStatus.find((item) => item.status === status);
        else if (type === StatusType.Offer) {
            tag = foreAndBackgroundColorsForOfferStatus.find((item) => item.status === status);
        }

        return {
            color: tag ? tag.foreground : "#000000",
            background: tag ? tag.background : "#FFFFFF",
            borderRadius: props.big ? ".5rem" : "1.25rem",
            height: props.big ? "48px" : "1.5rem",
            fontWeight: props.big ? "800" : "700",
            fontSize: props.big ? "16px" : "0.75rem",
            padding: "4px 10px",
            width: props.big ? "100%" : "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
    };
    let suffix = "";
    if (props.count && props.requestStatus === "Offered") suffix = " (" + props.count + ")";
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: ".25rem",
                marginBottom: props.big ? "0" : ".5rem",
            }}
        >
            {console.log(props, "req-and-off-status-page")}
            {props.showRequestStatus &&
            props.showOfferStatus === false &&
            (props.requestStatus === RequestStatus.Suggested || props.requestStatus === RequestStatus.Offered) &&
            props.offerStatus === OfferStatus.Offered ? (
                <div style={activeStyle(RequestStatus.Offered, StatusType.Request)}>
                    <span style={{ textAlign: "center" }}>
                        {props.role === "lessor" ? t("request-status.renterOffered") + suffix : t("request-status.offered") + suffix}
                    </span>
                </div>
            ) : (
                <>
                    {props.showRequestStatus && props.requestStatus && (
                        <div style={activeStyle(props.requestStatus, StatusType.Request)}>
                            <span style={{ textAlign: "center" }}>
                                {props.requestStatus === RequestStatus.Offered && props.role === "lessor"
                                    ? t("request-status.renterOffered") + suffix
                                    : t("request-status." + props.requestStatus) + suffix}
                            </span>
                        </div>
                    )}
                    {props.showOfferStatus && props.offerStatus && (
                        <div style={activeStyle(props.offerStatus, StatusType.Offer)}>
                            <span style={{ textAlign: "center" }}>{t("offer-status." + props.offerStatus)}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default RequestAndOfferStatus;
