/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SaleRequestStatus {
    NotContacted = 'NotContacted',
    Contacted = 'Contacted'
}

export function SaleRequestStatusFromJSON(json: any): SaleRequestStatus {
    return SaleRequestStatusFromJSONTyped(json, false);
}

export function SaleRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleRequestStatus {
    return json as SaleRequestStatus;
}

export function SaleRequestStatusToJSON(value?: SaleRequestStatus | null): any {
    return value as any;
}

