import { Col, Menu, Radio, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import placeholder from "../../../components/assets/placeholder.svg";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import ProductCard from "../../../components/atomics/product-card";
import BoxContainer from "../../../components/containers/box-container";
import ListContainer from "../../../components/containers/list-container";
import { formatCurrency } from "../../../helpers/formatters";
import { MachineListModel, PreviewViewModel, UpdateShowcaseViewModel } from "../../../service/models";
import { activeApiBaseUrl } from "../../../store/app-constants";
import { loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import SelectMachineForShowCaseModal from "./select-machines-from-approved-machines";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { formatString } from "typescript-string-operations";

const Showcases = () => {
    const TOTAL_PRODUCT_ITEM = 10;

    const [loading] = useAtom(loadingAtom);
    const [openSelectMachine, setOpenSelectMachine] = useState<boolean>(false);
    const [selectMachineForIndex, setSelectMachineForIndex] = useState<number>(-1);
    const [selectedMachineId, setSelectedMachineId] = useState<number>(-1);
    const [, setMessage] = useAtom(postloginMessageAtom);

    const { doSearchMachineDetail, doGetShowcases, doPublishShowcases, doPreviewShowcases } = useSecureService();
    const [dataFetching, setDataFetching] = useState(true);
    const [products, setProducts] = useState<Array<MachineListModel | undefined>>([]);

    const getShowcases = async () => {
        var showcaseData = await doGetShowcases();
        const data: Array<MachineListModel | undefined> = [];

        for (let index = 0; index < TOTAL_PRODUCT_ITEM; index++) {
            var currentShowcase = showcaseData?.[index];
            if (currentShowcase !== undefined && currentShowcase.machine !== null) {
                data.push(currentShowcase.machine);
            } else {
                data.push(undefined);
            }
        }
        setDataFetching(false);
        setProducts(data);
    };

    const publishProducts = async () => {
        var requestShowcase = new Array<UpdateShowcaseViewModel>();

        products.forEach((x: MachineListModel | undefined, index: number) => {
            requestShowcase.push({
                machineId: x?.id || null,
                rank: index + 1,
            });
        });

        await doPublishShowcases({ updateShowcaseViewModel: requestShowcase });
        await getShowcases();
        setMessage({
            type: "success",
            message: t("showcases.publish.success"),
        });
    };

    const previewProducts = async () => {
        var madeRequest = products.filter((x) => x?.id == null);

        if (madeRequest.length > 0) {
            var requestShowcase = new Array<PreviewViewModel>();

            products.forEach((x: MachineListModel | undefined, index: number) => {
                requestShowcase.push({
                    machineId: x?.id || null,
                    rank: index + 1,
                });
            });

            var data = await doPreviewShowcases({ previewViewModel: requestShowcase });
            var productData = products;
            data.forEach((x) => {
                if (x.rank !== null && x.rank !== undefined) {
                    var index = x.rank - 1;
                    productData = [...productData.slice(0, index), x.machine, ...productData.slice(index + 1)];
                }
            });
            setProducts(productData);
            setMessage({
                type: "success",
                message: t("showcases.preview.success"),
            });
        } else {
            setMessage({
                type: "warning",
                message: t("showcases.preview.warning-non-empty-machines"),
            });
        }
    };

    useEffect(() => {
        getShowcases();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeProduct = (index: number) => {
        setProducts([...products.slice(0, index), undefined, ...products.slice(index + 1)]);
    };

    const changeProduct = (index: number, data: MachineListModel | undefined) => {
        setProducts([...products.slice(0, index), data, ...products.slice(index + 1)]);
    };

    const swapProduct = (from: number, to: number) => {
        var fromVal = products[from];
        var toVal = products[to];
        var newProductData = [...products.slice(0, from), toVal, ...products.slice(from + 1)];
        setProducts([...newProductData.slice(0, to), fromVal, ...newProductData.slice(to + 1)]);
    };

    const getMenuById = (index: number): JSX.Element => {
        return (
            <Menu style={{ padding: "0.5rem" }}>
                <Radio.Group style={{ width: "100%" }}>
                    {products.map((x, curIndex: number) => {
                        const indexForUser = curIndex + 1 + ".) ";

                        const combinedCategory =
                            x && x.id ? indexForUser + (x.category || t("common.empty")).toString() : t("common.empty");

                        if (x) {
                            if (curIndex === index) {
                                return (
                                    <Menu.Item>
                                        <Radio disabled value={combinedCategory} defaultChecked>
                                            {combinedCategory}
                                        </Radio>
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    <Menu.Item onChange={() => swapProduct(index, curIndex)}>
                                        <Radio value={combinedCategory}>{combinedCategory}</Radio>
                                    </Menu.Item>
                                );
                            }
                        } else {
                            return (
                                <Menu.Item onChange={() => swapProduct(index, curIndex)}>
                                    <Radio value={indexForUser + t("common.empty")}>{indexForUser + t("common.empty")}</Radio>
                                </Menu.Item>
                            );
                        }
                    })}
                </Radio.Group>
            </Menu>
        );
    };

    useEffect(() => {
        if (selectMachineForIndex !== -1 && selectedMachineId !== -1) {
            const setShowcase = async () => {
                const selectedMachineData = await doSearchMachineDetail({
                    filter: {
                        machineId: selectedMachineId,
                    },
                    pageNo: 1,
                    pageSize: 1,
                });

                console.log(selectedMachineData);

                let selectedMachine = selectedMachineData?.data?.[0] || undefined;
                var showcaseIndex = products.findIndex((x) => x?.id === selectedMachine?.id);

                if (selectedMachine === undefined || (selectedMachine.monthlyPrice === 0 && selectedMachine.dailyPrice === 0)) {
                    selectedMachine = undefined;
                    setMessage({
                        type: "warning",
                        message: t("showcases.choice.warning-machine-price"),
                    });
                }

                if (selectedMachine?.photoId === 0 || selectedMachine?.photoId === null) {
                    selectedMachine = undefined;
                    setMessage({
                        type: "warning",
                        message: t("showcases.choice.warning-machine-photo"),
                    });
                }

                if (showcaseIndex !== -1) {
                    selectedMachine = undefined;

                    setMessage({
                        type: "warning",
                        message: formatString(t("showcases.choice.warning-duplicate-machine"), showcaseIndex + 1),
                    });
                }

                if (selectedMachine !== undefined) {
                    changeProduct(selectMachineForIndex, selectedMachine);
                    setMessage({
                        type: "success",
                        message: t("showcases.choice.success"),
                    });
                }

                setSelectedMachineId(-1);
                setSelectMachineForIndex(-1);
            };
            setShowcase();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMachineId]);

    return (
        <ListContainer loading={dataFetching}>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("showcases.title")}</h3>
                    </Col>
                    <Col style={{ justifyItems: "flex-start", float: "right" }}>
                        <Row gutter={[16, 16]}>
                            <Col>
                                <SecondaryButton
                                    disabled={loading}
                                    style={{ padding: "0 3rem" }}
                                    onClick={() => {
                                        previewProducts();
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>{t("showcases.preview")}</div>
                                </SecondaryButton>
                            </Col>
                            <Col>
                                <PrimaryButton
                                    disabled={loading}
                                    style={{ padding: "0 3rem" }}
                                    onClick={() => {
                                        publishProducts();
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>{t("showcases.publish")}</div>
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={[0, 16]} style={{ marginBottom: "2rem", columnGap: "6px", marginTop: "2rem" }}>
                    {products &&
                        products?.map((mac: MachineListModel | undefined, i: number) => {
                            let fallbackUsed = false;
                            return (
                                <ProductCard
                                    key={i}
                                    index={i}
                                    pageType="rent"
                                    productItem={
                                        mac
                                            ? {
                                                  id: mac.id || -1,
                                                  head: mac.category || "-",
                                                  text: mac.subCategory || "-",
                                                  daily: formatCurrency(mac.dailyPrice),
                                                  monthly: formatCurrency(mac.monthlyPrice),
                                                  image: (
                                                      <img
                                                          style={{ width: "auto", height: "8rem", borderRadius: "10px" }}
                                                          src={activeApiBaseUrl + "/document/" + mac.photoId}
                                                          alt={mac.category + " " + mac.subCategory}
                                                          onError={(e) => {
                                                              if (!fallbackUsed) {
                                                                  e.currentTarget.src = placeholder; // Provide the path or URL of the fallback photo
                                                                  e.currentTarget.style.width = "4rem";
                                                                  fallbackUsed = true;
                                                              }
                                                          }}
                                                          onLoad={(e) => {
                                                              if (!fallbackUsed) {
                                                                  e.currentTarget.style.width = "auto";
                                                              }
                                                          }}
                                                      ></img>
                                                  ),
                                              }
                                            : undefined
                                    }
                                    handleClick={(i: number, shouldRemove: boolean) => {
                                        if (shouldRemove) {
                                            removeProduct(i);
                                        } else {
                                            setSelectMachineForIndex(i);
                                            setOpenSelectMachine(true);
                                        }
                                    }}
                                    getMenu={getMenuById}
                                ></ProductCard>
                            );
                        })}
                </Row>
            </BoxContainer>

            <SelectMachineForShowCaseModal
                showCardAsContainer={true}
                showCaseNumber={selectMachineForIndex}
                show={openSelectMachine}
                selectMachine={setSelectedMachineId}
                onCloseClick={() => setOpenSelectMachine(false)}
            ></SelectMachineForShowCaseModal>
        </ListContainer>
    );
};
export default Showcases;
