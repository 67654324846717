import { Col, Row } from "antd";
import { moment } from "../helpers/moment";
import { useEffect, useState } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import SelectedItemText from "../components/atomics/selected-item-text";
import BigboxContainer from "../components/containers/bigbox-container";
import { DocumentCategory } from "../service";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";
import {activeApiBaseUrl} from "../store/app-constants";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";
import { useNavigate } from "react-router-dom";

const ViewMachineInfoPart = (props: ViewPartProps) => {
    let editedMachine: ExtendedMachineDetailModel = props.machine || {};
    if (props.request) {
        editedMachine.category = props.request.subCategory?.category;
        editedMachine = {
            ...editedMachine,
            ...props.request.machineFeatures,
            categoryName: props.request.subCategory?.category?.name || "",
            subcategoryName: props.request.subCategory?.name || "",
            subcategoryOther: props.request.subCategoryOther || undefined,
            fuelTypeName: props.request.fuelType?.name || "",
            subCategory: props.request.subCategory,
        };
    }
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [mainImageUrl, setMainImageUrl] = useState<string>("");
    const navigate = useNavigate();

    //for profile images
    useEffect(() => {
        if (imageUrls.length > 0 || !editedMachine || !editedMachine.documents || editedMachine.documents.length <= 0) return;
        const images = editedMachine.documents.filter((docs) => docs.documentCategory === DocumentCategory.Photo);
        if (images.length === 0) return;
        const imageIds = images
            .filter((doc) => doc.document && doc.document.id)
            .map((doc) => activeApiBaseUrl + "/document/" + doc.document?.id);

        if (!imageIds || imageIds.length === 0) return;

        setImageUrls(imageIds);
        setMainImageUrl(imageIds[0]);
        //eslint-disable-next-line
    }, [props.machine]);

    return (
        <BigboxContainer
            title={t("machines.add.machineInfoTitle")}
            buttonTitle={props.request?.machineId ? t("request.machine-detail.buttonTitle") : undefined}
            buttonOnClick={() => {
                navigate("/rent/machines/view?macid=" + props.request?.machineId);
            }}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        {imageUrls?.length > 0 && (
                            <>
                                <Col xs={24} style={{ height: "12rem", background: "#222222", display: "flex", justifyContent: "center" }}>
                                    <img
                                        style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                        src={mainImageUrl}
                                        alt="galleryImages"
                                    />
                                </Col>
                                {imageUrls.map((url) => {
                                    return (
                                        <Col
                                            xs={4}
                                            style={{
                                                height: "5rem",
                                                background: "#222222",
                                                margin: ".25rem",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                                src={url}
                                                alt="galleryImages"
                                                onClick={() => {
                                                    setMainImageUrl(url);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </>
                        )}
                    </Row>
                </Col>

                {props.request && <BasicModelViewer objKey={t("request.view.requestId")} value={props.request?.id} />}
                {props.machine && <BasicModelViewer objKey={t("machine.review.machineId")} value={editedMachine?.id} />}

                {props.request && <BasicModelViewer objKey={t("request.view.machineCount")} value={props.request?.machineCount} />}
                {props.request && <BasicModelViewer objKey={t("request.view.isFirm")} value={props.request?.companyName} />}

                <BasicModelViewer objKey={t("machine.review.machineCategory")} value={editedMachine?.subCategory?.category?.name} />
                <BasicModelViewer
                    objKey={t("machine.review.machineSubcategory")}
                    value={editedMachine?.subcategoryOther ?? editedMachine?.subcategoryName}
                />

                {props.request && <BasicModelViewer objKey={t("request.view.dailyWorkingTime")} value={props.request?.dailyWorkingTime} />}

                {editedMachine?.brandName && <BasicModelViewer objKey={t("machine.review.brandName")} value={editedMachine.brandName} />}
                {editedMachine?.modelName && <BasicModelViewer objKey={t("machine.review.model")} value={editedMachine?.modelName} />}
                {editedMachine?.operationMileage && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operationMileage")} value={editedMachine.operationMileage} />
                )}
                {editedMachine?.operationHour && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operationHour")} value={editedMachine.operationHour} />
                )}
                {editedMachine?.drivingEquipment && (
                    <BasicModelViewer objKey={t("machines.additional-fields.drivingEquipment")} value={editedMachine.drivingEquipment} />
                )}
                {editedMachine?.enginePower && (
                    <BasicModelViewer objKey={t("machines.additional-fields.enginePower")} value={editedMachine.enginePower} />
                )}
                {editedMachine?.operatingWeight && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operatingWeight")} value={editedMachine.operatingWeight} />
                )}
                {editedMachine?.tankCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.tankCapacity")} value={editedMachine.tankCapacity} />
                )}
                {editedMachine?.operatingHeight && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operatingHeight")} value={editedMachine.operatingHeight} />
                )}
                {editedMachine?.operatingLength && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operatingLength")} value={editedMachine.operatingLength} />
                )}
                {editedMachine?.operationWidth && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operationWidth")} value={editedMachine.operationWidth} />
                )}
                {editedMachine?.operatingCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.operatingCapacity")} value={editedMachine.operatingCapacity} />
                )}
                {editedMachine?.usageFields && (
                    <BasicModelViewer objKey={t("machines.additional-fields.usageFields")} value={editedMachine.usageFields} />
                )}
                {editedMachine?.hasCabin && (
                    <BasicModelViewer objKey={t("machines.additional-fields.hasCabin")} value={editedMachine.hasCabin} />
                )}
                {editedMachine?.engineBrand && (
                    <BasicModelViewer objKey={t("machines.additional-fields.engineBrand")} value={editedMachine.engineBrand} />
                )}
                {editedMachine?.hasPlatform && (
                    <BasicModelViewer objKey={t("machines.additional-fields.hasPlatform")} value={editedMachine.hasPlatform} />
                )}
                {editedMachine?.hasTowerElevationSystem && (
                    <BasicModelViewer
                        objKey={t("machines.additional-fields.hasTowerElevationSystem")}
                        value={editedMachine.hasTowerElevationSystem}
                    />
                )}
                {editedMachine?.lightningCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.lightningCapacity")} value={editedMachine.lightningCapacity} />
                )}
                {editedMachine?.carryingCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.carryingCapacity")} value={editedMachine.carryingCapacity} />
                )}
                {editedMachine?.width && <BasicModelViewer objKey={t("machines.additional-fields.width")} value={editedMachine.width} />}
                {editedMachine?.volume && <BasicModelViewer objKey={t("machines.additional-fields.volume")} value={editedMachine.volume} />}
                {editedMachine?.height && <BasicModelViewer objKey={t("machines.additional-fields.height")} value={editedMachine.height} />}
                {editedMachine?.floorCount && (
                    <BasicModelViewer objKey={t("machines.additional-fields.floorCount")} value={editedMachine.floorCount} />
                )}
                {editedMachine?.frequency && (
                    <BasicModelViewer objKey={t("machines.additional-fields.frequency")} value={editedMachine.frequency} />
                )}
                {editedMachine?.minPower && (
                    <BasicModelViewer objKey={t("machines.additional-fields.minPower")} value={editedMachine.minPower} />
                )}
                {editedMachine?.maxPower && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxPower")} value={editedMachine.maxPower} />
                )}
                {editedMachine?.maxLoadCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxLoadCapacity")} value={editedMachine.maxLoadCapacity} />
                )}
                {editedMachine?.nominalCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.nominalCapacity")} value={editedMachine.nominalCapacity} />
                )}
                {editedMachine?.waterTankCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.waterTankCapacity")} value={editedMachine.waterTankCapacity} />
                )}
                {editedMachine?.maxFeedingSize && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxFeedingSize")} value={editedMachine.maxFeedingSize} />
                )}
                {editedMachine?.workingSpeed && (
                    <BasicModelViewer objKey={t("machines.additional-fields.workingSpeed")} value={editedMachine.workingSpeed} />
                )}
                {editedMachine?.cleaningCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.cleaningCapacity")} value={editedMachine.cleaningCapacity} />
                )}
                {editedMachine?.garbageCollectionBin && (
                    <BasicModelViewer
                        objKey={t("machines.additional-fields.garbageCollectionBin")}
                        value={editedMachine.garbageCollectionBin}
                    />
                )}
                {editedMachine?.breakingInfo && (
                    <BasicModelViewer objKey={t("machines.additional-fields.breakingInfo")} value={editedMachine.breakingInfo} />
                )}
                {editedMachine?.bomLength && (
                    <BasicModelViewer objKey={t("machines.additional-fields.bomLength")} value={editedMachine.bomLength} />
                )}
                {editedMachine?.maxHeight && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxHeight")} value={editedMachine.maxHeight} />
                )}
                {editedMachine?.maxDepth && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxDepth")} value={editedMachine.maxDepth} />
                )}
                {editedMachine?.maxWidth && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxWidth")} value={editedMachine.maxWidth} />
                )}
                {editedMachine?.compressionWidth && (
                    <BasicModelViewer objKey={t("machines.additional-fields.compressionWidth")} value={editedMachine.compressionWidth} />
                )}
                {editedMachine?.platformLength && (
                    <BasicModelViewer objKey={t("machines.additional-fields.platformLength")} value={editedMachine.platformLength} />
                )}
                {editedMachine?.platformWidth && (
                    <BasicModelViewer objKey={t("machines.additional-fields.platformWidth")} value={editedMachine.platformWidth} />
                )}
                {editedMachine?.concretePumpingCapacity && (
                    <BasicModelViewer
                        objKey={t("machines.additional-fields.concretePumpingCapacity")}
                        value={editedMachine.concretePumpingCapacity}
                    />
                )}
                {editedMachine?.maxPressure && (
                    <BasicModelViewer objKey={t("machines.additional-fields.maxPressure")} value={editedMachine.maxPressure} />
                )}
                {editedMachine?.freeAirOutput && (
                    <BasicModelViewer objKey={t("machines.additional-fields.freeAirOutput")} value={editedMachine.freeAirOutput} />
                )}
                {editedMachine?.spraySpeed && (
                    <BasicModelViewer objKey={t("machines.additional-fields.spraySpeed")} value={editedMachine.spraySpeed} />
                )}
                {editedMachine?.tankVolume && (
                    <BasicModelViewer objKey={t("machines.additional-fields.tankVolume")} value={editedMachine.tankVolume} />
                )}
                {editedMachine?.loadCapacity && (
                    <BasicModelViewer objKey={t("machines.additional-fields.loadCapacity")} value={editedMachine.loadCapacity} />
                )}
                {editedMachine?.flightTime && (
                    <BasicModelViewer objKey={t("machines.additional-fields.flightTime")} value={editedMachine.flightTime} />
                )}
                {editedMachine?.flightDistance && (
                    <BasicModelViewer objKey={t("machines.additional-fields.flightDistance")} value={editedMachine.flightDistance} />
                )}
                {editedMachine?.workingRadius && (
                    <BasicModelViewer objKey={t("machines.additional-fields.workingRadius")} value={editedMachine.workingRadius} />
                )}
                {editedMachine?.liftingHeight && (
                    <BasicModelViewer objKey={t("machines.additional-fields.liftingHeight")} value={editedMachine.liftingHeight} />
                )}
                {editedMachine?.fuelTypeName && (
                    <BasicModelViewer objKey={t("machines.additional-fields.fuelTypeId")} value={editedMachine.fuelTypeName} />
                )}
                {editedMachine?.cameraResolutionName && (
                    <BasicModelViewer
                        objKey={t("machines.additional-fields.cameraResolution")}
                        value={editedMachine.cameraResolutionName}
                    />
                )}
                {editedMachine?.elevatorTypeName && (
                    <BasicModelViewer objKey={t("machines.additional-fields.elevatorTypeId")} value={editedMachine.elevatorTypeName} />
                )}
                {props.request && <BasicModelViewer objKey={t("request.view.startDate")} value={moment(props.request?.requestDateFrom)} />}
                {props.request && <BasicModelViewer objKey={t("request.view.endDate")} value={moment(props.request?.requestDateTo)} />}

                {props.request?.machineId && (
                    <BasicModelViewer objKey={t("machines.additional-fields.machineId")} value={props.request.machineId} />
                )}
                {props.machine?.attachmentsList && props.machine.attachmentsList.length > 0 && (
                    <Col xs={24}>
                        {t("common.attachments")}
                        <Row gutter={[8, 8]}>
                            {editedMachine?.attachmentsList?.map((atc) => {
                                return (
                                    <Col xs={12} sm={8}>
                                        <SelectedItemText>{atc}</SelectedItemText>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                )}
            </Row>
        </BigboxContainer>
    );
};

export default ViewMachineInfoPart;
