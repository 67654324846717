import { Checkbox, Col, Divider, Row } from "antd";
import styles from "./styles/update-lessor-authorization-modal.module.scss"
import { LessorUserViewModel } from "../service";
import { t } from "../translate";
import { useState } from "react";
import _ from "lodash";
import ModalLayout from "../components/containers/modal-layout";
import PrimaryButton from "../components/atomics/buttons/primary-button";


interface UpdateLessorAuthorizationModalProps {
    show: boolean;
    onCloseClick: () => void;
    onSaveClick: (lessors: number[]) => void;
    lessors: LessorUserViewModel[];
};

const UpdateLessorAuthorizationModal = (props: UpdateLessorAuthorizationModalProps) => {
    const [selectedLessors, setSelectedLessors] = useState<number[]>(
            _.compact(props.lessors.map(e => {
                if(e.isAuthorisedForSale) {
                    return e.id;
                }
                return undefined;
            }   
        ))
    );
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    
    return (
        <ModalLayout show={props.show} onCloseClick={props.onCloseClick}>
            <Row className={styles['container']}>
                <Row>
                    <Col sm={24} className={styles['title-container']}>
                        <span className={styles['title']}>{t("lessor-profile.updateAuthorizedLessors.title")}</span>
                    </Col>
                    <Col sm={24} className={styles['message-text']}>
                        <span>{t("lessor-profile.updateAuthorizedLessors.message")}</span>
                    </Col>
                </Row>
                <Row className={styles['lessor-container']}>
                    {props.lessors.map( lessor => {
                        return (
                            <Row className={styles['lessor-box']}>
                                <Col sm={2}>
                                    <Checkbox 
                                        checked={selectedLessors.includes(lessor.id ?? -1)}
                                        onChange={(e) => {
                                            if (e.target.checked && !selectedLessors.some(e => e === lessor.id)) {
                                                setShowErrorMessage(false);
                                                setSelectedLessors((old) => {
                                                    if (lessor.id) {
                                                        var newList = [...old];
                                                        newList.push(lessor.id);
                                                        return newList;
                                                    }
                                                    else 
                                                        return old;
                                                });
                                            } else if (!e.target.checked) {
                                                setSelectedLessors((old) => {
                                                    if (lessor.id) {
                                                        var newList = [...old].filter(e => e !== lessor.id);
                                                        if (newList.length === 0) {
                                                            setShowErrorMessage(true);
                                                            return old;
                                                        }
                                                        return newList;
                                                    }
                                                    else 
                                                        return old;
                                                });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm={20}>
                                    <Row className={styles['property-container']}>
                                        <span className={styles['property-title']}>{t("lessor-profile.updateAuthorizedLessors.name") + ' :'}</span>
                                        <span className={styles['property-text']}>{lessor.name}</span>
                                    </Row>
                                    <Row className={styles['property-container']}>
                                        <span className={styles['property-title']}>{t("lessor-profile.updateAuthorizedLessors.phone") + ' :'}</span>
                                        <span className={styles['property-text']}>{lessor.phone}</span>
                                    </Row>
                                </Col>
                                <Divider />
                            </Row>
                        );
                    })}
                </Row>
                <Row hidden={!showErrorMessage}>
                    <Col>
                        <span className={styles['error-message']}>{t("lessor-profile.updateAuthorizedLessors.error")}</span>
                    </Col>
                </Row>
                <PrimaryButton 
                    style={{width: '60%', marginTop: '16px'}}
                    onClick={() => props.onSaveClick(selectedLessors)}
                >
                    {t("common.save")}
                </PrimaryButton>
            </Row>
        </ModalLayout>
    );
} 


export default UpdateLessorAuthorizationModal;