/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatchMachineInfoUpdate,
    BatchMachineInfoUpdateFromJSON,
    BatchMachineInfoUpdateFromJSONTyped,
    BatchMachineInfoUpdateToJSON,
} from './BatchMachineInfoUpdate';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';

/**
 * 
 * @export
 * @interface BatchMachineInfoUpdateListValidatedModel
 */
export interface BatchMachineInfoUpdateListValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof BatchMachineInfoUpdateListValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof BatchMachineInfoUpdateListValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {Array<BatchMachineInfoUpdate>}
     * @memberof BatchMachineInfoUpdateListValidatedModel
     */
    data?: Array<BatchMachineInfoUpdate> | null;
}

export function BatchMachineInfoUpdateListValidatedModelFromJSON(json: any): BatchMachineInfoUpdateListValidatedModel {
    return BatchMachineInfoUpdateListValidatedModelFromJSONTyped(json, false);
}

export function BatchMachineInfoUpdateListValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchMachineInfoUpdateListValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(BatchMachineInfoUpdateFromJSON)),
    };
}

export function BatchMachineInfoUpdateListValidatedModelToJSON(value?: BatchMachineInfoUpdateListValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(BatchMachineInfoUpdateToJSON)),
    };
}

