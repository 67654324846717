/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    PreviewViewModel,
    PreviewViewModelFromJSON,
    PreviewViewModelToJSON,
    ShowCaseViewModel,
    ShowCaseViewModelFromJSON,
    ShowCaseViewModelToJSON,
    UpdateShowcaseViewModel,
    UpdateShowcaseViewModelFromJSON,
    UpdateShowcaseViewModelToJSON,
} from '../models';

export interface ShowcasePostRequest {
    updateShowcaseViewModel?: Array<UpdateShowcaseViewModel>;
}

export interface ShowcasePreviewPostRequest {
    previewViewModel?: Array<PreviewViewModel>;
}

/**
 * 
 */
export class ShowcaseApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async showcaseGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ShowCaseViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/showcase`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShowCaseViewModelFromJSON));
    }

    /**
     * Admin
     */
    async showcaseGet(initOverrides?: RequestInit): Promise<Array<ShowCaseViewModel>> {
        const response = await this.showcaseGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async showcasePostRaw(requestParameters: ShowcasePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/showcase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateShowcaseViewModel?.map(UpdateShowcaseViewModelToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async showcasePost(requestParameters: ShowcasePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.showcasePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async showcasePreviewPostRaw(requestParameters: ShowcasePreviewPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ShowCaseViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/showcase/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.previewViewModel?.map(PreviewViewModelToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShowCaseViewModelFromJSON));
    }

    /**
     * Admin
     */
    async showcasePreviewPost(requestParameters: ShowcasePreviewPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ShowCaseViewModel>> {
        const response = await this.showcasePreviewPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
