import { Collapse } from "antd";
import styles from "../styles/accordion.module.scss";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";

type AccordionItems = {
    title?: string | null;
    children: any;
    description?: string;
    imageUrl?: string;
    defaultOpen?: boolean;
    key: number;
};
type AccordionProps = {
    items?: AccordionItems[];
    activeKey?: number;
};

const Accordion = (props: AccordionProps) => {
    const { Panel } = Collapse;
    const children = props.items?.map((item) => {
        const Header = (
            <div className={styles.accordionItemHeader}>
                {item.imageUrl && <img src={"/" + item.imageUrl} alt={item.imageUrl} />}
                <div>
                    <h4>{item.title}</h4>
                    {item.description && <span style={{paddingRight:50}}>{item.description}</span>}
                </div>
            </div>
        );
        if (item) {
            return (
                <Panel key={item.key} header={Header} className={styles.accordionItemPanel}>
                    {item.children}
                </Panel>
            );
        } else return <></>;
    });

    return (
        <Collapse
            expandIconPosition="right"
            bordered={false}
            accordion
            defaultActiveKey={props.activeKey}
            className={styles.accordionCollapse}
            expandIcon={(panelProps) => {
                if (panelProps.isActive) return <Minus className={styles.expandIcons} />;
                else return <Plus className={styles.expandIcons} />;
            }}
        >
            {children}
        </Collapse>
    );
};

export default Accordion;
