/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Int32ValidatedModel,
    Int32ValidatedModelFromJSON,
    Int32ValidatedModelToJSON,
    MachineCommissionCreateModel,
    MachineCommissionCreateModelFromJSON,
    MachineCommissionCreateModelToJSON,
    MachineCommissionViewModel,
    MachineCommissionViewModelFromJSON,
    MachineCommissionViewModelToJSON,
    MachineSaleCommissionCreateModel,
    MachineSaleCommissionCreateModelFromJSON,
    MachineSaleCommissionCreateModelToJSON,
    MachineSaleCommissionViewModel,
    MachineSaleCommissionViewModelFromJSON,
    MachineSaleCommissionViewModelToJSON,
    MachineSaleDepositFeeCreateModel,
    MachineSaleDepositFeeCreateModelFromJSON,
    MachineSaleDepositFeeCreateModelToJSON,
    MachineSaleDepositFeeViewModel,
    MachineSaleDepositFeeViewModelFromJSON,
    MachineSaleDepositFeeViewModelToJSON,
} from '../models';

export interface MachineCommissionsForFeeCreatePostRequest {
    machineSaleDepositFeeCreateModel?: MachineSaleDepositFeeCreateModel;
}

export interface MachineCommissionsForRentCreatePostRequest {
    machineCommissionCreateModel?: MachineCommissionCreateModel;
}

export interface MachineCommissionsForSaleCreatePostRequest {
    machineSaleCommissionCreateModel?: MachineSaleCommissionCreateModel;
}

/**
 * 
 */
export class MachineCommissionApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async machineCommissionsForFeeCreatePostRaw(requestParameters: MachineCommissionsForFeeCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-fee-create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSaleDepositFeeCreateModelToJSON(requestParameters.machineSaleDepositFeeCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineCommissionsForFeeCreatePost(requestParameters: MachineCommissionsForFeeCreatePostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.machineCommissionsForFeeCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForFeeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MachineSaleDepositFeeViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-fee`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineSaleDepositFeeViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForFeeGet(initOverrides?: RequestInit): Promise<Array<MachineSaleDepositFeeViewModel>> {
        const response = await this.machineCommissionsForFeeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineCommissionsForRentCreatePostRaw(requestParameters: MachineCommissionsForRentCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-rent-create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineCommissionCreateModelToJSON(requestParameters.machineCommissionCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineCommissionsForRentCreatePost(requestParameters: MachineCommissionsForRentCreatePostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.machineCommissionsForRentCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForRentGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MachineCommissionViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-rent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineCommissionViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForRentGet(initOverrides?: RequestInit): Promise<Array<MachineCommissionViewModel>> {
        const response = await this.machineCommissionsForRentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async machineCommissionsForSaleCreatePostRaw(requestParameters: MachineCommissionsForSaleCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-sale-create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSaleCommissionCreateModelToJSON(requestParameters.machineSaleCommissionCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async machineCommissionsForSaleCreatePost(requestParameters: MachineCommissionsForSaleCreatePostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.machineCommissionsForSaleCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForSaleGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MachineSaleCommissionViewModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine-commissions/for-sale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineSaleCommissionViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async machineCommissionsForSaleGet(initOverrides?: RequestInit): Promise<Array<MachineSaleCommissionViewModel>> {
        const response = await this.machineCommissionsForSaleGetRaw(initOverrides);
        return await response.value();
    }

}
