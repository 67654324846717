import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { t } from "../translate";
import { ReactComponent as Approved } from "../../src/components/assets/approved.svg";
import { Col, Row } from "antd";

const SuccessApproveSaleMachinesModal = (props: ModalLayoutProps) => {
    return (
        <ModalLayout {...props}>
            <Row align="middle" justify="center">
                <Col>
                    <Approved />
                </Col>
                <Col>
                    <ModalCardContainer title={t("success.approve.sale.machines.modal.title")}>{props.children}</ModalCardContainer>
                </Col>
            </Row>
        </ModalLayout>
    );
};

export default SuccessApproveSaleMachinesModal;
