import { Col, Divider, Input, Row } from "antd";
import { BasicModel, OfferFormBidViewModel } from "../../service/models";
import styles from "../styles/payment.module.scss";
import { t } from "../../translate";
import { useEffect, useState } from "react";
import { useSecureService } from "../../store/service-atoms";

type PaymentContainerProps = {
    bid?: OfferFormBidViewModel;
    onBidChange?: (param: OfferFormBidViewModel) => void;
    machines?: BasicModel[] | null;
};

export const PaymentContainer = (props: PaymentContainerProps) => {
    const getTotal = (total: number, num: number) => {
        return total + Math.ceil(num);
    };
    const totalBid =
        (props.bid?.predictedTotal || 0) +
        (props.bid?.machineGoCommission || 0) +
        (props.bid?.shippingFeeWithCommission ? props.bid?.shippingFeeWithCommission : 0) +
        (props.bid?.shippingToBackFeeWithCommission ? props.bid?.shippingToBackFeeWithCommission : 0) +
        (props.bid?.attachments
            ? props.bid.attachments.map((e) => {
                  return e.fee ?? 0;
              })
                ? props.bid.attachments
                      .map((e) => {
                          return e.fee ?? 0;
                      })
                      .reduce(getTotal, 0)
                : 0
            : 0);
    const [availableAttachments, setAvailableAttachments] = useState<BasicModel[]>([]);
    const { doGetMachine, doGetAttachments } = useSecureService();

    //const predictedMachineOwnerOffer = (props.bid?.shippingFee ?? 0) + (props.bid?.shippingToBackFee ?? 0) + (props.bid?.attachments ? (props.bid.attachments.map(e=> {return e.fee ?? 0}) ? props.bid.attachments.map(e=> {return e.fee ?? 0}).reduce(getTotal, 0) : 0) : 0);

    useEffect(
        () => {
            const getServiceData = async () => {
                if (props.machines && props.machines[0].id) {
                    const machine = await doGetMachine(props.machines[0].id);
                    if (!machine) return;
                    if (!machine["subCategoryId"]) return;
                    const attachments = await doGetAttachments(machine["subCategoryId"]);
                    setAvailableAttachments(attachments || []);
                }
            };
            getServiceData();
        },
        //eslint-disable-next-line
        []
    );

    useEffect(() => {
        const bidModel : OfferFormBidViewModel = {
            ...props.bid,
            total: totalBid,
        };
        if (props.onBidChange) props.onBidChange(bidModel);
        //eslint-disable-next-line
    }, [totalBid]);

    return (
        <div {...props}>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[0, 16]} justify="space-between">
                <Col md={12} xs={12}>
                    <Row justify="space-between">
                        <Col md={12} className={styles.labelStyle}>
                            {t("offers.view.period")}
                        </Col>
                        <Col md={12} className={styles.priceStyle}>
                            {props.bid?.paymentPeriod?.name}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} xs={12}>
                    <Row justify="space-between">
                        <Col md={12} className={styles.labelStyle}>
                            {t("offers.view.price")}
                        </Col>
                        <Col md={12} className={styles.priceStyle}>
                            {props.bid?.bid} {props.bid?.currency?.name}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[0, 16]} className={styles.priceTable} justify="space-between">
                <Col xs={16}>{t("offers.view.predictedTotal")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const calp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                predictedTotal: calp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.predictedTotal}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.machineGoCommission")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const comm = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                machineGoCommission: comm,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.machineGoCommission}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>

                <Col xs={16}>{t("offers.view.advancePayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const ladv = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            if (props.onBidChange)
                                props.onBidChange({
                                    ...props.bid,
                                    advancePayment: ladv,
                                });
                        }}
                        value={props?.bid?.advancePayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>

                <Col xs={16}>{t("offers.view.renterPeriodPayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const renterp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                renterPeriodPayment: renterp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.renterPeriodPayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.lessorMachineGoCommission")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const lessorc = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                lessorMachineGoCommission: lessorc,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.lessorMachineGoCommission}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.lessorPeriodPayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const lessorp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                lessorPeriodPayment: lessorp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.lessorPeriodPayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.shippingFee")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const shippingFee = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel : OfferFormBidViewModel= {
                                ...props.bid,
                                shippingFeeWithCommission: shippingFee,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.shippingFeeWithCommission ? props.bid?.shippingFeeWithCommission : 0}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.shippingReturnFee")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const shippingToBackFee = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel : OfferFormBidViewModel = {
                                ...props.bid,
                                shippingToBackFeeWithCommission: shippingToBackFee
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.shippingToBackFeeWithCommission ? props.bid?.shippingToBackFeeWithCommission : 0}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
                <span style={{ fontWeight: 700 }}>{t("offers.view.attachmentsTitle")}</span>
            </Row>
            <Row style={{ marginTop: "0.5rem", display: "flex", gap: "6px", justifyContent: "space-between" }} gutter={[0, 12]}>
                {props.bid &&
                    props.bid.attachments &&
                    props.bid.attachments.map((e) => {
                        return (
                            <Col
                                style={{
                                    background: "#EEF0F2",
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    fontSize: "0.9rem",
                                }}
                                xs={24}
                                md={11}
                            >
                                <span style={{ color: "#282828" }}>
                                    {availableAttachments.find((element) => element.id === e.attachmentId)?.name ?? ""}
                                </span>
                                <span style={{ fontWeight: 800 }}>{(e.fee ?? 0).toString() + " " + props.bid?.currency?.name ?? ""}</span>
                            </Col>
                        );

                        /* /* AttachmentCard( availableAttachments.find(element => element.id === e.attachmentId)?.name ?? "" , (e.fee ?? 0).toString(), props.bid?.currency?.name ?? "")  ; */
                    })}
            </Row>
            <Row style={{ marginTop: "1rem", display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                <Col className={styles.labelStyle} xs={16}>
                    {t("offers.view.totalBid")}
                </Col>
                <Col className={styles.totalPriceLabel} xs={8}>
                    {totalBid} {props.bid?.currency?.name}
                </Col>
            </Row>
            <Divider style={{ marginTop: "0", marginBottom: "0.75rem" }}></Divider>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                <Col style={{ fontWeight: 700 }}>{t("offers.view.paymentLink")}</Col>
                <Col>
                    <Input
                        placeholder="https://www.example.com/abc"
                        onChange={(e) => {
                            if (props.onBidChange)
                                props.onBidChange({
                                    ...props.bid,
                                    paymentLink: e.currentTarget.value,
                                });
                        }}
                        value={props.bid?.paymentLink || undefined}
                    ></Input>
                </Col>
            </Row>
        </div>
    );
};
