import { Col, message, Row, Select, Space, TablePaginationConfig, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ListContainer from "../../components/containers/list-container";
import {
    BasicModel,
    DocumentCategory,
    MachineDocumentViewModel,
    MachineSearchFilterModel,
    MachineSearchSortField,
} from "../../service/models";
import { PaginationType, useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";
import { CSVLink } from "react-csv";
import { extendedMachineDetailAtom, loadingAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import DataTable from "../../components/atomics/data-table";
import PrimaryButton from "../../components/atomics/buttons/primary-button";
import { ReactComponent as PlusB } from "./../../components/assets/plus-black.svg";
import { ReactComponent as CsvDownload } from "./../../components/assets/download-csv.svg";
import { ReactComponent as Edit } from "./../../components/assets/edit.svg";
import SecondaryButton from "../../components/atomics/buttons/secondary-button";
import SelectCompany from "../../modals/select-company";
import { ExtendedMachineDetailModel } from "../../service/models/ExtendedMachineDetailModel";
import MachineListFilter from "../../components/containers/machine-list-filter";
import { useQuery } from "../../helpers/use-query";
import styles from "./style/machine.module.css";
import { Data } from "react-csv/components/CommonPropTypes";
import { useCsvExport } from "../../helpers/useTableData";
import moment from "moment";
import BoxContainer from "../../components/containers/box-container";
import { activeApiBaseUrl } from "../../store/app-constants";
import { ReactComponent as Placeholder } from "./../../components/assets/placeholder.svg";

export type TableModel = {
    CreationDate?: string;
    companyName?: string | null;
    districtAndCity: string | undefined;
    subCategoryName?: string | null;
    allAttachments?: string | null;
    fuelTypeName?: string | null;
    rentalTypeNames?: string[] | null;
    serviceRegionNames?: string[] | null;
    attachmentNames?: string[] | null;
    machineNotes?: string;
    ModifiedDate?: string;
    Documents?: string;
    images?: MachineDocumentViewModel[] | null;
} & ExtendedMachineDetailModel;

const { Option } = Select;

export const castToTableModelArray = (data: ExtendedMachineDetailModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        let attachmentsValue = item?.attachments?.map((atc) => {
            return atc.name;
        });

        let cities = item?.serviceRegions?.map((cty) => {
            return cty.name;
        });

        const tableModel: TableModel = {
            id: item.id,
            brandName: item.brand?.name ? item.brand.name : "",
            companyName: item.lessorCompany?.name,
            districtAndCity: cities?.join(", "),
            creationDate: item.creationDate,
            subCategoryName: item.subCategory?.name,
            allAttachments: attachmentsValue?.join(", "),
            images: item.documents,
            ...item,
        };
        return tableModel;
    });
    return newList;
};

const Machines = () => {
    const { doSearchMachineByApprovalStatus } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [selectcompanyVisible, toggleSelectCompanyVisible] = useState(false);
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);
    const [canvas, setCanvas] = useState(false);
    const query = useQuery();
    const machineId = query.get("macid");
    const compName = query.get("compname");
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetMachine } = useSecureService();
    const [doGetMachineTableData] = useCsvExport();
    const [filterObj, setFilterObj] = useState<{
        filterModel: MachineSearchFilterModel;
        sortBy: {
            field: MachineSearchSortField;
            sortAscending: boolean;
        };
    }>({
        filterModel: {
            companyName: compName,
        },
        sortBy: {
            field: MachineSearchSortField.ApprovalDate,
            sortAscending: false,
        },
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                });
        }
        //eslint-disable-next-line
    }, []);
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const columns = [
        canvas
            ? {
                  title: t("machines.table.image"),
                  key: "images",
                  render: (record: TableModel) => {
                      // Makinenin belgelerinden fotoğrafları filtreleyin
                      console.log(record);
                      const images = record.documents?.filter((doc) => doc.documentCategory === DocumentCategory.Photo);
                      console.log(images);
                      // Fotoğraf belgelerinin URL'lerini oluşturun
                      const imageUrls = images?.map((doc) => activeApiBaseUrl + "/document/" + doc.document?.id);

                      // İlk fotoğrafın URL'sini döndürün veya boş döndürün
                      const firstImageUrl = imageUrls && imageUrls.length > 0 ? imageUrls[0] : "";

                      // İlk fotoğraf varsa, bu fotoğrafı gösterin
                      if (firstImageUrl) {
                          return (
                              <a href={firstImageUrl} target="_blank" rel="noopener noreferrer">
                                  <div
                                      style={{
                                          width: "200px",
                                          height: "150px",
                                          overflow: "hidden",
                                          borderRadius: "8px",
                                          border: "1px solid #ddd",
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                      }}
                                  >
                                      <img
                                          src={firstImageUrl}
                                          alt="Machine"
                                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                      />
                                  </div>
                              </a>
                          );
                      } else {
                          return (
                              <a href={firstImageUrl} target="_blank" rel="noopener noreferrer">
                                  <div
                                      style={{
                                          width: "200px",
                                          height: "150px",
                                          overflow: "hidden",
                                          borderRadius: "8px",
                                          border: "1px solid #ddd",
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          background: "#f5f5f5",
                                      }}
                                  >
                                      <Placeholder />
                                  </div>
                              </a>
                          );
                      }
                  },
              }
            : {},

        {
            title: t("machines.table.machineId"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.review")}>
                        <Link className={styles.tooltipText} to={"/rent/machines/view?macid=" + text}>
                            {text}
                        </Link>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) =>
                canvas ? (
                    <a className={styles.tooltipText} href={"/lessor-company/view?compid=" + val.id} target="blank">
                        <div className={styles.firmName}>{val.name}</div>
                    </a>
                ) : (
                    <div className={styles.firmName}>{val.name}</div>
                ),
        },
        {
            title: t("machines.table.adDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("machines.table.machineRentStatus"),
            dataIndex: "machineRentStatus",
            key: "machineRentStatus",
            render: (val: BasicModel) => (
                <div
                    className={styles.machineStatus}
                    style={{
                        width: "76px",
                        height: "24px",
                        flexShrink: 0,
                        borderRadius: "100px",
                        background: "#35B934",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Mulish",
                            fontSize: "11px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                            letterSpacing: "0.5px",
                            textTransform: "uppercase",
                            color: "#FFF",
                        }}
                    >
                        {t("machine.status.ok")}
                    </span>
                </div>
            ),
        },
        {
            title: t("common.forSaleAd"),
            dataIndex: "isMachineForSale",
            key: "isMachineForSale",
            render: (val: boolean, record: any) => (
                <Space size="middle">
                    {val ? (
                        <Tooltip title={t("common.review")}>
                            <Link
                                className={styles.tooltipText}
                                to={
                                    record.isApprovedForSale
                                        ? "/sale/machines/view?macid=" + record.id
                                        : "/sale/pending-approval/detail?macid=" + record.id
                                }
                            >
                                <Col>{t("common.review")}</Col>
                            </Link>
                        </Tooltip>
                    ) : (
                        <>-</>
                    )}
                </Space>
            ),
        },
        {
            title: t("common.edit"),
            key: "edit",
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.edit")}>
                        <Link
                            onAuxClick={() => {
                                editMachine(record.id);
                            }}
                            to={"/rent/machines/edit?macid=" + record.id}
                        >
                            <Edit />
                        </Link>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        console.log(tablePagination);
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current, pageSize: tablePagination.pageSize };
        });
    };

    useEffect(() => {
        const fillMachineList = async () => {
            try {
                const result = await doSearchMachineByApprovalStatus(
                    {
                        pageNo: pagination.current,
                        pageSize: pagination.pageSize,
                        filter: filterObj.filterModel,
                        sortAscending: filterObj.sortBy.sortAscending,
                        sortBy: filterObj.sortBy.field,
                    },
                    true
                );
                if (result?.machines !== null) {
                    setItemCount(result?.itemCount);
                    setMachines(await doGetMachineTableData(result?.machines, false));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchMachineByApprovalStatus(
            {
                pageNo: 1,
                pageSize: itemCount,
                sortAscending: false,
                sortBy: MachineSearchSortField.ApprovalDate,
            },
            true
        );
        if (result?.machines?.length !== undefined && result?.machines?.length > 0) {
            const exportData = await doGetMachineTableData(result?.data, true);
            if (exportData?.length !== undefined && exportData?.length > 0) {
                setAllRows(exportData);
                csvRef.current?.link.click();
            }
            //result.data[0].rentalTypes[0].rentalFee
        }
    };

    return (
        <ListContainer>
            <MachineListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                    });
                }}
            ></MachineListFilter>
            <BoxContainer>
                <Row justify="space-between" align="middle" style={{ marginBottom: "1rem" }}>
                    <Col>
                        {t("menu.listMachines")} -{" "}
                        <strong>
                            {" "}
                            ({t("common.total")} {itemCount})
                        </strong>
                    </Col>
                    <Col style={{ float: "right" }}>
                        <PrimaryButton
                            onClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                            style={{ marginRight: "0.5rem", width: "inherit", marginBottom: "0px" }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <PlusB style={{ marginRight: "0.5rem" }} />
                                {t("success.machine.middleButtonText")}
                            </div>
                        </PrimaryButton>
                        <SecondaryButton onClick={exportClick} style={{ width: "initial", marginBottom: "0px" }}>
                            <CsvDownload style={{ display: "flex" }} />
                        </SecondaryButton>
                    </Col>
                </Row>
                <CSVLink filename="machines.csv" ref={csvRef} data={allRows} separator={";"} />
                <Row justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col md={4}>
                        <Select
                            style={{ width: 350 }}
                            placeholder={t("common.sortBy")}
                            allowClear
                            onChange={(val) => {
                                setFilterObj((filter) => {
                                    return {
                                        ...filter,
                                        sortBy: {
                                            sortAscending: val === "sortByAsc",
                                            field: MachineSearchSortField.ApprovalDate,
                                        },
                                    };
                                });
                            }}
                        >
                            <Option value="sortByAsc">{t("common.sortByAsc")}</Option>
                            <Option value="sortByDesc">{t("common.sortByDesc")}</Option>
                        </Select>
                    </Col>

                    <Col>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder={t("machines.table.view")}
                                    onChange={(val) => {
                                        setCanvas(val);
                                    }}
                                >
                                    <Option value={false}>{t("machines.table.setList")}</Option>
                                    <Option value={true}>{t("machines.table.setCanvas")}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                {
                    <DataTable
                        onChange={handleChange}
                        columns={columns}
                        dataSource={machines}
                        loading={loading}
                        pagination={{
                            ...pagination,
                            total: itemCount,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                            pageSizeOptions: ["10", "20", "50", "100", "250", "500"],
                        }}
                    />
                }
            </BoxContainer>
            <SelectCompany
                onCloseClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                show={selectcompanyVisible}
            ></SelectCompany>
        </ListContainer>
    );
};

export default Machines;
