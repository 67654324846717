import DataTable from "../components/atomics/data-table";
import { BasicModel, OfferFormRenterViewModel } from "../service";
import { t } from "../translate";
import BigboxContainer from "../components/containers/bigbox-container";
import { moment } from "../helpers/moment";
import { OfferFormRenterBidViewModel } from "../service/models/OfferFormRenterBidViewModel";
import RequestAndOfferStatus from "../components/atomics/request-and-offer-status";
import "../styles/request-offers-parts.scss";

type AccepteOfferPartsProps = {
    selectedMachineId?: number | null | undefined;
    Acceptedoffers?: OfferFormRenterViewModel[] | null;
    onRow?: (record: OfferFormRenterViewModel | undefined) => void;
};

const AccepteOfferPart = (props: AccepteOfferPartsProps) => {
    const cols = [
        {
            title: t("offers.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("offers.table.company"),
            dataIndex: "company",
            key: "company",
        },
        {
            title: t("offers.table.machines"),
            dataIndex: "machines",
            key: "machines",
            render: (val: BasicModel[]) => {
                const machineTitles = val.map((bm) => bm.name).join("\n");
                return <>{machineTitles}</>;
            },
        },
        {
            title: t("offers.table.notes"),
            dataIndex: "notes",
            key: "notes",
        },
        {
            title: t("offers.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.expireDate"),
            dataIndex: "expireDate",
            key: "expireDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.rentalStatus"),
            dataIndex: "status",
            key: "status",
            render: (val: string) => {
                return <RequestAndOfferStatus showRequestStatus={false} showOfferStatus={true} offerStatus={val}></RequestAndOfferStatus>;
            },
        },
        {
            title: t("offers.table.bid"),
            dataIndex: "bids",
            key: "bids",
            render: (val: OfferFormRenterBidViewModel[]) => {
                const offerBids = val.map((ob) => ob.total?.toString()).join("\n");
                return <>{offerBids}</>;
            },
        },
    ];

    const onRowHandler = (record: any, rowIndex: number | undefined) => {
        return {
            onClick: (event: any) => {
                if (props.onRow) props.onRow(record);
            },
        };
    };

    return (
        <BigboxContainer title={t("offers.Accepted")}>
            {props.Acceptedoffers && (
                <DataTable
                    rowClassName={(record, index) => {
                        if (record.machines.some((machine: BasicModel) => machine.id === props.selectedMachineId)) {
                            return "selected";
                        }
                        return "";
                    }}
                    onRow={onRowHandler}
                    dataSource={props.Acceptedoffers}
                    columns={cols}
                    pagination={false}
                />
            )}
        </BigboxContainer>
    );
};

export default AccepteOfferPart;
