import { Routes, Route, BrowserRouter } from "react-router-dom";
import { MenuKeys } from "./helpers/menu-keys";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                {MenuKeys.map(item=>{
                    var fullPath = "/";
                    item.parents.forEach((prt)=>{
                        fullPath += prt.parent+"/";
                    });
                    fullPath += item.path;
                    console.log("\n\npaths: ",fullPath);
                    if (item.hidden !== true) {
                        return <Route key={fullPath} path={fullPath} element={item.element} />
                    }
                    return undefined;
                })};
            </Routes>
        </BrowserRouter>
    );
}

export default App;
