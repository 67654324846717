import { Card } from "antd";
import styles from "../../styles/components.module.css";

export type CardContainerProps = {
    children: any;
    title?: string;
};

const CardContainer = (props: CardContainerProps) => {
    return (
        <Card className={styles.card} title={props.title}>
            {props.children}
        </Card>
    );
};

export default CardContainer;
