/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateShowcaseViewModel
 */
export interface UpdateShowcaseViewModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateShowcaseViewModel
     */
    rank?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShowcaseViewModel
     */
    machineId?: number | null;
}

export function UpdateShowcaseViewModelFromJSON(json: any): UpdateShowcaseViewModel {
    return UpdateShowcaseViewModelFromJSONTyped(json, false);
}

export function UpdateShowcaseViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShowcaseViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
    };
}

export function UpdateShowcaseViewModelToJSON(value?: UpdateShowcaseViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rank': value.rank,
        'machineId': value.machineId,
    };
}

