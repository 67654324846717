/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineListSearchSortField {
    ModifiedDateDesc = 'ModifiedDateDesc',
    ModifiedDateAsc = 'ModifiedDateAsc',
    MonthlyPriceAsc = 'MonthlyPriceAsc',
    MonthlyPriceDesc = 'MonthlyPriceDesc',
    DailyPriceAsc = 'DailyPriceAsc',
    DailyPriceDesc = 'DailyPriceDesc',
    ShowCases = 'ShowCases'
}

export function MachineListSearchSortFieldFromJSON(json: any): MachineListSearchSortField {
    return MachineListSearchSortFieldFromJSONTyped(json, false);
}

export function MachineListSearchSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineListSearchSortField {
    return json as MachineListSearchSortField;
}

export function MachineListSearchSortFieldToJSON(value?: MachineListSearchSortField | null): any {
    return value as any;
}

