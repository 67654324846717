/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MachineSearchSortField {
    CreationDate = 'CreationDate',
    ProductionYear = 'ProductionYear',
    ApprovalDate = 'ApprovalDate',
    RentalModifiedDate = 'RentalModifiedDate',
    SaleModifiedDate = 'SaleModifiedDate'
}

export function MachineSearchSortFieldFromJSON(json: any): MachineSearchSortField {
    return MachineSearchSortFieldFromJSONTyped(json, false);
}

export function MachineSearchSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSearchSortField {
    return json as MachineSearchSortField;
}

export function MachineSearchSortFieldToJSON(value?: MachineSearchSortField | null): any {
    return value as any;
}

