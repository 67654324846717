import { TableModel } from "../pages/admin";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel"
import { moment } from "../helpers/moment";
import _ from "lodash";

export const useCsvExport = () => {

    const doGetMachineTableData = async (data: ExtendedMachineDetailModel[] | null | undefined, forCsvFile?: Boolean) => {
        var forbiddenCharsInNotes = ['"', "'"];
        var regexForNotes = new RegExp(forbiddenCharsInNotes.join('|'), 'g');
        const newList = data?.map((item) => {
            const attachmentsValue = item?.attachments?.map((atc) => {
                return atc.name;
            });
    
            const cities = item?.serviceRegions?.map((cty) => {
                return cty.name;
            });
    
            const rentalTypeNames: string[] =  item.rentalTypes ? item.rentalTypes?.map(e => {
                return e.rentalType?.name ?? '';
            }) : [];
    
            const serviceRegionNames: string[] =  item.serviceRegions ? item.serviceRegions?.map(e => {
                return e.name ?? '';
            }) : [];
    
            const attachmentNames: string[] =  item.attachments ? item.attachments?.map(e => {
                return e.name ?? '';
            }) : [];

            const documentsArr = _.compact(item.documents?.map(e => e.document?.fileName));
            const documents = documentsArr.reduce((prev, cur, index) => {return prev + (index !== 0 ? ',' : '') + cur}, "" );
    
            const tableModel: TableModel = {
                id: item.id,
                brandName: item.brand?.name ? item.brand.name : "",
                companyName: item.lessorCompany?.name,
                districtAndCity: cities?.join(", "),
                CreationDate: moment(item.creationDate, "DD.MM.YYYY"),
                ModifiedDate: moment(item.modifiedDate, "DD.MM.YYYY"),
                subCategoryName: item.subCategory?.name,
                allAttachments: attachmentsValue?.join(", "),
                fuelTypeName: item.fuelTypeName ?? '',
                elevatorTypeName: item.elevatorTypeName ?? '',
                rentalTypeNames:  rentalTypeNames,
                serviceRegionNames: serviceRegionNames,
                attachmentNames: attachmentNames,
                cameraResolutionName: item.cameraResolution?.name ?? '',
                machineNotes: item.notes?.replaceAll(regexForNotes, ""),
                Documents: documents ?? "",
                ...item,
            };
            if (forCsvFile) {
                delete tableModel.creationDate;
                delete tableModel.modifiedDate;
                delete tableModel.notes;
                delete tableModel.subCategory;
                delete tableModel.brand;
                delete tableModel.fuelType;
                delete tableModel.elevatorType;        
                delete tableModel.lessorCompany;
                delete tableModel.rentalTypes;
                delete tableModel.serviceRegions;
                delete tableModel.attachments;
                delete tableModel.documents;
                delete tableModel.cameraResolution;
            }

            return tableModel;
        });
        return newList;
    }

    return [
        doGetMachineTableData,
    ] 
}