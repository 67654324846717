import { Col, message, Row, Select, Space, TablePaginationConfig, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ListContainer from "../../../components/containers/list-container";
import { BasicModel, MachineSearchFilterModel, MachineSearchSortField } from "../../../service/models";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { CSVLink } from "react-csv";
import { extendedMachineDetailAtom, loadingAtom, postloginMessageAtom } from "../../../store/global-atoms";
import { useAtom } from "jotai";
import DataTable from "../../../components/atomics/data-table";
import { ReactComponent as CsvDownload } from "../../../components/assets/download-csv.svg";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { moment } from "../../../helpers/moment";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import { useQuery } from "../../../helpers/use-query";
import styles from "../style/machine.module.css";
import { ReactComponent as Plus } from "../../../components/assets/plus.svg";
import BoxContainer from "../../../components/containers/box-container";
import MachineSaleListFilter from "../../../components/containers/machine-sale-list-filter";
import { Data } from "react-csv/components/CommonPropTypes";
import { useCsvExport } from "../../../helpers/useTableData";

export type TableModel = {
    companyName?: string | null;
    districtAndCity: string | undefined;
    subCategoryName?: string | null;
    allAttachments?: string | null;
} & ExtendedMachineDetailModel;

const { Option } = Select;

export const castToTableModelArray = (data: ExtendedMachineDetailModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        let attachmentsValue = item?.attachments?.map((atc) => {
            return atc.name;
        });

        let cities = item?.serviceRegions?.map((cty) => {
            return cty.name;
        });

        const tableModel: TableModel = {
            id: item.id,
            brandName: item.brand?.name ? item.brand.name : "",
            companyName: item.lessorCompany?.name,
            districtAndCity: cities?.join(", "),
            creationDate: item.creationDate,
            subCategoryName: item.subCategory?.name,
            allAttachments: attachmentsValue?.join(", "),
            ...item,
        };
        return tableModel;
    });
    return newList;
};

const RemovedFromSaleMachines = () => {
    const { doSearchSaleMachine, doRepublishSaleMachine } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);
    const query = useQuery();
    const machineId = query.get("macid");
    const compName = query.get("compname");
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetMachine, doGetCurrencies } = useSecureService();
    const navigate = useNavigate();
    const [doGetMachineTableData] = useCsvExport();
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [filterObj, setFilterObj] = useState<{
        filterModel: MachineSearchFilterModel;
        sortBy: {
            field: MachineSearchSortField;
            sortAscending: boolean;
        };
    }>({
        filterModel: {
            companyName: compName,
            isMachineRemovedFromSale: true,
            onlySaleMachines: true,
        },
        sortBy: {
            field: MachineSearchSortField.SaleModifiedDate,
            sortAscending: false,
        },
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                });
        }
        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };
        getCurrencies();
        //eslint-disable-next-line
    }, []);
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const columns = [
        {
            title: t("machines.id"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.review")}>
                        <Link
                            onClick={(event) => event.stopPropagation()}
                            className={styles.tooltipText}
                            to={"/sale/removed/view?macid=" + detail.id}
                        >
                            <Col>{text}</Col>
                        </Link>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) => <div className={styles.firmName}>{val.name}</div>,
        },
        {
            title: t("machines.table.adDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.machineOwnerListPrice"),
            key: "machineSalePrice",
            render: (text: any, record: TableModel) => {
                const currencyName = availableCurrencies?.find((cur) => cur.id === record.machineSalePriceCurrencyId)?.name || "";
                return (
                    <div>
                        <span>{record.machineSalePrice?.toFixed(2)}</span>
                        <span className={styles.currencyText}> {currencyName}</span>
                    </div>
                );
            },
        },
        {
            title: t("common.forRentAd"),
            dataIndex: "rentalTypes",
            key: "rentalTypes",
            render: (val: [], record: any) => (
                <Space size="middle">
                    {val.length > 0 ? (
                        <Tooltip title={t("common.review")}>
                            <Link
                                onClick={(event) => event.stopPropagation()}
                                className={styles.tooltipText}
                                to={
                                    record.isApproved
                                        ? "/rent/machines/view?macid=" + record.id
                                        : "/rent/pending-approval/detail?macid=" + record.id
                                }
                            >
                                <Col>{t("common.review")}</Col>
                            </Link>
                        </Tooltip>
                    ) : (
                        <>-</>
                    )}
                </Space>
            ),
        },
        {
            title: t("common.republish"),
            key: "action",
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.republish")}>
                        <div
                            className={styles.approveIcon}
                            onClick={(event) => {
                                event.stopPropagation();
                                rePublishSaleMachine(record.id);
                            }}
                        >
                            <Plus />
                        </div>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        const fillMachineList = async () => {
            try {
                const result = await doSearchSaleMachine(
                    {
                        pageNo: pagination.current,
                        pageSize: pagination.pageSize,
                        filter: filterObj.filterModel,
                        sortAscending: filterObj.sortBy.sortAscending,
                        sortBy: filterObj.sortBy.field,
                    },
                );
                if (result?.machines !== null) {
                    setItemCount(result?.itemCount);
                    setMachines(castToTableModelArray(result?.machines));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchSaleMachine({ filter: {onlySaleMachines: true, isMachineRemovedFromSale: true}, pageNo: 1, pageSize: itemCount });
        if (result?.machines?.length !== undefined && result?.machines?.length > 0) {
            const exportData = await doGetMachineTableData(result?.data, true);
            if (exportData?.length !== undefined && exportData?.length > 0) {
                setAllRows(exportData);
                csvRef.current?.link.click();
            }
        }
    };

    const rePublishSaleMachine = async (machineId: string) => {
        const machineIdN = parseInt(machineId);
        const result = await doRepublishSaleMachine(machineIdN);
        if (result){
            setErrorMessage({
                type: "success",
                message: t("common.publich-message"),
            });
            navigate("/sale/machines");
        }
    }

    return (
        <ListContainer>
            <MachineSaleListFilter
                initialFilter={filterObj.filterModel}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                    });
                }}
            ></MachineSaleListFilter>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("menu.removed-machines")} - <strong> ({t("common.total")} {itemCount})</strong></h3>
                    <Col style={{ float: "right" }}>
                        <SecondaryButton onClick={exportClick} style={{ width: "initial", marginBottom: "0px" }}>
                            <CsvDownload style={{ display: "flex" }} />
                        </SecondaryButton>
                    </Col>
                </Row>
                <CSVLink filename="machines.csv" ref={csvRef} data={allRows} separator={";"} />
                <Col md={4}>
                    <Select
                        style={{ width: 350 }}
                        placeholder={t("common.sortBy")}
                        allowClear
                        defaultValue={"sortByDesc"}
                        onChange={(val) => {
                            setFilterObj((filter) => {
                                return {
                                    ...filter,
                                    sortBy: {
                                        sortAscending: val === "sortByAsc",
                                        field: MachineSearchSortField.SaleModifiedDate,
                                    },
                                };
                            });
                        }}
                    >
                        <Option value="sortByAsc">{t("common.sortByAsc")}</Option>
                        <Option value="sortByDesc">{t("common.sortByDesc")}</Option>
                    </Select>
                </Col>

                <br />
                {
                    <DataTable
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    navigate("/sale/removed/view?macid=" + record.id);
                                },
                            };
                        }}
                        isSecondary={true}
                        onChange={handleChange}
                        columns={columns}
                        dataSource={machines}
                        loading={loading}
                        pagination={{ ...pagination, total: itemCount, showSizeChanger: false, position: ["bottomCenter"] }}
                    />
                }
            </BoxContainer>
        </ListContainer>
    );
};

export default RemovedFromSaleMachines;
