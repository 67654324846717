/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminLoginWithOtpModel
 */
export interface AdminLoginWithOtpModel {
    /**
     * 
     * @type {number}
     * @memberof AdminLoginWithOtpModel
     */
    adminId: number;
    /**
     * 
     * @type {string}
     * @memberof AdminLoginWithOtpModel
     */
    otpCode: string;
}

export function AdminLoginWithOtpModelFromJSON(json: any): AdminLoginWithOtpModel {
    return AdminLoginWithOtpModelFromJSONTyped(json, false);
}

export function AdminLoginWithOtpModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminLoginWithOtpModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminId': json['adminId'],
        'otpCode': json['otpCode'],
    };
}

export function AdminLoginWithOtpModelToJSON(value?: AdminLoginWithOtpModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminId': value.adminId,
        'otpCode': value.otpCode,
    };
}

