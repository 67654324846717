import { Form, Input, InputProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { t } from "../../../translate";

export type EmailInputProps = {} & InputProps;

const EmailInput = (props: EmailInputProps) => {
    return (
        <Form.Item name="username" rules={[{ required: true, type: "email", message: t("login.email-error") }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("email")} />
        </Form.Item>
    );
};

export default EmailInput;
