import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../components/assets/left-arrow.svg";
import { ReactComponent as YellowCircle } from "./../../../components/assets/yellowCircle.svg";
import ViewSaleMachineInfoPart from "../../../page-parts/view-sale-machine-info-part";
import BoxContainer from "../../../components/containers/box-container";
import MachineSaleAdditionalInfoPart from "../../../page-parts/view-sale-additional-info-part";
import MachineSalePricePart from "../../../page-parts/view-sale-price-part";
import { ReactComponent as Delete } from "./../../../components/assets/delete.svg";
import MachineSaleStatisticPart from "../../../page-parts/view-sale-statistic-part";

const OnSaleApprovalDetail = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const [dataFetching, setDataFetching] = useState(true);
    const navigate = useNavigate();
    const { doGetMachine, doApproveSaleMachine, doDeleteMachineSale } = useSecureService();
    useEffect(() => {
        editMachine(undefined); //global state'te kalan eski makine datasını uçurmak için.
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                }).finally(() => {setDataFetching(false)});
        } else {
            setDataFetching(false);
        }
        //eslint-disable-next-line
    }, []);

    const deleteMachine = async () => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN)) {
                await doDeleteMachineSale(machineIdN);
                navigate("/sale/pending-approval");
            }
        }
    };

    return (
        <ListContainer loading={dataFetching}>
            <Row justify="space-between" align="middle">
                <Col>
                    <div
                        style={{ marginBottom: "1rem", cursor: "pointer" }}
                        onClick={() => {
                            navigate("/sale/pending-approval");
                        }}
                    >
                        <ArrowLeft style={{ marginBottom: "0.15rem" }}></ArrowLeft>
                        <span style={{ marginLeft: "0.75rem", fontWeight: "700", fontSize: "1.5rem" }}>
                            {t("onSalePendingApproval.title")}
                        </span>
                    </div>
                </Col>
                <Col md={12} lg={16}>
                    <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
                        <div
                            style={{
                                height: "3rem",
                                alignItems: "center",
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                width: "18rem",
                                marginBottom: "1rem",
                                border: "1px solid rgba(148, 147, 148, 0.2)",
                                borderRadius: "12px",
                            }}
                        >
                            <div style={{ display: "flex", alignContent: "center" }}>
                                <YellowCircle style={{ transform: "scale(0.7)" }}></YellowCircle>
                            </div>
                            <span style={{ marginLeft: "0.5rem", color: "#FEB111", fontWeight: "800", fontSize: "1rem" }}>
                                {t("machine.status.waiting")}
                            </span>
                        </div>
                        {editedMachine?.lessorCompanyId !== undefined && editedMachine?.lessorCompanyId !== null && (
                            <div style={{ width: "20rem" }}>
                                <SecondaryButton
                                    onClick={() => {
                                        navigate("/lessor-company/view?compid=" + editedMachine.lessorCompanyId, { replace: true });
                                    }}
                                    style={{ marginRight: "0.5rem", width: "100%" }}
                                >
                                    {t("companies.detail.view")}
                                </SecondaryButton>
                            </div>
                        )}
                        {!editedMachine?.isMachineRemovedFromSale && (
                            <div style={{ width: "10rem" }}>
                                <PrimaryButton
                                    onClick={() => {
                                        navigate("/sale/pending-approval/edit?macid=" + machineId, { replace: true });
                                    }}
                                    style={{ marginRight: "0.5rem", width: "100%" }}
                                >
                                    {t("common.edit")}
                                </PrimaryButton>
                            </div>
                        )}
                        <div style={{ width: "12rem" }}>
                            <PrimaryButton
                                onClick={async () => {
                                    if (machineId) await doApproveSaleMachine({ machineIds: [Number.parseInt(machineId)] });
                                    navigate("/sale/pending-approval");
                                }}
                                style={{ marginRight: "0.5rem", width: "100%" }}
                            >
                                {t("pendingApproval.primaryButtonText.SingleSelection")}
                            </PrimaryButton>
                        </div>
                    </div>
                </Col>
            </Row>
            <BoxContainer shadowless marginless>
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <ViewSaleMachineInfoPart viewType="machine" machine={editedMachine} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <MachineSaleAdditionalInfoPart viewType="machine" machine={editedMachine} />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSalePricePart viewType="machine" machine={editedMachine} />
                            </Col>
                            <Col md={24} xs={24}>
                                <MachineSaleStatisticPart key={'statistic-' + machineId} macId={parseInt(machineId ?? '0')} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BoxContainer>
            <Col md={24} xs={24} onClick={deleteMachine}>
                <Row justify="center" align="middle">
                    <Col md={8} xs={24}>
                        <SecondaryButton style={{ width: "100%" }}>
                            <Row gutter={[8, 8]} align="middle" justify="center">
                                <Col>
                                    <Delete />
                                </Col>
                                <Col>{t("common.delete")}</Col>
                            </Row>
                        </SecondaryButton>
                    </Col>
                </Row>
            </Col>
        </ListContainer>
    );
};

export default OnSaleApprovalDetail;
