import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { t } from "../translate";

const ApproveSaleMachinesModal = (props: ModalLayoutProps) => {
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={t("approve.sale.machines.modal.title")}>{props.children}</ModalCardContainer>
        </ModalLayout>
    );
};

export default ApproveSaleMachinesModal;