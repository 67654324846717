import { Col, Input } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

type OfferModalProps = {
    offerType: "approve" | "reject" | undefined;
    offerId: number;
    commissionsSuccess: boolean;
} & ModalLayoutProps;
const OfferModal = (props: OfferModalProps) => {
    const navigate = useNavigate();
    const { doRejectOffer, doApproveOffer } = useSecureService();
    const [comment, setComment] = useState("");
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [loading] = useAtom(loadingAtom);
    const onContinueClick = async () => {
        let result = false;
        if (props.offerType === "reject") {
            result = await doRejectOffer(props.offerId, comment);
            if (result) {
                setMessage({
                    message: t("offers.view.rejectSuccess"),
                    type: "success"
                });
                navigate(-1);
            }
        }
        else if (props.offerType === "approve" && props.commissionsSuccess) {
            result = await doApproveOffer(props.offerId, comment);
            if (result) {
                setMessage({
                    message: t("offers.view.approveSuccess"),
                    type: "success",
                });
                navigate(-1);
            }
        } else {
            setMessage({
                message: t("common.genericError"),
                type: "error",
            });
        }
        if (result) navigate("/rent/offers/");
    };

    const { TextArea } = Input;
    const onTextAreaChanged = (e: any) => {
        setComment(e.currentTarget.value);
    };
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={props.offerType === "reject" ? t("common.rejectionreason") : t("common.approvereason")}>
                <Col>
                    <TextArea
                        onChange={onTextAreaChanged}
                        rows={4}
                        placeholder={props.offerType === "reject" ? t("common.rejectionreason") : t("common.approvereason")}
                    />
                </Col>
                <Col span={18} offset={3}>
                    <PrimaryButton loading={loading} onClick={onContinueClick}>{t("common.continue")}</PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};
export default OfferModal;
