import { Col, DatePicker, Input, InputNumber, Row } from "antd";
import { useEffect, useState } from "react";
import { RenterCompanySearchFilterModel, RenterCompanySearchSortField, RenterStatus } from "../../service/models";
import { t } from "../../translate";
import BoxContainer from "./box-container";
import moment from "moment";
import { restrictPositiveNumbersInRange } from "../../utils/input-rules";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";

//import styles from '../styles/filter-components.module.scss';
type RenterListFilterProps = {
    onChange?: (params: RenterListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
};

type RenterListFilterViewModel = {
    searchFilterModel?: RenterCompanySearchFilterModel;
    companyName?: string | null;
    sortBy: {
        field: RenterCompanySearchSortField;
        sortAscending: boolean;
    };
};

const RenterListFilter = (props: RenterListFilterProps) => {
    const [filterModel, setFilterModel] = useState<RenterListFilterViewModel>({
        sortBy: {
            field: RenterCompanySearchSortField.DealCount,
            sortAscending: true,
        },
    });

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const companyIdChange = (val: string | number) => {
        let compId: number | undefined = undefined;
        if (typeof val === "number") compId = val;
        setFilterModel((filt) => {
            return {
                ...filt,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    id: compId,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };
    const NameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    nameLastname: val,
                },
            };
        });
    };
    const PhoneChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    phone: val,
                },
            };
        });
    };

    const DateChange = (val: Date | null) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    creationDate: val,
                },
            };
        });
    };

    const onRegistrationStatusChange = async (val?: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    status: val === t("companies.table.status.option-deleted") ? RenterStatus.Deleted : 
                        val === t("requests.table.isFullRegister") ? RenterStatus.Active : 
                        val === t("request-status.PreRegistration") ? RenterStatus.Inactive : undefined,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={3}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("renter.filter.id")}
                                value={filterModel.searchFilterModel?.id || undefined}
                                onKeyDown={(event) => {
                                    const re = /[+-]/;
                                    if (re.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                onChange={(val) => {
                                    var num = restrictPositiveNumbersInRange(val ? val.toString() : undefined, 0, Infinity);
                                    companyIdChange(num ?? "");
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <>
                                <Col xs={12} md={3}>
                                    <Input
                                        allowClear
                                        style={{ width: "100%" }}
                                        placeholder={t("renter.filter.companyName")}
                                        value={filterModel.searchFilterModel?.companyName || undefined}
                                        onChange={(val) => {
                                            companyNameChange(val.currentTarget.value);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <Input
                                        allowClear
                                        style={{ width: "100%" }}
                                        placeholder={t("renter.filter.nameLastName")}
                                        value={filterModel.searchFilterModel?.nameLastname || undefined}
                                        onChange={(val) => {
                                            NameChange(val.currentTarget.value);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <Input
                                        allowClear
                                        style={{ width: "100%" }}
                                        placeholder={t("renter.filter.phone")}
                                        value={filterModel.searchFilterModel?.phone || undefined}
                                        onChange={(val) => {
                                            PhoneChange(val.currentTarget.value);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <DatePicker
                                        allowClear
                                        style={{ width: "100%" }}
                                        placeholder={t("renter.filter.creationDate")}
                                        value={
                                            filterModel.searchFilterModel?.creationDate
                                                ? moment(filterModel.searchFilterModel.creationDate)
                                                : undefined
                                        }
                                        onChange={(val) => {
                                            if (val) {
                                                DateChange(val.toDate());
                                            } else {
                                                // Eğer val null ise, DateChange fonksiyonuna null geçme
                                                DateChange(null);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <MgCharacterSelect
                                        allowClear
                                        placeholder={t("renter.filter.isActive")}
                                        style={{ width: "100%" }}
                                        showSearch
                                        onSelect={(value: any) => onRegistrationStatusChange(value)}
                                        onClear={() => onRegistrationStatusChange(undefined)}
                                        options={
                                            [
                                                {label: t("request-status.PreRegistration"), value: t("request-status.PreRegistration")},
                                                {label: t("requests.table.isFullRegister"), value: t("requests.table.isFullRegister")},
                                                {label: t("companies.table.status.option-deleted"), value: t("companies.table.status.option-deleted")},
                                            ]
                                        }
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RenterListFilter;
