import { Row, Col } from "antd";
import { EventHandler } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";
import styles from "./styles/view-renter-company-info-part.module.scss"

type RenterCompanyInfoPartProps = {
    compId: number;
    userId: number;
    isBlocked: boolean | undefined;
    isDeleted: boolean;
    onBlockClick: EventHandler<any>;
    onEditClick?: EventHandler<any>;
    onClickRequestFormList?: EventHandler<any>;
    onClickRenterDelete?: () => void;
} & ViewPartProps;

const RenterCompanyInfoPart = (props: RenterCompanyInfoPartProps) => {
    return (
        <Row justify={'center'} gutter={[8, 8]}>
            <Col xs={24} md={24}>
                <BigboxContainer title={t("companyDetail.view.companyInfoDetail")}>
                    <Row>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer md={24} objKey={t("renterCompany.view.companyID")} value={props.renterCompany?.id || "-"} />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer
                                md={24}
                                objKey={t("renterCompany.view.companyName")}
                                value={props.renterCompany?.companyName || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <BasicModelViewer
                                md={12}
                                objKey={t("renterCompany.view.fullName")}
                                value={props.renterCompany?.name + " " + props.renterCompany?.lastname || "-"}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer md={24} objKey={t("renterCompany.view.phone")} value={props.renterCompany?.phone || "-"} />
                        </Col>
                        <Col xs={12} md={6}>
                            {" "}
                            <BasicModelViewer md={24} objKey={t("renterCompany.view.email")} value={props.renterCompany?.email || "-"} />
                        </Col>
                    </Row>
                </BigboxContainer>
            </Col>
            <Col xs={24}>
                <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
                    {props.isBlocked === false ? (
                        <SecondaryButton onClick={props.onBlockClick} negative>
                            {t("common.block")}
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton onClick={props.onBlockClick}>{t("common.active")}</SecondaryButton>
                    )}
                    <PrimaryButton onClick={props?.onClickRequestFormList}>{t("renterCompany.view.listRequests")}</PrimaryButton>
                </div>
            </Col>
            <Col hidden={props.isDeleted} className={styles['user-delete-container']} xs={24} md={12}>
                <PrimaryButton style={{backgroundColor: 'red', borderColor: 'transparent'}} onClick={props?.onClickRenterDelete}>{t("renterCompany.view.renterDelete")}</PrimaryButton>
            </Col>
        </Row>
    );
};
export default RenterCompanyInfoPart;
