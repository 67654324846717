/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePasswordViewModel
 */
export interface ChangePasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordViewModel
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordViewModel
     */
    newPassword: string;
}

export function ChangePasswordViewModelFromJSON(json: any): ChangePasswordViewModel {
    return ChangePasswordViewModelFromJSONTyped(json, false);
}

export function ChangePasswordViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPassword': json['currentPassword'],
        'newPassword': json['newPassword'],
    };
}

export function ChangePasswordViewModelToJSON(value?: ChangePasswordViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
    };
}

