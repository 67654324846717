import { Checkbox, Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, OfferFormBidViewModel, RequestFormAdditionalServices } from "../service";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";
import {activeApiBaseUrl} from "../store/app-constants";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import BorderedBoxContainer from "../components/containers/bordered-box-container";
import LightTitle from "../components/atomics/light-title";

export type OfferViewMachineInfoPartProps = {
    additionalServices?: RequestFormAdditionalServices; 
    availableAttachments: BasicModel[];
    requestedMachineId?: number;
    bid?: OfferFormBidViewModel;
}

const OfferViewMachineInfoPart = (props: { machines: BasicModel[] | null | undefined } & OfferViewMachineInfoPartProps) => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [mainImageUrl, setMainImageUrl] = useState<string>("");
    const [allMachines, setAllMachines] = useState<ExtendedMachineDetailModel[]>([]);
    const { doGetMachine, doGetLessorCompanyDetail } = useSecureService();
    const [serviceActivityFields, setServiceActivityFields] = useState<BasicModel[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        props.machines?.forEach((bMach) => {
            const available = allMachines?.find((mach) => mach.id === bMach.id);
            if (!available && bMach.id) {
                doGetMachine(bMach.id).then((data) => {
                    setAllMachines([data as ExtendedMachineDetailModel, ...allMachines]);
                    if(data?.lessorCompanyId){
                        doGetLessorCompanyDetail(data?.lessorCompanyId).then((lessor) => {
                            setServiceActivityFields(lessor?.serviceRegions ?? []);
                        });
                    }
                   
                });
            }
        });
        //eslint-disable-next-line
    }, [props.machines]);

    //for profile images
    useEffect(() => {
        if (imageUrls.length > 0 || !allMachines) return;
        let imageIds: string[] = [];
        allMachines.forEach((mach) => {
            const currentImagesIds = mach?.documents
                ?.filter((doc) => doc.documentCategory === "Photo")
                .map((doc) => activeApiBaseUrl + "/document/" + (doc.document?.id || 0));
            if (currentImagesIds) imageIds.push(...currentImagesIds);
        });
        setImageUrls(imageIds);
        setMainImageUrl(imageIds[0]);
        //eslint-disable-next-line
    }, [allMachines]);

    return (
        <BigboxContainer title={t("machines.add.machineInfoTitle")}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        {imageUrls?.length > 0 && (
                            <>
                                <Col xs={24} style={{ height: "12rem", background: "#222222", display: "flex", justifyContent: "center" }}>
                                    <img
                                        style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                        src={mainImageUrl}
                                        alt="galleryImages"
                                    />
                                </Col>
                                {imageUrls.map((url) => {
                                    return (
                                        <Col
                                            xs={4}
                                            style={{
                                                height: "5rem",
                                                background: "#222222",
                                                margin: ".25rem",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                                src={url}
                                                alt="galleryImages"
                                                onClick={() => {
                                                    setMainImageUrl(url);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </>
                        )}
                    </Row>
                </Col>
                {props.machines?.map((bMach) => {
                    const names = bMach?.name?.split(" / ");

                    return (
                        <>
                            <BasicModelViewer objKey={t("machine.review.machineId")} value={(props.requestedMachineId && bMach?.id === props.requestedMachineId) ? (bMach?.id + " - " + t("offers.view.requestMachine.affix")) : bMach?.id} />
                            <BasicModelViewer objKey={t("machine.review.machineCategory")} value={names?.[0]} />
                            <BasicModelViewer objKey={t("machine.review.machineSubcategory")} value={names?.[1]} />
                            <Col xs={24}>
                                <SecondaryButton onClick={() => navigate("/rent/machines/view?macid=" + bMach?.id)}>
                                    {t("offer.view.machineDetailsButtonLabel")}
                                </SecondaryButton>
                            </Col>

                            <Col xs={24}>

                                <LightTitle>{t("common.attachments")}</LightTitle>

                            </Col>

                            <Row style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <Row style={{width: '95%', display: 'flex', justifyContent: 'flex-start'}} gutter={[8,12]}>
                                    {props.bid?.attachments && props.bid?.attachments.map(e => {
                                        return(
                                            <Col xs={24} md={8} >
                                                <div style={{background: '#EEF0F2', borderRadius: '10px', display: 'flex', alignItems: 'center', height: '50px'}}>
                                                    <span style={{marginLeft: '10px', fontWeight: '700', width: '100%'}}>{props.availableAttachments.find(attc => attc.id === e.attachmentId)?.name ?? ""}</span>
                                                </div> 
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Row>
                          
                            <Col xs={24} style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                <Row style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem'}}>
                                    <div style={{background: '#FFF4E1', borderRadius: '10px' , width: '100%', height: '3.5rem', display: 'flex', alignItems: 'center'}}>
                                        <span style={{marginLeft: '1.25rem', fontWeight: '800', fontSize: '1.25rem'}}>{t("machines.add.additionalServicesTitle")}</span>
                                    </div>
                                </Row>

                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <BorderedBoxContainer style={{width: '100%'}}>
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Col xs={16}>
                                                <span style={{fontWeight: '700'}}>{t("offers.view.operator")}</span>
                                            </Col>
                                            <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Checkbox checked={props.additionalServices?.hasOperator}></Checkbox>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '1rem'}} gutter={[16,16]}>
                                            <Col xs={24} style={{display: 'flex', flexDirection: 'column'}}>
                                                <span style={{fontWeight: '600'}}>{t("offers.view.operatorExperience")}</span>
                                                <div style={{position: 'relative'}}>
                                                    <Input disabled style={{background: 'white', color: 'black', fontWeight: '800'}} value={props.additionalServices?.operatorExperienceYear ?? "-"}></Input>
                                                    <div style={{position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '5rem', height: '2.5rem', background: '#FFF6E8', fontWeight: '700', borderRadius: '8px', right: '5px', top: '4px',  zIndex: '1'}}>
                                                        <span>{t("offers.view.operatorExperience.year")}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </BorderedBoxContainer>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <BorderedBoxContainer style={{width: '100%'}}>
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Col xs={18}>
                                                <span style={{fontWeight: '700'}}>{t("offers.view.operatorCost")}</span>
                                            </Col>
                                            <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Checkbox checked={props.additionalServices?.operatorCostIncluded}></Checkbox>
                                            </Col>
                                        </Row>
                                    </BorderedBoxContainer>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <BorderedBoxContainer style={{width: '100%'}}>
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Col xs={18}>
                                                <span style={{fontWeight: '700'}}>{t("offers.view.operatorInsurance")}</span>
                                            </Col>
                                            <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Checkbox checked={props.additionalServices?.operatorInsuranceIncluded}></Checkbox>
                                            </Col>
                                        </Row>
                                    </BorderedBoxContainer>
                                </div>

{/*                                 <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <BorderedBoxContainer style={{width: '100%'}}>
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Col xs={18}>
                                                <span style={{fontWeight: '700'}}>{t("offers.view.shipping")}</span>
                                            </Col>
                                            <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Checkbox checked={props.additionalServices?.shipingIncluded}></Checkbox>
                                            </Col>
                                        </Row>
                                    </BorderedBoxContainer>
                                </div>
 */}
                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <BorderedBoxContainer style={{width: '100%'}}>
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Col xs={18}>
                                                <span style={{fontWeight: '700'}}>{t("offers.view.fuel")}</span>
                                            </Col>
                                            <Col xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <Checkbox checked={props.additionalServices?.fuelIncluded}></Checkbox>
                                            </Col>
                                        </Row>
                                    </BorderedBoxContainer>
                                </div>

                            </Col>
                        
                            <Col xs={24}>
                                <Row style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem'}}>
                                    <div style={{background: '#FFF4E1', borderRadius: '10px' , width: '100%', height: '3.5rem', display: 'flex', alignItems: 'center'}}>
                                        <span style={{marginLeft: '1.25rem', fontWeight: '800', fontSize: '1.25rem'}}>{t("offers.view.districts.title")}</span>
                                    </div>
                                </Row>     
                            </Col>
                            <Col xs={24} style={{display: 'flex', justifyContent: 'center'}}>
                                <Row style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: '1rem', width: '100%'}} gutter={[12,12]}>                 
                                            {serviceActivityFields.map((fields) => {
                                                return (
                                                    <Col xs={24} sm={7} md={8} lg={9} xl={9} xxl={5}>
                                                        <div style={{background: '#EEF0F2', borderRadius: '10px', fontWeight: '700', display: 'flex', alignItems: 'center', height: '2rem'}}>
                                                            <span style={{marginLeft: '1.25rem'}}> {(fields.name?.at(0)?.toUpperCase() ?? "") + (fields.name?.slice(1).toLowerCase() ?? "")} </span>
                                                        </div>
                                                       
                                                    </Col>
                                                );
                                            })}
                                </Row>
                            </Col>
                            
                        </>
                    );
                })}
            </Row>
        </BigboxContainer>
    );
};

export default OfferViewMachineInfoPart;
