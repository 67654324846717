/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SaleMachineStatisticViewModel,
    SaleMachineStatisticViewModelFromJSON,
    SaleMachineStatisticViewModelToJSON,
} from '../models';

export interface ViewGetSaleMachineStatisticIdGetRequest {
    id: number;
}

export interface ViewSaleMachineDetailIdGetRequest {
    id: number;
}

export interface ViewSaleMachineLessorIdGetRequest {
    id: number;
}

/**
 * 
 */
export class ViewApi extends runtime.BaseAPI {

    /**
     * Lessor, Admin
     */
    async viewGetSaleMachineStatisticIdGetRaw(requestParameters: ViewGetSaleMachineStatisticIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaleMachineStatisticViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling viewGetSaleMachineStatisticIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/view/get-sale-machine-statistic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaleMachineStatisticViewModelFromJSON(jsonValue));
    }

    /**
     * Lessor, Admin
     */
    async viewGetSaleMachineStatisticIdGet(requestParameters: ViewGetSaleMachineStatisticIdGetRequest, initOverrides?: RequestInit): Promise<SaleMachineStatisticViewModel> {
        const response = await this.viewGetSaleMachineStatisticIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async viewSaleMachineDetailIdGetRaw(requestParameters: ViewSaleMachineDetailIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling viewSaleMachineDetailIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/view/sale-machine-detail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Admin
     */
    async viewSaleMachineDetailIdGet(requestParameters: ViewSaleMachineDetailIdGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.viewSaleMachineDetailIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async viewSaleMachineLessorIdGetRaw(requestParameters: ViewSaleMachineLessorIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling viewSaleMachineLessorIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/view/sale-machine-lessor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Admin
     */
    async viewSaleMachineLessorIdGet(requestParameters: ViewSaleMachineLessorIdGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.viewSaleMachineLessorIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async viewTestIpGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/view/test-ip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async viewTestIpGet(initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.viewTestIpGetRaw(initOverrides);
        return await response.value();
    }

}
