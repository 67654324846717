import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";

const SaleMachineAdditionalInfoPart = () => {
    return (
        <BigboxContainer title={t("machines.add.additionalInfoTitle")}>
            <Form.Item required label={t("machines.add.AdLabel")} name={"saleAdTitle"}>
                <TextArea showCount maxLength={250} autoSize={{ minRows: 5, maxRows: 10 }} />
            </Form.Item>
            <Form.Item label={t("machines.add.notesLabel")} name="saleNotes">
                <TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
            </Form.Item>
        </BigboxContainer>
    );
};

export default SaleMachineAdditionalInfoPart;
