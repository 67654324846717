import { Col, Space, TablePaginationConfig, Tooltip, message } from "antd";
import { useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableModel } from "..";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import DataTable from "../../../components/atomics/data-table";
import MachineListFilter from "../../../components/containers/machine-list-filter";
import ModalLayout, { ModalLayoutProps } from "../../../components/containers/modal-layout";
import ModalCardContainer from "../../../components/containers/modalcard-container";
import { BasicModel, MachineSearchFilterModel, MachineSearchSortField } from "../../../service";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import { loadingAtom, machineEditAtom } from "../../../store/global-atoms";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import styles from "../style/machine.module.css";
import { useCsvExport } from "../../../helpers/useTableData";

export interface SelectMachineForShowCase extends ModalLayoutProps {
    selectMachine: (id: number) => void;
    showCaseNumber: number;
}
export type FilterTableModel = {
    filterModel: MachineSearchFilterModel;
    sortBy: {
        field: MachineSearchSortField;
        sortAscending: boolean;
    };
};

const SelectMachineForShowCaseModal = (props: SelectMachineForShowCase) => {
    const { doSearchMachineByApprovalStatus } = useSecureService();
    const navigate = useNavigate();
    const [doGetMachineTableData] = useCsvExport();
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);

    const [loading] = useAtom(loadingAtom);
    const [, ViewMachine] = useAtom(machineEditAtom);

    const [filterObj, setFilterObj] = useState<FilterTableModel>({
        filterModel: {},
        sortBy: {
            field: MachineSearchSortField.ApprovalDate,
            sortAscending: false,
        },
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });

    const rowClickHandler = async (record: ExtendedMachineDetailModel) => {
        ViewMachine(record);
        navigate("/rent/machines/view?macid=" + record.id);
    };

    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const columns = [
        {
            title: t("machines.table.approvedMachineId"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.review")}>
                        <div onClick={() => rowClickHandler(detail)} className={styles.tooltipText}>
                            {text}
                        </div>{" "}
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.CreationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.action"),
            key: "action",
            render: (text: string, record: any) => (
                <PrimaryButton
                    loading={loading}
                    onClick={(e) => {
                        if (record) props.selectMachine(record?.id || -1);
                        props.onCloseClick(e);
                    }}
                    style={{ margin: 0 }}
                >
                    {t("common.choice")}
                </PrimaryButton>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        if (props.show) {
            setFilterObj({
                filterModel: {},
                sortBy: {
                    field: MachineSearchSortField.ApprovalDate,
                    sortAscending: false,
                },
            });

            setPagination({
                current: 1,
                pageSize: 10,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        const fillMachineList = async () => {
            try {
                const result = await doSearchMachineByApprovalStatus(
                    {
                        pageNo: pagination.current,
                        pageSize: pagination.pageSize,
                        filter: filterObj.filterModel,
                        sortAscending: filterObj.sortBy.sortAscending,
                        sortBy: filterObj.sortBy.field,
                    },
                    true
                );
                if (result?.machines !== null) {
                    setItemCount(result?.itemCount);
                    setMachines(await doGetMachineTableData(result?.machines, false));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    return (
        <ModalLayout {...props}>
            <ModalCardContainer
                marginLessButton
                title={props.showCaseNumber + 1 + t("showcases.choice.index-number-text")}
                style={{ padding: 0 }}
            >
                <Col md={24}>
                    <MachineListFilter
                        isAdmin={true}
                        resetItems={!props.show}
                        onChange={(params) => {
                            setFilterObj({
                                filterModel: params.searchFilterModel || {},
                                sortBy: params.sortBy,
                            });
                        }}
                    ></MachineListFilter>
                    <DataTable
                        onChange={handleChange}
                        columns={columns}
                        dataSource={machines}
                        loading={loading}
                        pagination={{ ...pagination, total: itemCount, showSizeChanger: false }}
                    />
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};

export default SelectMachineForShowCaseModal;
