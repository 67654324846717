import { Col } from "antd";
import LightTitle from "./light-title";

export type BasicModelViewerProps = {
    objKey?: string | null | undefined;
    value?: string | number | boolean | null | undefined;
    xs?: number;
    md?: number;
    children?: any;
    showEvenValueNull?: boolean;
    horizontal?: boolean;
    suffix?: string;
};

const BasicModelViewer = (props: BasicModelViewerProps) => {
    if (!props.value && !props.showEvenValueNull) {
        return <></>;
    }
    let xs = props.xs;
    let md = props.md;
    if (!xs) xs = 12;
    if (!md) md = 8;
    let values = typeof props.value === "string" ? props.value.split("\n") : undefined;
    return (
        <Col
            xs={xs}
            md={md}
            style={{
                display: "flex",
                flexDirection: props.horizontal ? "row" : "column",
                justifyContent: props.horizontal ? "space-between" : "flex-start",
                background: props.horizontal ? "#EEF0F2" : undefined,
                borderRadius: ".5rem",
                paddingLeft: props.horizontal ? "1rem" : "0",
                paddingRight: props.horizontal ? "1rem" : "0",
                padding: "0.5rem",
            }}
        >
            <LightTitle darkTitle={props.horizontal}>{props.objKey}</LightTitle>
            {values && values.length > 1 ? (
                values.map((val) => {
                    return (
                        <>
                            <span style={{ fontSize: "1rem", fontWeight: "700", color: "#282828" }}>{val}</span>
                        </>
                    );
                })
            ) : (
                <span style={{ fontSize: "1rem", fontWeight: "700", color: "#282828" }}>
                    {props.value} {props.suffix}
                </span>
            )}
            {props.children}
        </Col>
    );
};

export default BasicModelViewer;
