import { isNumber } from "lodash";

export const restrictSpecialCharacters = (value: string | undefined) => {
  if (value === undefined) return '';
  const specialCharacters = new RegExp(/[^A-Za-z0-9ıİğĞüÜşŞöÖçÇ]+/gm);
  return value.replace(specialCharacters, '');
};

export const restrictNumbers = (value: string | undefined) => {
  if (value === undefined) return '';
  const specialCharacters = new RegExp(/[0-9]+/gm);
  return value.replace(specialCharacters, '');
};

export const restrictPositiveNumbers = (value: string | undefined)=>{
  if(value === undefined) return '';
  const regex = /^[0-9\b]+$/;
  if (value === '' || regex.test(value)) {
     return value
  }
  return '';
}

export const restrictPositiveNumbersInRange = (value: string | undefined, min: number, max: number) => {
  if (value === '' || value === undefined || !/^[0-9]+$/.test(value)) {
    return undefined;
  }
  var num = Number.parseInt(value);
  if (isNumber(num) && num >= min && num <= max)
      return num;
  else if (num > max)
      return max;
  else
      return min;
    
}
