import { Button, Form, Row } from "antd";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import CardContainer from "../components/containers/card-container";
import PreloginLayout from "../components/containers/prelogin-layout";
import EmailInput from "../components/atomics/inputs/email-input";
import { t } from "../translate";
import { useSecureService } from "../store/service-atoms";
import { useAtom } from "jotai";
import { loadingAtom } from "../store/global-atoms";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const { doForgotPassword } = useSecureService();
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);

    const onRemindSubmit = async (parameters: { username: string }) => {
        await doForgotPassword({ email: parameters.username });
    };

    return (
        <PreloginLayout footer={t("copyright")}>
            <CardContainer title={t("forgot-password.cardTitle")}>
                <Form name="password_login" className="login-form" onFinish={onRemindSubmit}>
                    <EmailInput />
                    <Row justify="space-between">
                        <Form.Item>
                            <PrimaryButton type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                                {t("forgot-password.primary")}
                            </PrimaryButton>
                        </Form.Item>
                        <Button type="link" onClick={(evt) => navigate(-1)}>
                            {t("common.back")}
                        </Button>
                    </Row>
                </Form>
            </CardContainer>
        </PreloginLayout>
    );
};

export default ForgotPassword;
