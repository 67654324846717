import { Col, DatePicker, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, OfferFormSearchModel, OfferStatus } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
import moment from "moment";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";
type OffersFilterProps = {
    onChange?: (params: OffersFilterViewModel | undefined) => void;
    children?: any;
    isAdmin?: boolean;
};

type OffersFilterViewModel = {
    searchFilterModel?: OfferFormSearchModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    lessorCompanyName?: string | null;
    cityName?: string | null;
    reqDate?: moment.Moment;
    offerStatus?: OfferStatus[] | null | undefined;
} & OfferFormSearchModel;

const requestStatusOptions = {
    InReview: { id: 0, text: t("request-status.InReview") },
    Rejected: { id: 1, text: t("request-status.Rejected") },
    Approved: { id: 2, text: t("request-status.Approved") },
    Suggested: { id: 3, text: t("request-status.Suggested") },
    Offered: { id: 4, text: t("request-status.Offered") },
    Deal: { id: 5, text: t("request-status.Deal") },
    InProgress: { id: 6, text: t("request-status.InProgress") },
    Completed: { id: 7, text: t("request-status.Completed") },
    Expired: { id: 8, text: t("request-status.Expired") },
    PreRegistration: { id: 9, text: t("request-status.PreRegistration") },
};

const offerStatusOptions = {
    Suggested: { id: 0, text: t("offer-status.Suggested") },
    Offered: { id: 1, text: t("offer-status.Offered") },
    Rejected: { id: 2, text: t("offer-status.Rejected") },
    Approved: { id: 3, text: t("offer-status.Approved") },
    Accepted: { id: 4, text: t("offer-status.Accepted") },
    Expired: { id: 5, text: t("offer-status.Expired") },
    Declined: { id: 6, text: t("offer-status.Declined") },
    Deal: { id: 7, text: t("offer-status.Deal") },
    PaymentDeclined: { id: 8, text: t("offer-status.PaymentDeclined") }
};


const OfferListFilter = (props: OffersFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [city, setCity] = useState<BasicModel[] | undefined>(undefined);
    const [filterModel, setFilterModel] = useState<OffersFilterViewModel>();
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetCities } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const getCityNames = async () => {
            const result = await doGetCities();
            setCity(result);
        };
        getCityNames();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: undefined,
                    categoryId: selectedCategory?.id,
                    requestDateFrom: undefined,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onBrandSelected = async (selectedBrandName?: string) => {
        let SelectedCity: BasicModel | undefined;
        city?.find((ct) => {
            SelectedCity = city?.find((br) => {
                return br.name === selectedBrandName;
            });
            return SelectedCity !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                cityName: SelectedCity?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    cityId: SelectedCity?.id,
                },
            };
        });
    };

    const requestCreationDateChange = (val: moment.Moment) => {
        let prodYear = moment().toDate();
        if (val) prodYear = moment(val).toDate();
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    creationDateFrom: prodYear,
                },
            };
        });
    };
    const requestDateChange = (val: moment.Moment) => {
        let prodYear = moment().toDate();
        if (val) prodYear = moment(val).toDate();
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestDateFrom: prodYear,
                },
            };
        });
    };
    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    lessorCompanyName: val,
                },
            };
        });
    };


    const offerIdChange = (val: number) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    offerId: val,
                },
            };
        });
    };

    const requestIdChange = (val: number) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestId: val,
                },
            };
        });
        console.log(filterModel);
    };

    const onOfferStatusChange = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    status: [val],
                },
            };
        });
    };


    const onOfferStatusClear = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    status: null,
                },
            };
        });
    };

    const onRequestStatusChange = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestStatus: [val],
                },
            };
        });
    };


    const onRequestStatusClear = async (val?: any) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestStatus: null,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                placeholder={t("machines.add.category")}
                                value={filterModel?.categoryName}
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                                options={allCategories?.map((mc) => ({ label: mc.name, value: mc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                placeholder={t("machines.add.subcategory")}
                                value={filterModel?.subCategoryName}
                                loading={loading}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                                options={availableSubcategories?.map((msc) => ({ label: msc.name, value: msc.name }))}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect 
                                 onClear={onBrandSelected}
                                 placeholder={t("offers.filter.city")}
                                 value={filterModel?.cityName}
                                 loading={loading}
                                 onSelect={onBrandSelected}
                                 options={
                                    city?.map((msc) => (
                                        {label:msc.name, value:msc.name}
                                    ))
                                 }
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("offers.filter.companyName")}
                                value={filterModel?.lessorCompanyName || undefined}
                                onChange={(val) => {
                                    companyNameChange(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("offers.filter.offerCreationDate")}
                                format="DD-MM-YYYY"
                                onChange={(val) => {
                                    requestCreationDateChange(moment(val));
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("offers.filter.requestDateFrom")}
                                onChange={(val) => {
                                    requestDateChange(moment(val));
                                }}
                            />
                        </Col>

                        <Col xs={12} md={2}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("offers.filter.offerId")}
                                value={filterModel?.offerId || undefined}
                                onChange={(val) => {
                                    offerIdChange(val ?? null);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={2}>
                            <InputNumber
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("offers.filter.requestId")}
                                value={filterModel?.requestId || undefined}
                                onChange={(val) => {
                                    requestIdChange(val ?? null);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                allowClear
                                placeholder={t("offers.filter.offerStatus")}
                                style={{ width: "100%" }}
                                showSearch
                                onSelect={(value: any) => onOfferStatusChange(value)}
                                onClear={() => onOfferStatusClear(undefined)}
                            >
                                {Object.values(offerStatusOptions).map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.text}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={3}>
                            <Select
                                allowClear
                                placeholder={t("offers.filter.requestStatus")}
                                style={{ width: "100%" }}
                                showSearch
                                onSelect={(value: any) => onRequestStatusChange(value)}
                                onClear={() => onRequestStatusClear(undefined)}
                            >
                                {Object.values(requestStatusOptions).map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.text}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        
                        
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default OfferListFilter;
