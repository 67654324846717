import { Col, Input, InputNumber, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { BasicModel, CategoryModel, MachineSearchFilterModel, MachineSearchSortField } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
import { useQuery } from "../../helpers/use-query";
import MgCharacterSelect from "../atomics/inputs/mg-character-select";
import { restrictPositiveNumbersInRange } from "../../utils/input-rules";
type MachineListFilterProps = {
    onChange?: (params: MachineListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
    resetItems?: boolean;
};

type MachineListFilterViewModel = {
    searchFilterModel?: MachineSearchFilterModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    companyName?: string | null;
    sortBy: {
        field: MachineSearchSortField;
        sortAscending: boolean;
    };
};

const MachineListFilter = (props: MachineListFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const query = useQuery();
    const compName = query.get("compname");
    const clearProdYear = useRef(0);
    const [filterModel, setFilterModel] = useState<MachineListFilterViewModel>({
        sortBy: {
            field: MachineSearchSortField.ApprovalDate,
            sortAscending: false,
        },
        searchFilterModel: {
            companyName: compName,
        },
    });
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.resetItems) {
            setFilterModel({
                brandName: undefined,
                categoryName: undefined,
                companyName: undefined,
                searchFilterModel: undefined,
                subCategoryName: undefined,
                sortBy: {
                    field: MachineSearchSortField.ApprovalDate,
                    sortAscending: false,
                },
            });
        }
    }, [props.resetItems]);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);

        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                brandName: undefined,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: undefined,
                    brandId: undefined,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const productionYearChange = (val: string|number ) => {
        let prodYear: number | undefined = undefined;
        if (typeof val === "number") prodYear = val;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    productionYearFrom: prodYear,
                    productionYearTo: prodYear,
                },
            };
        });
    };

    const machineIdChange = (val: string|undefined) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    searchKeyword: val,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect 
                                value={filterModel.categoryName}
                                placeholder={t("common.mainCategory")}
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                                options={
                                    allCategories?.map((mc) => (
                                       {label:mc.name, value: mc.name}
                                    ))
                                }
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <MgCharacterSelect
                                loading={loading}
                                value={filterModel?.subCategoryName} 
                                placeholder={t("common.subCategory")}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                                options={availableSubcategories?.map((msc) => (
                                   {label: msc.name, value:msc.name}
                                ))}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <InputNumber
                                type="number"
                                key={clearProdYear.current}
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.productionYear")}
                                value={filterModel.searchFilterModel?.productionYearFrom || undefined}
                                onKeyDown={(event) => {
                                    const re = /[+-]/;
                                    if (re.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                onChange={(val) => {
                                    var num = restrictPositiveNumbersInRange(val ? val.toString() : undefined, 0, Infinity);
                                    productionYearChange(num ?? "");
                                }}
                            /> 
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.companyName")}
                                value={filterModel.searchFilterModel?.companyName || undefined}
                                onChange={(val) => {
                                    companyNameChange(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <InputNumber 
                                type="number"
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.machineIdLabel")}
                                onChange={(val) => {
                                    machineIdChange(val ? val.toString() : undefined )
                                }}
                            />
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default MachineListFilter;
