import { Col, Input, Row } from "antd";
import { useAtom } from "jotai";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { t } from "../translate";
import { MachineSaleCommissionCreateModel, MachineSaleCommissionViewModel } from "../service";
import { useEffect, useState } from "react";
import { useSecureService } from "../store/service-atoms";

type MachineSaleCommissionModalProps = {
    commission?: MachineSaleCommissionViewModel;
    subCategoryName?: string;
} & ModalLayoutProps;
const MachineSaleCommissionModal = (props: MachineSaleCommissionModalProps) => {
    const [loading] = useAtom(loadingAtom);
    const { doSetMachineCommissionForSale } = useSecureService();
    const [priceRate, setPriceRate] = useState<string | undefined>(props.commission?.priceRate?.toString());
    const [, setMessage] = useAtom(postloginMessageAtom);

    useEffect(() => {
        setPriceRate(props.commission?.priceRate?.toString());
        //eslint-disable-next-line
    }, [props.show]);

    const onContinueClick = async () => {
        let machineCommissionCreateModel: MachineSaleCommissionCreateModel = {
            subCategoryId: props.commission?.subCategoryId,
            categoryId: props.commission?.categoryId,
            priceRate: parseFloat(priceRate ?? "0"),
        };
        const result = await doSetMachineCommissionForSale(machineCommissionCreateModel);
        if (result) {
            setMessage({
                message: t("common.success"),
                type: "success",
            });
            props.onCloseClick(false);
        }
    };
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={props.subCategoryName + t("menu.commissionRate")}>
                <div style={{ border: "1px solid #949394", borderRadius: "1rem" }}>
                    <Col style={{ backgroundColor: "#f3f3f3", padding: 10, borderRadius: "1rem" }}>
                        <Row gutter={[16, 16]} wrap={false} justify="center" align="middle">
                            <Col>
                                <Row wrap={false} gutter={[16, 16]} style={{ marginBlock: "2%", alignItems: "center" }}>
                                    <Col style={{ marginLeft: "2%", fontSize: "16px", color: "#282828", fontWeight: 700 }}>
                                        {t("common.commissionRate")}
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            style={{ height: "45px" }}
                                            onChange={(val) => {
                                                setPriceRate(val.target.value);
                                            }}
                                            suffix="%"
                                            value={priceRate}
                                        ></Input>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </div>

                <Col span={18} offset={3}>
                    <PrimaryButton loading={loading} onClick={onContinueClick}>
                        {t("common.accept")}
                    </PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};
export default MachineSaleCommissionModal;
