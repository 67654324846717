import { Checkbox, Col, Collapse, Row } from "antd";
import { BasicModel, OfferFormBidViewModel } from "../service";
import { ReactComponent as Plus } from "../components/assets/plus.svg";
import { ReactComponent as Minus } from "../components/assets/minus.svg";
import { t } from "../translate";
import { useEffect, useState } from "react";

export type OfferViewBidPartsProps = {
    bids: (OfferFormBidViewModel);
    contextId?: number;
    periodPaymentType?: string;
    periodPaymentTypeId?: number | string;
    periodFee: string | number;
    children?: any;
    index: number;
    availableAttachments: BasicModel[];
    dealAccepted?: boolean;
    bidSelected?: boolean;
}


const OfferViewBidPart = (props: OfferViewBidPartsProps) => {
    const { Panel } = Collapse;
    const getTotal = (total: number, num: number) => {
        return total + Math.ceil(num);
    };
    const [totalBid, setTotalBid] = useState(0); 


    useEffect(() => {
        const total = (props.bids?.predictedTotal || 0) +
        (props.bids?.machineGoCommission || 0) +
        (props.bids?.shippingFeeWithCommission ? props.bids?.shippingFeeWithCommission : 0) +
        (props.bids?.shippingToBackFeeWithCommission ? props.bids?.shippingToBackFeeWithCommission : 0) +
        (props.bids?.attachments
            ? props.bids.attachments.map((e) => {
                  return e.fee ?? 0;
              })
                ? props.bids.attachments
                      .map((e) => {
                          return e.fee ?? 0;
                      })
                      .reduce(getTotal, 0)
                : 0
            : 0);
        setTotalBid(total);
    }, [props.bids]);
    return (
        <Row style={{width: '100%'}}>
            <Col xs={24}>
                <Collapse
                    expandIconPosition={"right"}
                    expandIcon={(panelProps) => {
                        if (panelProps.isActive) return <div style={{position: 'absolute', right: 0, top: 10}}><Minus  /></div>;
                        else return <div style={{position: 'absolute', right: 0, top: 10}}><Plus  /></div>;
                    }}
                >          

                    {
                        props.dealAccepted && props.bidSelected &&
                            <div style={{position: 'absolute', marginTop: '14px', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                                <Checkbox checked></Checkbox> 
                            </div>
                    }
                    <Panel style={{fontWeight: '700'}} header={<span style={{marginLeft: '25px', fontWeight: '700', fontSize: '1.25rem'}}>{props.bids?.maturityOption?.name}</span>} key={props.contextId ?? "-1"}>
                        <Row style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                <Col xs={24} md={10} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Col xs={12}>
                                        <span style={{fontWeight: '600'}}>{t("offers.view.period")}</span>
                                    </Col>
                                    <Col xs={12} style={{borderRadius: '10px',  background: '#EEF0F2', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , height: '2.5rem'}}>
                                        <span style={{fontWeight: '800', marginRight: '20px'}}>{props.periodPaymentType}</span>
                                    </Col>
                                </Col>

                                <Col xs={24} md={10} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Col xs={12}>
                                        <span style={{fontWeight: '600'}}>{t("offers.view.period.priceLabel" + props.periodPaymentTypeId)}</span>
                                    </Col>
                                    
                                    <Col xs={12} style={{borderRadius: '10px',  background: '#EEF0F2', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , height: '2.5rem'}}>
                                        <span style={{fontWeight: '800', marginRight: '20px'}}>{props.periodFee + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>
                                </Col>
                            </Row>

                            <Row style={{display: 'flex', width: '100%'}}>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offers.view.predictedTotal")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.predictedTotal && props.bids.predictedTotal !== 0  ? props.bids.predictedTotal : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offers.view.machineGoCommission")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.machineGoCommission && props.bids.machineGoCommission !== 0  ? props.bids.machineGoCommission : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offer.view.advance.payment")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.advancePayment && props.bids.advancePayment !== 0  ? props.bids.advancePayment : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offers.view.renterPeriodPayment")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.renterPeriodPayment && props.bids.renterPeriodPayment !== 0  ? props.bids.renterPeriodPayment : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offers.view.lessorMachineGoCommission")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.lessorMachineGoCommission && props.bids.lessorMachineGoCommission !== 0  ? props.bids.lessorMachineGoCommission : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offers.view.lessorPeriodPayment")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.lessorPeriodPayment && props.bids.lessorPeriodPayment !== 0  ? props.bids.lessorPeriodPayment : "-")  + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offer.view.shipping.fee")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.shippingFeeWithCommission ? props.bids.shippingFeeWithCommission :"-" ) + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{t("offer.view.shippingBack.fee")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px'}}>{(props.bids.shippingToBackFeeWithCommission ? props.bids.shippingToBackFeeWithCommission: "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                                <Row style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <Row style={{marginTop: '30px', width: '100%'}}>
                                        <Col xs={24} style={{fontWeight: '500', color: '#949394', marginLeft: '10px'}}>
                                            <span>{t("offer.view.attachments")}</span>
                                        </Col>
                                    </Row>
                                
                                    <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', width: '100%'}} gutter={[14,12]}>
                                        {
                                            props.bids.attachments?.length === 0 ?
                                                <Col xs={24} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#949394'}}>
                                                    <span>{"-"}</span>
                                                </Col>
                                            :
                                                props.bids.attachments?.map((attachment) => {
                                                return( 
                                                    <Col xs={24} md={12} lg={8}>
                                                        <Row style={{width: '100%', background: '#EEF0F2', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '700', height: '3rem'}}>
                                                            <Col xs={16} style={{paddingLeft: '5px'}}>
                                                                <span style={{ fontSize: '0.85rem', fontWeight: '700'}}>{props.availableAttachments.find(e=>e.id === attachment.attachmentId)?.name ?? ""}</span>
                                                            </Col>
                                                            <Col xs={8}>
                                                            <span style={{marginRight: '5px',fontWeight: '800'}} >{attachment.fee + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>          
                                                );
                                                })  
                                        } 
                                    </Row>
                                </Row>

                                <Row style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <Row style={{marginTop: '30px', width: '100%'}}>
                                        <Col xs={24} style={{fontWeight: '500', color: '#949394', marginLeft: '10px'}}>
                                            <span>{t("offer.view.shippingTitle")}</span>
                                        </Col>
                                    </Row>
                                
                                    <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', width: '100%'}} gutter={[14,12]}>
                                        <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                            <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                                <span style={{marginRight: '20px'}}>{t("offer.view.shippingToGo")}</span>
                                            </Col>

                                            <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                                <span style={{marginRight: '20px'}}>{(props.bids.shippingFee ? props.bids.shippingFee : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                            </Col>

                                        </Col>

                                        <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', background: "#EEF0F2", height:'3rem', borderRadius: '10px', marginTop: '1.5rem'}}>

                                            <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                                <span style={{marginRight: '20px'}}>{t("offer.view.shippingToReturn")}</span>
                                            </Col>

                                            <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                                <span style={{marginRight: '20px'}}>{(props.bids.shippingToBackFee ? props.bids.shippingToBackFee : "-") + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                            </Col>

                                        </Col>
                                    </Row>
                                </Row>

                                <Col xs={24} style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'3rem', marginTop: '1.5rem'}}>

                                    <Col xs={12} style={{fontWeight: '400', display:'flex', alignItems:'center', marginLeft: '15px'}}>
                                        <span style={{marginRight: '20px', fontWeight: '700'}}>{t("offers.view.totalBid")}</span>
                                    </Col>

                                    <Col xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'center', marginRight: '15px'}}>
                                        <span style={{marginRight: '20px', fontWeight: '800'}}>{totalBid + " " + (props.bids.currency ? props.bids.currency.name : "")}</span>
                                    </Col>

                                </Col>

                
                            </Row>
                    </Panel>
                </Collapse> 
            </Col>
        </Row>
    );
}


export default OfferViewBidPart;