import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../components/assets/left-arrow.svg";

const ViewMachine = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const navigate = useNavigate();
    const [dataFetching, setDataFetching] = useState(true);
    const [rentalDataFetched, setRentalDataFetched] = useState(false);
    const [machineFetched, setMachineFetched] = useState(false);
    const { doGetMachine, doUnApproveMachine } = useSecureService();
    useEffect(() => {
        editMachine(undefined);
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                }).finally(() => {setMachineFetched(true);});
                
        }
        //eslint-disable-next-line
    }, []);

    const onDataFetched = () => {
        setRentalDataFetched(true);
    };

    useEffect(() => {
        if (rentalDataFetched && machineFetched) {
            setDataFetching(false);
        }
    }, [rentalDataFetched,  machineFetched]);

    return (
        <ListContainer loading={dataFetching}>
            <Row justify="space-between" align="middle">
                <Col>
                    <div
                        style={{ marginBottom: "1rem", cursor: "pointer" }}
                        onClick={() => {
                            navigate("/rent/machines");
                        }}
                    >
                        <ArrowLeft style={{ marginBottom: "0.15rem" }}></ArrowLeft>
                        <span style={{ marginLeft: "0.75rem", fontWeight: "700", fontSize: "1.5rem" }}>{t("rent.machines-view.title")}</span>
                    </div>
                </Col>
                <Col style={{ float: "right" }}>
                    <Row gutter={[8, 0]}>
                        {editedMachine?.isMachineForSale && (
                            <Col>
                                <SecondaryButton
                                    onClick={() => {
                                        navigate("/sale/machines/view?macid=" + machineId);
                                    }}
                                >
                                    {t("machine.view.show.sale.machine")}
                                </SecondaryButton>
                            </Col>
                        )}
                        {editedMachine?.lessorCompanyId !== undefined && editedMachine?.lessorCompanyId !== null && (
                            <Col>
                                <SecondaryButton
                                    onClick={() => {
                                        navigate("/lessor-company/view?compid=" + editedMachine.lessorCompanyId, { replace: true });
                                    }}
                                    style={{ width: "20rem" }}
                                >
                                    {t("companies.detail.view")}
                                </SecondaryButton>
                            </Col>
                        )}
                        <Col>
                            <SecondaryButton
                                onClick={async () => {
                                    if (machineId !== null) {
                                        await doUnApproveMachine({ machineIds: [Number.parseInt(machineId)] });
                                        navigate("/rent/machines");
                                    }
                                }}
                                style={{ width: "10rem" }}
                            >
                                {t("machine.view.removeMachineFromPublishList")}
                            </SecondaryButton>
                        </Col>
                        <Col>
                            <PrimaryButton
                                onClick={() => {
                                    navigate("/rent/machines/edit?macid=" + machineId, { replace: true });
                                }}
                                style={{ width: "10rem" }}
                            >
                                {t("common.edit")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[4, 4]}>
                <Col xs={24} md={12}>
                    <ViewMachineInfoPart key={'machineInfo-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalInfoPart key={'machineAdditionalInfo-' + editedMachine?.id} viewType="machine" dataFetched={onDataFetched} machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart key={'machineService-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewServiceRegionsPart key={'machineServiceRegion-' + editedMachine?.id} viewType="machine" machine={editedMachine} />
                </Col>
            </Row>
        </ListContainer>
    );
};

export default ViewMachine;
