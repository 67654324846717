import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { t } from "../../../translate";

export type PhoneInputProps = {
    prefix: boolean;
};

const PhoneInput = (props: PhoneInputProps) => {
    const [phoneInput, setPhoneInput] = useState<string>();

    const Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        let phone = "";
        if (e.target.value.includes("+90")) {
            let val = e.target.value;
            phone = val;
        } else {
            if (!e.target.value.includes("+")) {
                let val = "+90" + e.target.value;
                phone = val;
            }
        }
        phone = phone.replace(/ /gm, "");
        let num = `${phone.substring(0, 3)} ${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 13)}`;
        num = num.trim();
        setPhoneInput(num);
    };
    return (
        <Form.Item
            name="username"
            rules={[
                {
                    pattern: /^[ 0-9+]*$/,
                    message: t("signup.form.phoneValidation"),
                    required: true,
                },
            ]}
        >
            <div>
                <Input
                    prefix={props.prefix ? <UserOutlined className="site-form-item-icon" /> : undefined}
                    maxLength={16}
                    style={{ width: "100%" }}
                    type={"tel"}
                    onChange={Change}
                    value={phoneInput}
                    defaultValue={"+90 "}
                    onKeyPress={(event) => {
                        if (!/[0-9,+]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </div>
        </Form.Item>
    );
};

export default PhoneInput;
