/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminForgotPasswordViewModel,
    AdminForgotPasswordViewModelFromJSON,
    AdminForgotPasswordViewModelToJSON,
    AdminLoginWithOtpModel,
    AdminLoginWithOtpModelFromJSON,
    AdminLoginWithOtpModelToJSON,
    AdminResetPasswordViewModel,
    AdminResetPasswordViewModelFromJSON,
    AdminResetPasswordViewModelToJSON,
    ChangePasswordViewModel,
    ChangePasswordViewModelFromJSON,
    ChangePasswordViewModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    Int32ValidatedModel,
    Int32ValidatedModelFromJSON,
    Int32ValidatedModelToJSON,
    LoginRequestModel,
    LoginRequestModelFromJSON,
    LoginRequestModelToJSON,
    LoginResponseModel,
    LoginResponseModelFromJSON,
    LoginResponseModelToJSON,
} from '../models';

export interface AdminChangePasswordPostRequest {
    changePasswordViewModel?: ChangePasswordViewModel;
}

export interface AdminForgotPasswordPostRequest {
    adminForgotPasswordViewModel?: AdminForgotPasswordViewModel;
}

export interface AdminResetPasswordPostRequest {
    adminResetPasswordViewModel?: AdminResetPasswordViewModel;
}

export interface AdminSendOtpPostRequest {
    loginRequestModel?: LoginRequestModel;
}

export interface AdminSignInPostRequest {
    adminLoginWithOtpModel?: AdminLoginWithOtpModel;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async adminChangePasswordPostRaw(requestParameters: AdminChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordViewModelToJSON(requestParameters.changePasswordViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async adminChangePasswordPost(requestParameters: AdminChangePasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.adminChangePasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Captcha ( AdminForgotPassword = adminforgotpassword )
     */
    async adminForgotPasswordPostRaw(requestParameters: AdminForgotPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminForgotPasswordViewModelToJSON(requestParameters.adminForgotPasswordViewModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( AdminForgotPassword = adminforgotpassword )
     */
    async adminForgotPasswordPost(requestParameters: AdminForgotPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.adminForgotPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( AdminResetPassword = adminresetpassword )
     */
    async adminResetPasswordPostRaw(requestParameters: AdminResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminResetPasswordViewModelToJSON(requestParameters.adminResetPasswordViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Captcha ( AdminResetPassword = adminresetpassword )
     */
    async adminResetPasswordPost(requestParameters: AdminResetPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.adminResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Captcha ( AdminSignIn = adminsignin )
     */
    async adminSendOtpPostRaw(requestParameters: AdminSendOtpPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/send-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestModelToJSON(requestParameters.loginRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( AdminSignIn = adminsignin )
     */
    async adminSendOtpPost(requestParameters: AdminSendOtpPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.adminSendOtpPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( AdminSignIn = adminsignin )
     */
    async adminSignInPostRaw(requestParameters: AdminSignInPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminLoginWithOtpModelToJSON(requestParameters.adminLoginWithOtpModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( AdminSignIn = adminsignin )
     */
    async adminSignInPost(requestParameters: AdminSignInPostRequest = {}, initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.adminSignInPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async adminSignOutGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/sign-out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async adminSignOutGet(initOverrides?: RequestInit): Promise<void> {
        await this.adminSignOutGetRaw(initOverrides);
    }

}
