/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanValidatedModel,
    BooleanValidatedModelFromJSON,
    BooleanValidatedModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SaleRequestFormCreateWithRegisterModel,
    SaleRequestFormCreateWithRegisterModelFromJSON,
    SaleRequestFormCreateWithRegisterModelToJSON,
    SaleRequestFormSearchModel,
    SaleRequestFormSearchModelFromJSON,
    SaleRequestFormSearchModelToJSON,
    SaleRequestFormViewModel,
    SaleRequestFormViewModelFromJSON,
    SaleRequestFormViewModelToJSON,
    SaleRequestFormViewModelPagedDataModel,
    SaleRequestFormViewModelPagedDataModelFromJSON,
    SaleRequestFormViewModelPagedDataModelToJSON,
    SaleRequestUpdateStatusRequestModel,
    SaleRequestUpdateStatusRequestModelFromJSON,
    SaleRequestUpdateStatusRequestModelToJSON,
} from '../models';

export interface SaleRequestFormCreateWithRegisterPostRequest {
    saleRequestFormCreateWithRegisterModel?: SaleRequestFormCreateWithRegisterModel;
}

export interface SaleRequestFormSearchPostRequest {
    saleRequestFormSearchModel?: SaleRequestFormSearchModel;
}

export interface SaleRequestFormUpdateStatusPostRequest {
    saleRequestUpdateStatusRequestModel?: SaleRequestUpdateStatusRequestModel;
}

/**
 * 
 */
export class SaleRequestFormApi extends runtime.BaseAPI {

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async saleRequestFormCreateWithRegisterPostRaw(requestParameters: SaleRequestFormCreateWithRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaleRequestFormViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-request-form/create-with-register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaleRequestFormCreateWithRegisterModelToJSON(requestParameters.saleRequestFormCreateWithRegisterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaleRequestFormViewModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async saleRequestFormCreateWithRegisterPost(requestParameters: SaleRequestFormCreateWithRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<SaleRequestFormViewModel> {
        const response = await this.saleRequestFormCreateWithRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async saleRequestFormSearchPostRaw(requestParameters: SaleRequestFormSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SaleRequestFormViewModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-request-form/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaleRequestFormSearchModelToJSON(requestParameters.saleRequestFormSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaleRequestFormViewModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async saleRequestFormSearchPost(requestParameters: SaleRequestFormSearchPostRequest = {}, initOverrides?: RequestInit): Promise<SaleRequestFormViewModelPagedDataModel> {
        const response = await this.saleRequestFormSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async saleRequestFormUpdateStatusPostRaw(requestParameters: SaleRequestFormUpdateStatusPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sale-request-form/update-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaleRequestUpdateStatusRequestModelToJSON(requestParameters.saleRequestUpdateStatusRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async saleRequestFormUpdateStatusPost(requestParameters: SaleRequestFormUpdateStatusPostRequest = {}, initOverrides?: RequestInit): Promise<BooleanValidatedModel> {
        const response = await this.saleRequestFormUpdateStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
