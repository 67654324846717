import { Col, Row } from "antd";
import { EventHandler } from "react";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import BoxContainer from "../components/containers/box-container";
import { t } from "../translate";

type requestViewPartProps = {
    requestId: number;
    dateFromTo:string;
    onClick?: EventHandler<any>;
};

const requestViewPart = (props: requestViewPartProps) => {
    return (
        <BoxContainer>
            <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Col md={10}>
                    <Row justify="start">
                        <Col md={10} style={{ color: "#949394", fontWeight: "800" }}>{t("request.id")}:</Col>
                        <Col  style={{ color: "#171725", fontWeight: "800" }}>{props.requestId}</Col>
                    </Row>
                    <Row justify="start">
                        <Col md={10} style={{ color: "#949394", fontWeight: "800" }}>{t("request.dateFromTo")}:</Col>
                        <Col  style={{ color: "#171725", fontWeight: "800" }}>{props.dateFromTo}</Col>
                    </Row>
                </Col>
                <Col >
                    <PrimaryButton style={{ marginBottom: "0" }} onClick={props.onClick}>
                        {t("request.viewRequest")}
                    </PrimaryButton>
                </Col>
            </Row>
        </BoxContainer>
    );
};
export default requestViewPart;
