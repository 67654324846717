import { Col, Form, Row } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import { useQuery } from "../../../helpers/use-query";
import MachineAdditionalInfoPart from "../../../page-parts/machine-additional-info-part";
import MachineAdditionalServicesPart from "../../../page-parts/additional-services-part";
import MachineMachineInfoPart from "../../../page-parts/machine-machine-info-part";
import MachineServiceRegionsPart from "../../../page-parts/machine-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import { BasicModel, DocumentCategory, DocumentModel, MachineDocumentViewModel, MachineRentalTypeModel } from "../../../service";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../../components/containers/list-container";

const EditMachine = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const machineId = query.get("macid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetRentalTypes, doGetMachine, doUpdateMachine, doGetCurrencies, doApproveMachineWithUpdate } = useSecureService();
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const navigate = useNavigate();
    const pageKey = useRef(0);
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);
    const otherDocs = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory !== "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        form.resetFields();
        if (machineId) {
            getMachine(Number.parseInt(machineId));
        } else {
            editMachine((mach) => {
                return {
                    ...mach,
                    lessorCompanyId: companyId ? parseInt(companyId) : undefined,
                    id: machineId ? parseInt(machineId) : undefined,
                };
            });
        }
        const getRentalTypes = async () => {
            const result = await doGetRentalTypes();
            setAvailableRentalTypes(result);
        };
        getRentalTypes();

        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };

        getCurrencies();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (editedMachine?.id?.toString() ?? "-1" === machineId) {
        interface ExtendedObject {
            [key: string]: any;
        }

        const addedForEditing: ExtendedObject = {};
        editedMachine?.rentalTypes?.forEach((rental) => {
            if (rental.rentalType !== undefined && rental.rentalType.name !== null && rental.rentalType.name !== undefined) {
                const checkName: string = "check" + rental.rentalType.name;
                const inputName: string = "input" + rental.rentalType.name;
                const selectName: string = "select" + rental.rentalType.name;

                addedForEditing[checkName] = true;
                addedForEditing[inputName] = rental.price;
                addedForEditing[selectName] = rental.currency;
            }
        });

        if (addedForEditing['checkSaatlik'] === undefined) {
            addedForEditing['checkSaatlik'] = false;
            addedForEditing['inputSaatlik'] = undefined;
            addedForEditing['selectSaatlik'] = undefined;
        }
        if (addedForEditing['checkGünlük'] === undefined) {
            addedForEditing['checkGünlük'] = false;
            addedForEditing['inputGünlük'] = undefined;
            addedForEditing['selectGünlük'] = undefined;
        }
        if (addedForEditing['checkAylık'] === undefined) {
            addedForEditing['checkAylık'] = false;
            addedForEditing['inputAylık'] = undefined;
            addedForEditing['selectAylık'] = undefined;
        }
        if (addedForEditing['checkYıllık'] === undefined) {
            addedForEditing['checkYıllık'] = false;
            addedForEditing['inputYıllık'] = undefined;
            addedForEditing['selectYıllık'] = undefined;
        }

        form.setFieldsValue({ 
            ...editedMachine, 
            ...addedForEditing,  
            otherBrandName: editedMachine?.brandOther,
            inputSaatlik: editedMachine?.rentalTypes?.find(e => e.rentalType?.id === 1)?.price,
            inputGünlük: editedMachine?.rentalTypes?.find(e => e.rentalType?.id === 2)?.price,
        });

        pageKey.current += 1;
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
        //eslint-disable-next-line
    }, [editedMachine]);

    const getMachine = async (machineId: number) => {
        const result = await doGetMachine(machineId);
        if (result?.documents) setDocs(result?.documents);
        form.setFieldsValue({   
            subcategoryName: result?.subcategoryName,
            categoryName: result?.subCategory?.category?.name,
        });
        editMachine(result);
    };

    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];
        availableRentalTypes?.forEach((rtype) => {
            if (params["check" + rtype.name] && params["input" + rtype.name]) {
                let rental: MachineRentalTypeModel = {
                    currency: availableCurrencies?.find((x) => x.name === params["select-" + rtype.name]),
                    price: params["input" + rtype.name],
                    rentalType: {
                        id: rtype.id,
                        name: rtype.name,
                    },
                };
                rentalTypes.push(rental);
            }
        });
        //editedMachine.rental
        const result = await doUpdateMachine({
            ...editedMachine,
            ...params,
            attachmentsList: Array.isArray(params.attachmentsList) ? params.attachmentsList : [params.attachmentsList],
            otherAttachmentsName: params.otherAttachmentsName,
            rentalTypes: rentalTypes,
            documents: docs,
        });
        if (result) {
            const toApprovePage = !editedMachine?.isApproved;
            if (!editedMachine?.isApproved && editedMachine?.id) await doApproveMachineWithUpdate({ machineIds: [editedMachine.id] });
            editMachine(undefined);
            toApprovePage ? navigate("/rent/pending-approval", { replace: true }) : navigate("/rent/machines", { replace: true });
        }
    };
    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var otherCategories = oldVal?.filter((doc) => doc.documentCategory !== category);
            if (!otherCategories) otherCategories = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            otherCategories?.push(...addedDocs);

            return [...otherCategories];
        });
    };

    return (
        <ListContainer loading={loading}>
            <Form name="edit-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <MachineMachineInfoPart key={pageKey.current} uploadChange={uploadedFilesChanged} docs={pics} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalInfoPart key={pageKey.current} uploadChange={uploadedFilesChanged} docs={otherDocs} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineAdditionalServicesPart key={pageKey.current} form={form} />
                    </Col>

                    <Col xs={24} md={12}>
                        <MachineServiceRegionsPart key={pageKey.current} />
                    </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs={6}>
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                paddingTop: "2.5rem",
                            }}
                        >
                            <Form.Item>
                                <PrimaryButton htmlType="submit" style={{ width: "100%", height: "100%" }}>
                                    <span style={{ fontWeight: "700", fontSize: "1.25rem" }}>{t("machine.edit.saveAndPublish")}</span>
                                </PrimaryButton>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </ListContainer>
    );
};

export default EditMachine;
