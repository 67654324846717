/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportMachinesResponseViewModel
 */
export interface ReportMachinesResponseViewModel {
    /**
     * 
     * @type {number}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompaniesName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyRegisteredMachineCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyLocation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportMachinesResponseViewModel
     */
    lessorCompanyCreationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCategory?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportMachinesResponseViewModel
     */
    machineSubCategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineSubCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineBrand?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineModel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    machineApproval?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCreationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportMachinesResponseViewModel
     */
    machineModifiedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportMachinesResponseViewModel
     */
    machineApprovalDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineServiceRegions?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image1?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image2?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image3?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image4?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image5?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesResponseViewModel
     */
    image6?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineHourlyPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCurrencyTypeHourlyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineDailyPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCurrencyTypeDailyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineMonthlyPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCurrencyTypeMonthlyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineYearlyPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportMachinesResponseViewModel
     */
    machineCurrencyTypeYearlyName?: string | null;
}

export function ReportMachinesResponseViewModelFromJSON(json: any): ReportMachinesResponseViewModel {
    return ReportMachinesResponseViewModelFromJSONTyped(json, false);
}

export function ReportMachinesResponseViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMachinesResponseViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessorCompanyId': !exists(json, 'lessorCompanyId') ? undefined : json['lessorCompanyId'],
        'lessorCompaniesName': !exists(json, 'lessorCompaniesName') ? undefined : json['lessorCompaniesName'],
        'lessorCompanyContactName': !exists(json, 'lessorCompanyContactName') ? undefined : json['lessorCompanyContactName'],
        'lessorCompanyPhone': !exists(json, 'lessorCompanyPhone') ? undefined : json['lessorCompanyPhone'],
        'lessorCompanyEmail': !exists(json, 'lessorCompanyEmail') ? undefined : json['lessorCompanyEmail'],
        'lessorCompanyRegisteredMachineCount': !exists(json, 'lessorCompanyRegisteredMachineCount') ? undefined : json['lessorCompanyRegisteredMachineCount'],
        'lessorCompanyLocation': !exists(json, 'lessorCompanyLocation') ? undefined : json['lessorCompanyLocation'],
        'lessorCompanyCreationDate': !exists(json, 'lessorCompanyCreationDate') ? undefined : (json['lessorCompanyCreationDate'] === null ? null : new Date(json['lessorCompanyCreationDate'])),
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'machineCategoryId': !exists(json, 'machineCategoryId') ? undefined : json['machineCategoryId'],
        'machineCategory': !exists(json, 'machineCategory') ? undefined : json['machineCategory'],
        'machineSubCategoryId': !exists(json, 'machineSubCategoryId') ? undefined : json['machineSubCategoryId'],
        'machineSubCategory': !exists(json, 'machineSubCategory') ? undefined : json['machineSubCategory'],
        'machineBrand': !exists(json, 'machineBrand') ? undefined : json['machineBrand'],
        'machineModel': !exists(json, 'machineModel') ? undefined : json['machineModel'],
        'machineApproval': !exists(json, 'machineApproval') ? undefined : json['machineApproval'],
        'machineCreationDate': !exists(json, 'machineCreationDate') ? undefined : (json['machineCreationDate'] === null ? null : new Date(json['machineCreationDate'])),
        'machineModifiedDate': !exists(json, 'machineModifiedDate') ? undefined : (json['machineModifiedDate'] === null ? null : new Date(json['machineModifiedDate'])),
        'machineApprovalDate': !exists(json, 'machineApprovalDate') ? undefined : (json['machineApprovalDate'] === null ? null : new Date(json['machineApprovalDate'])),
        'machineServiceRegions': !exists(json, 'machineServiceRegions') ? undefined : json['machineServiceRegions'],
        'image1': !exists(json, 'image1') ? undefined : json['image1'],
        'image2': !exists(json, 'image2') ? undefined : json['image2'],
        'image3': !exists(json, 'image3') ? undefined : json['image3'],
        'image4': !exists(json, 'image4') ? undefined : json['image4'],
        'image5': !exists(json, 'image5') ? undefined : json['image5'],
        'image6': !exists(json, 'image6') ? undefined : json['image6'],
        'machineHourlyPrice': !exists(json, 'machineHourlyPrice') ? undefined : json['machineHourlyPrice'],
        'machineCurrencyTypeHourlyName': !exists(json, 'machineCurrencyTypeHourlyName') ? undefined : json['machineCurrencyTypeHourlyName'],
        'machineDailyPrice': !exists(json, 'machineDailyPrice') ? undefined : json['machineDailyPrice'],
        'machineCurrencyTypeDailyName': !exists(json, 'machineCurrencyTypeDailyName') ? undefined : json['machineCurrencyTypeDailyName'],
        'machineMonthlyPrice': !exists(json, 'machineMonthlyPrice') ? undefined : json['machineMonthlyPrice'],
        'machineCurrencyTypeMonthlyName': !exists(json, 'machineCurrencyTypeMonthlyName') ? undefined : json['machineCurrencyTypeMonthlyName'],
        'machineYearlyPrice': !exists(json, 'machineYearlyPrice') ? undefined : json['machineYearlyPrice'],
        'machineCurrencyTypeYearlyName': !exists(json, 'machineCurrencyTypeYearlyName') ? undefined : json['machineCurrencyTypeYearlyName'],
    };
}

export function ReportMachinesResponseViewModelToJSON(value?: ReportMachinesResponseViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessorCompanyId': value.lessorCompanyId,
        'lessorCompaniesName': value.lessorCompaniesName,
        'lessorCompanyContactName': value.lessorCompanyContactName,
        'lessorCompanyPhone': value.lessorCompanyPhone,
        'lessorCompanyEmail': value.lessorCompanyEmail,
        'lessorCompanyRegisteredMachineCount': value.lessorCompanyRegisteredMachineCount,
        'lessorCompanyLocation': value.lessorCompanyLocation,
        'lessorCompanyCreationDate': value.lessorCompanyCreationDate === undefined ? undefined : (value.lessorCompanyCreationDate === null ? null : value.lessorCompanyCreationDate.toISOString()),
        'machineId': value.machineId,
        'machineCategoryId': value.machineCategoryId,
        'machineCategory': value.machineCategory,
        'machineSubCategoryId': value.machineSubCategoryId,
        'machineSubCategory': value.machineSubCategory,
        'machineBrand': value.machineBrand,
        'machineModel': value.machineModel,
        'machineApproval': value.machineApproval,
        'machineCreationDate': value.machineCreationDate === undefined ? undefined : (value.machineCreationDate === null ? null : value.machineCreationDate.toISOString()),
        'machineModifiedDate': value.machineModifiedDate === undefined ? undefined : (value.machineModifiedDate === null ? null : value.machineModifiedDate.toISOString()),
        'machineApprovalDate': value.machineApprovalDate === undefined ? undefined : (value.machineApprovalDate === null ? null : value.machineApprovalDate.toISOString()),
        'machineServiceRegions': value.machineServiceRegions,
        'image1': value.image1,
        'image2': value.image2,
        'image3': value.image3,
        'image4': value.image4,
        'image5': value.image5,
        'image6': value.image6,
        'machineHourlyPrice': value.machineHourlyPrice,
        'machineCurrencyTypeHourlyName': value.machineCurrencyTypeHourlyName,
        'machineDailyPrice': value.machineDailyPrice,
        'machineCurrencyTypeDailyName': value.machineCurrencyTypeDailyName,
        'machineMonthlyPrice': value.machineMonthlyPrice,
        'machineCurrencyTypeMonthlyName': value.machineCurrencyTypeMonthlyName,
        'machineYearlyPrice': value.machineYearlyPrice,
        'machineCurrencyTypeYearlyName': value.machineCurrencyTypeYearlyName,
    };
}

