import { MoreOutlined } from "@ant-design/icons";
import { Col, Dropdown, Row, Skeleton } from "antd";
import { t } from "../../translate";
import PrimaryButton from "./buttons/primary-button";
import style from "./product-card.module.scss";
import Link from "antd/lib/typography/Link";

export type ProductItem = {
    id: number;
    lessorPrice?: string;
    head: string;
    text: string;
    image: any;
    daily?: string;
    monthly?: string;
};

type ProductProps = {
    index: number;
    productItem?: ProductItem | undefined;
    children?: any;
    pageType: 'sale' | 'rent';
    handleClick: (i: number, shouldRemove: boolean) => void;
    getMenu: (index: number) => JSX.Element;
};

const ProductCard = (props: ProductProps) => {
    return (
        <Col className={style.productCard}>
            <Row className={style.productCardBody}>
                <Col className={style.productCardImage} style={{ position: "relative" }}>
                    {props.productItem ? (
                        <>
                            {props.productItem.image}
                            <span className={style.spanImg}>
                                <Dropdown placement="bottomLeft" trigger={["click"]} overlay={<>{props.getMenu(props.index)}</>}>
                                    <Link>
                                        <div>
                                            <span className={style.idStyle}>{props.index + 1}</span>
                                            <MoreOutlined style={{ color: "#000000" }}/>
                                        </div>
                                    </Link>
                                </Dropdown>
                            </span>
                        </>
                    ) : (
                        <Skeleton.Image style={{ marginTop: "2rem" }}></Skeleton.Image>
                    )}
                </Col>
                {props.productItem ? (
                    <>
                        <Col className={style.productCategory}>{props.productItem.head}</Col>
                        <Col className={style.productSubCategory}>{props.productItem.text}</Col>
                    </>
                ) : (
                    <Skeleton active={true} paragraph={{ rows: 1 }}></Skeleton>
                )}

                <Col>
                    {props.productItem ? (
                        <Row>
                            <Col className={style.period} md={8} xs={6}>
                                {t("machines.id")}
                            </Col>
                            <Col className={style.price} md={16} xs={18}>
                                {props.productItem.id}
                            </Col>

                            {props.pageType === 'rent' ? 
                                (
                                    <>
                                        <Col className={style.period} md={8} xs={6}>
                                            {t("common.daily")}
                                        </Col>
                                        <Col className={style.price} md={16} xs={18}>
                                            {props.productItem.daily}
                                        </Col>
                                        <Col className={style.period} md={8} xs={6}>
                                            {t("common.monthly")}
                                        </Col>
                                        <Col className={style.price} md={16} xs={18}>
                                            {props.productItem.monthly}
                                        </Col>
                                    </>
                                )
                            :
                                (
                                    <>
                                        <Col className={style.period} md={12} xs={8}>
                                            {t("common.lessor-price")}
                                        </Col>
                                        <Col className={style.price} md={12} xs={16}>
                                            {props.productItem.lessorPrice}
                                        </Col>
                                    </>

                                )
                            }
                        </Row>
                    ) : (
                        <Skeleton active={true} paragraph={{ rows: 1 }}></Skeleton>
                    )}
                </Col>
                <Col>
                    <PrimaryButton
                        onClick={() => props.handleClick(props.index, props.productItem ? true : false)}
                        style={{ width: "100%", marginTop: "1rem" }}
                    >
                        {props.productItem ? t("common.remove") : t("common.choice")}
                    </PrimaryButton>
                </Col>
            </Row>
        </Col>
    );
};
export default ProductCard;
