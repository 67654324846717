import { Button, Checkbox, Col, Row, Select, Space, TablePaginationConfig, message } from "antd";
import ListContainer from "../../../components/containers/list-container";
import { t } from "../../../translate";
import PendingApprovalFilter from "../../../components/containers/pending-approval-filter";
import { useEffect, useRef, useState } from "react";
import { BasicModel, DocumentCategory, MachineDocumentViewModel, MachineSearchSortField } from "../../../service/models";
import { useQuery } from "../../../helpers/use-query";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { PendingApprovalFilterModel } from "../../../service/models/PendingApprovalFilterModel";
import BoxContainer from "../../../components/containers/box-container";
import DataTable from "../../../components/atomics/data-table";
import { ExtendedMachineDetailModel, SearchMachineModel } from "../../../service/models/ExtendedMachineDetailModel";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loadingAtom } from "../../../store/global-atoms";
import { moment } from "../../../helpers/moment";
import { CSVLink } from "react-csv";
import { ReactComponent as CsvDownload } from "./../../../components/assets/download-csv.svg";
import { Data } from "react-csv/components/CommonPropTypes";
import { useCsvExport } from "../../../helpers/useTableData";
import { ReactComponent as Placeholder } from "./../../../components/assets/placeholder.svg";
import { activeApiBaseUrl } from "../../../store/app-constants";
import styles from "../../admin/style/machine.module.css";

type TableModel = {
    companyName?: string | null;
    districtAndCity: string | undefined;
    subCategoryName?: string | null;
    allAttachments?: string | null;
    documents?: MachineDocumentViewModel[] | null;
} & ExtendedMachineDetailModel;

const PendingApproval = () => {
    const query = useQuery();
    const compName = query.get("compname");
    const navigate = useNavigate();
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const { doSearchMachineByApprovalStatus, doApproveMachine } = useSecureService();
    const [machinesToApprove, setMachinesToApprove] = useState<number[]>([]);
    const [canvas, setCanvas] = useState(false);
    const [selectedItemCount, setSelectedItemCount] = useState<number>(0);
    const [selectAllIDs, setSelectAllIDs] = useState<number[]>([]);
    const [, setSelectedMachine] = useState<ExtendedMachineDetailModel[]>([]);
    const [allCheck, setAllCheck] = useState(false);
    const [doGetMachineTableData] = useCsvExport();
    const [filterObj, setFilterObj] = useState<{
        filterModel: PendingApprovalFilterModel;
        sortBy: {
            field: MachineSearchSortField;
            sortAscending: boolean;
        };
    }>({
        filterModel: {
            companyName: compName,
        },
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const { Option } = Select;

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setAllCheck(false);
        setSelectAllIDs([]);
        setMachinesToApprove([]);
        setSelectedItemCount(0);
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current, pageSize: tablePagination.pageSize };
        });
    };

    const columns = [
        {
            title: (
                <Checkbox
                    onChange={(e) => {
                        if (e.target.checked) {
                            setMachinesToApprove(selectAllIDs);
                            setAllCheck(true);
                            setSelectedItemCount(selectAllIDs.length);
                        } else {
                            setMachinesToApprove([]);
                            setAllCheck(false);
                            setSelectedItemCount(0);
                        }
                    }}
                    checked={allCheck}
                ></Checkbox>
            ),
            dataIndex: "id",
            key: "idCheckbox",
            render: (text: string, detail: any) => (
                <Checkbox
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedMachine((prev) => {
                                return [...prev, detail];
                            });
                            setSelectedItemCount((prev) => {
                                return prev + 1;
                            });
                            setMachinesToApprove(
                                !machinesToApprove.includes(Number.parseInt(text))
                                    ? [...machinesToApprove, Number.parseInt(text)]
                                    : machinesToApprove
                            );
                        } else {
                            setSelectedMachine((prev) => {
                                return prev?.filter((e) => e.id !== detail.id);
                            });
                            setSelectedItemCount((prev) => {
                                return prev - 1;
                            });
                            setMachinesToApprove(
                                machinesToApprove.filter((e) => {
                                    return e !== Number.parseInt(text);
                                })
                            );
                            setAllCheck(false);
                        }
                    }}
                    checked={machinesToApprove.includes(Number.parseInt(text))}
                    name={detail.id}
                ></Checkbox>
            ),
        },

        canvas
            ? {
                  title: t("machines.table.image"),
                  key: "documents",
                  render: (record: TableModel) => {
                      // Makinenin belgelerinden fotoğrafları filtreleyin
                      console.log(record);
                      const images = record.documents?.filter((doc) => doc.documentCategory === DocumentCategory.Photo);
                      console.log(images);
                      // Fotoğraf belgelerinin URL'lerini oluşturun
                      const imageUrls = images?.map((doc) => activeApiBaseUrl + "/document/" + doc.document?.id);

                      // İlk fotoğrafın URL'sini döndürün veya boş döndürün
                      const firstImageUrl = imageUrls && imageUrls.length > 0 ? imageUrls[0] : "";

                      // İlk fotoğraf varsa, bu fotoğrafı gösterin
                      if (firstImageUrl) {
                          return (
                              <a href={firstImageUrl} target="_blank" rel="noopener noreferrer">
                                  <div
                                      style={{
                                          width: "200px",
                                          height: "150px",
                                          overflow: "hidden",
                                          borderRadius: "8px",
                                          border: "1px solid #ddd",
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                      }}
                                  >
                                      <img
                                          src={firstImageUrl}
                                          alt="Machine"
                                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                      />
                                  </div>
                              </a>
                          );
                      } else {
                          return (
                              <a href={firstImageUrl} target="_blank" rel="noopener noreferrer">
                                  <div
                                      style={{
                                          width: "200px",
                                          height: "150px",
                                          overflow: "hidden",
                                          borderRadius: "8px",
                                          border: "1px solid #ddd",
                                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          background: "#f5f5f5",
                                      }}
                                  >
                                      <Placeholder />
                                  </div>
                              </a>
                          );
                      }
                  },
              }
            : {},

        {
            title: t("machines.table.machineId"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <div>{text}</div>{" "}
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) =>
                canvas ? (
                    <a className={styles.tooltipText} href={"/lessor-company/view?compid=" + val.id} target="blank">
                        <div className={styles.firmName}>{val.name}</div>
                    </a>
                ) : (
                    <div className={styles.firmName}>{val.name}</div>
                ),
        },
        {
            title: t("machines.table.CreationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.action"),
            key: "action",
            render: (text: string, record: any) => (
                <Button
                    size="middle"
                    onClick={() => {
                        navigate("/rent/pending-approval/detail?macid=" + record.id);
                    }}
                >
                    <span style={{ fontWeight: "700" }}>{t("pendingApproval.button.detail")}</span>
                </Button>
            ),
        },
    ];
    const fillMachineList = async () => {
        try {
            const result = await doSearchMachineByApprovalStatus(
                {
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    filter: filterObj.filterModel,
                    sortAscending: filterObj.sortBy.sortAscending,
                    sortBy: filterObj.sortBy.field,
                },
                false
            );
            if (result?.machines !== null && result && result.machines !== undefined) {
                setItemCount(result?.itemCount);
                setMachines(await doGetMachineTableData(result?.machines, false));

                setSelectAllIDs((oldVal) => {
                    if (result.machines) return [...result.machines.map((e) => e.id ?? -1)];
                    return [];
                });
            }
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    useEffect(() => {
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        let result: SearchMachineModel | undefined = undefined;

        result = await doSearchMachineByApprovalStatus(
            {
                pageNo: 1,
                pageSize: itemCount ?? pagination.current,
                filter: undefined,
                sortAscending: filterObj.sortBy.sortAscending,
                sortBy: filterObj.sortBy.field,
            },
            false
        );

        if (result?.machines?.length !== undefined && result?.machines?.length > 0) {
            const exportData = await doGetMachineTableData(result?.data, true);
            if (exportData?.length !== undefined && exportData?.length > 0) {
                setAllRows(exportData);
                csvRef.current?.link.click();
            }
            //result.data[0].rentalTypes[0].rentalFee
        }
    };

    return (
        <ListContainer>
            <PendingApprovalFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                    });
                }}
            ></PendingApprovalFilter>
            <BoxContainer>
                <Row justify="space-between" align="middle" style={{ marginBottom: "1rem" }}>
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>
                            {t("pendingApproval.title")} -{" "}
                            <strong>
                                {" "}
                                ({t("common.total")} {itemCount})
                            </strong>
                        </h3>
                    </Col>
                    <Col style={{ display: "flex", float: "right" }}>
                        <PrimaryButton
                            onClick={async () => {
                                if (selectedItemCount > 0) {
                                    await doApproveMachine({ machineIds: machinesToApprove });
                                    await fillMachineList();
                                    setMachinesToApprove([]);
                                    setAllCheck(false);
                                }
                            }}
                            disabled={loading}
                            style={{ marginRight: "0.5rem", width: "inherit", marginBottom: "0px" }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {selectedItemCount < 2
                                    ? t("pendingApproval.primaryButtonText.SingleSelection")
                                    : t("pendingApproval.primaryButtonText.MultipleSelection")}
                            </div>
                        </PrimaryButton>
                        <SecondaryButton onClick={exportClick} style={{ width: "initial", marginBottom: "0px" }}>
                            <CsvDownload style={{ display: "flex" }} />
                        </SecondaryButton>

                        <CSVLink filename="machines.csv" ref={csvRef} data={allRows} separator={";"} />
                    </Col>
                </Row>
                <Row justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col>
                        <Select
                            style={{ width: 350, fontWeight: "700" }}
                            placeholder={t("common.sortBy")}
                            allowClear
                            onChange={(val) => {
                                setFilterObj((filter) => {
                                    return {
                                        ...filter,
                                        sortBy: {
                                            sortAscending: true,
                                            field: val,
                                        },
                                    };
                                });
                            }}
                        >
                            <Option value="creationDate">{t("common.creationDate")}</Option>
                            <Option value="productionYear">{t("common.productionYear")}</Option>
                        </Select>
                    </Col>
                    <Col>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder={t("machines.table.view")}
                                    onChange={(val) => {
                                        setCanvas(val);
                                    }}
                                >
                                    <Option value={false}>{t("machines.table.setList")}</Option>
                                    <Option value={true}>{t("machines.table.setCanvas")}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    <DataTable
                        onChange={handleChange}
                        columns={columns}
                        dataSource={machines}
                        loading={loading}
                        pagination={{
                            ...pagination,
                            total: itemCount,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "50", "100", "250", "500"],
                        }}
                    />
                }
            </BoxContainer>
        </ListContainer>
    );
};

export default PendingApproval;
