import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styles from "../../styles/btn.module.scss";

const TextButton = (props: ButtonProps) => {
    return (
        <Button {...props} type="text" className={styles.textBtn}>
            {props.children}
        </Button>
    );
};

export default TextButton;
