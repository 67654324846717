/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineSaleListModel,
    MachineSaleListModelFromJSON,
    MachineSaleListModelFromJSONTyped,
    MachineSaleListModelToJSON,
} from './MachineSaleListModel';

/**
 * 
 * @export
 * @interface SaleShowcaseViewModel
 */
export interface SaleShowcaseViewModel {
    /**
     * 
     * @type {MachineSaleListModel}
     * @memberof SaleShowcaseViewModel
     */
    machine?: MachineSaleListModel;
    /**
     * 
     * @type {number}
     * @memberof SaleShowcaseViewModel
     */
    rank?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaleShowcaseViewModel
     */
    isAdminAdded?: boolean;
}

export function SaleShowcaseViewModelFromJSON(json: any): SaleShowcaseViewModel {
    return SaleShowcaseViewModelFromJSONTyped(json, false);
}

export function SaleShowcaseViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleShowcaseViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machine': !exists(json, 'machine') ? undefined : MachineSaleListModelFromJSON(json['machine']),
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'isAdminAdded': !exists(json, 'isAdminAdded') ? undefined : json['isAdminAdded'],
    };
}

export function SaleShowcaseViewModelToJSON(value?: SaleShowcaseViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machine': MachineSaleListModelToJSON(value.machine),
        'rank': value.rank,
        'isAdminAdded': value.isAdminAdded,
    };
}

