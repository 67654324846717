import { Select, SelectProps } from 'antd'
import React, { useState } from 'react'
import { restrictNumbers, restrictSpecialCharacters } from '../../../utils/input-rules';

type MgCharacterSelectProps = {
  allowClear?:boolean;
  showSearch?:boolean;
} & SelectProps


const MgCharacterSelect = (props:MgCharacterSelectProps) => {

  const [searchValue,setSearchValue] = useState<string|undefined>(undefined)

  return (
    <Select 
      allowClear={props.allowClear ?? true}
      showSearch={props.showSearch ?? true}
      style={{ width: "100%" }}
      onSearch={(value:string)=>{
        const input = restrictNumbers(restrictSpecialCharacters(value))
        setSearchValue(input)
      }}
      searchValue={searchValue}
      {...props}
/>
  )
}

export default MgCharacterSelect

