/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportMachinesModel
 */
export interface ReportMachinesModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportMachinesModel
     */
    lessorCompanyIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyContactName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyPhone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyRegisteredMachineCount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    lessorCompanyCreationDate?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportMachinesModel
     */
    machineCategoryIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineId?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineBrand?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineModel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineApproval?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineServiceRegions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineCreationDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineModifiedDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineApprovalDate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportMachinesModel
     */
    machineRequestedImageCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineHourlyPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineDailyPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineMonthlyPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineYearlyPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineCurrencyTypeTL?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineCurrencyTypeUSD?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReportMachinesModel
     */
    machineCurrencyTypeEUR?: boolean;
}

export function ReportMachinesModelFromJSON(json: any): ReportMachinesModel {
    return ReportMachinesModelFromJSONTyped(json, false);
}

export function ReportMachinesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMachinesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessorCompanyIds': !exists(json, 'lessorCompanyIds') ? undefined : json['lessorCompanyIds'],
        'lessorCompanyName': !exists(json, 'lessorCompanyName') ? undefined : json['lessorCompanyName'],
        'lessorCompanyContactName': !exists(json, 'lessorCompanyContactName') ? undefined : json['lessorCompanyContactName'],
        'lessorCompanyPhone': !exists(json, 'lessorCompanyPhone') ? undefined : json['lessorCompanyPhone'],
        'lessorCompanyEmail': !exists(json, 'lessorCompanyEmail') ? undefined : json['lessorCompanyEmail'],
        'lessorCompanyRegisteredMachineCount': !exists(json, 'lessorCompanyRegisteredMachineCount') ? undefined : json['lessorCompanyRegisteredMachineCount'],
        'lessorCompanyLocation': !exists(json, 'lessorCompanyLocation') ? undefined : json['lessorCompanyLocation'],
        'lessorCompanyCreationDate': !exists(json, 'lessorCompanyCreationDate') ? undefined : json['lessorCompanyCreationDate'],
        'machineCategoryIds': !exists(json, 'machineCategoryIds') ? undefined : json['machineCategoryIds'],
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
        'machineBrand': !exists(json, 'machineBrand') ? undefined : json['machineBrand'],
        'machineModel': !exists(json, 'machineModel') ? undefined : json['machineModel'],
        'machineApproval': !exists(json, 'machineApproval') ? undefined : json['machineApproval'],
        'machineServiceRegions': !exists(json, 'machineServiceRegions') ? undefined : json['machineServiceRegions'],
        'machineCreationDate': !exists(json, 'machineCreationDate') ? undefined : json['machineCreationDate'],
        'machineModifiedDate': !exists(json, 'machineModifiedDate') ? undefined : json['machineModifiedDate'],
        'machineApprovalDate': !exists(json, 'machineApprovalDate') ? undefined : json['machineApprovalDate'],
        'machineRequestedImageCount': !exists(json, 'machineRequestedImageCount') ? undefined : json['machineRequestedImageCount'],
        'machineHourlyPrice': !exists(json, 'machineHourlyPrice') ? undefined : json['machineHourlyPrice'],
        'machineDailyPrice': !exists(json, 'machineDailyPrice') ? undefined : json['machineDailyPrice'],
        'machineMonthlyPrice': !exists(json, 'machineMonthlyPrice') ? undefined : json['machineMonthlyPrice'],
        'machineYearlyPrice': !exists(json, 'machineYearlyPrice') ? undefined : json['machineYearlyPrice'],
        'machineCurrencyTypeTL': !exists(json, 'machineCurrencyTypeTL') ? undefined : json['machineCurrencyTypeTL'],
        'machineCurrencyTypeUSD': !exists(json, 'machineCurrencyTypeUSD') ? undefined : json['machineCurrencyTypeUSD'],
        'machineCurrencyTypeEUR': !exists(json, 'machineCurrencyTypeEUR') ? undefined : json['machineCurrencyTypeEUR'],
    };
}

export function ReportMachinesModelToJSON(value?: ReportMachinesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessorCompanyIds': value.lessorCompanyIds,
        'lessorCompanyName': value.lessorCompanyName,
        'lessorCompanyContactName': value.lessorCompanyContactName,
        'lessorCompanyPhone': value.lessorCompanyPhone,
        'lessorCompanyEmail': value.lessorCompanyEmail,
        'lessorCompanyRegisteredMachineCount': value.lessorCompanyRegisteredMachineCount,
        'lessorCompanyLocation': value.lessorCompanyLocation,
        'lessorCompanyCreationDate': value.lessorCompanyCreationDate,
        'machineCategoryIds': value.machineCategoryIds,
        'machineId': value.machineId,
        'machineBrand': value.machineBrand,
        'machineModel': value.machineModel,
        'machineApproval': value.machineApproval,
        'machineServiceRegions': value.machineServiceRegions,
        'machineCreationDate': value.machineCreationDate,
        'machineModifiedDate': value.machineModifiedDate,
        'machineApprovalDate': value.machineApprovalDate,
        'machineRequestedImageCount': value.machineRequestedImageCount,
        'machineHourlyPrice': value.machineHourlyPrice,
        'machineDailyPrice': value.machineDailyPrice,
        'machineMonthlyPrice': value.machineMonthlyPrice,
        'machineYearlyPrice': value.machineYearlyPrice,
        'machineCurrencyTypeTL': value.machineCurrencyTypeTL,
        'machineCurrencyTypeUSD': value.machineCurrencyTypeUSD,
        'machineCurrencyTypeEUR': value.machineCurrencyTypeEUR,
    };
}

