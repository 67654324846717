import { Button, Checkbox, Col, Row, Select, Space, TablePaginationConfig, Tooltip, message } from "antd";
import ListContainer from "../../../components/containers/list-container";
import { t } from "../../../translate";
import { useEffect, useRef, useState } from "react";
import { BasicModel, MachineSearchSortField } from "../../../service/models";
import { useQuery } from "../../../helpers/use-query";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { PendingApprovalFilterModel } from "../../../service/models/PendingApprovalFilterModel";
import BoxContainer from "../../../components/containers/box-container";
import DataTable from "../../../components/atomics/data-table";
import { ExtendedMachineDetailModel, SearchMachineModel } from "../../../service/models/ExtendedMachineDetailModel";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { loadingAtom } from "../../../store/global-atoms";
import { moment } from "../../../helpers/moment";
import { CSVLink } from "react-csv";
import { ReactComponent as CsvDownload } from "./../../../components/assets/download-csv.svg";
import styles from "../style/machine.module.css";
import OnSalePendingApprovalFilter from "../../../components/containers/on-sale-pending-approval-filter";
import { ReactComponent as Edit } from "../../../components/assets/edit.svg";
import { ReactComponent as Approve } from "../../../components/assets/approve.svg";
import { ReactComponent as PlusB } from "../../../components/assets/plus-black.svg";
import SelectCompany from "../../../modals/select-company";
import ApproveSaleMachinesModal from "../../../modals/approve-sale-machines";
import { formatString } from "typescript-string-operations";
import SuccessApproveSaleMachinesModal from "../../../modals/success-approve-sale-machines";
import { Data } from "react-csv/components/CommonPropTypes";
import { useCsvExport } from "../../../helpers/useTableData";

type TableModel = {
    companyName?: string | null;
    districtAndCity: string | undefined;
    subCategoryName?: string | null;
    allAttachments?: string | null;
    CreationDate?: string;
    ModifiedDate?: string;
} & ExtendedMachineDetailModel;

const OnSalePendingApproval = () => {
    const query = useQuery();
    const compName = query.get("compname");
    const navigate = useNavigate();
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const { doSearchSaleMachine, doApproveSaleMachine } = useSecureService();
    const [machinesToApprove, setMachinesToApprove] = useState<number[]>([]);
    const [selectedItemCount, setSelectedItemCount] = useState<number>(0);
    const [selectAllIDs, setSelectAllIDs] = useState<number[]>([]);
    const [, setSelectedMachine] = useState<ExtendedMachineDetailModel[]>([]);
    const [allCheck, setAllCheck] = useState(false);
    const [selectcompanyVisible, toggleSelectCompanyVisible] = useState(false);
    const [showApproveSaleMachinesModal, setShowApproveSaleMachinesModal] = useState(false);
    const [showIsApprovedSaleMachinesModal, setShowIsApprovedSaleMachinesModal] = useState(false);
    const [doGetMachineTableData] = useCsvExport();
    const [filterObj, setFilterObj] = useState<{
        filterModel: PendingApprovalFilterModel;
        sortBy: MachineSearchSortField;
        sortAscending: boolean;
    }>({
        filterModel: {
            companyName: compName,
        },
        sortBy: MachineSearchSortField.CreationDate,
        sortAscending: false,
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const { Option } = Select;

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setAllCheck(false);
        setSelectAllIDs([]);
        setMachinesToApprove([]);
        setSelectedItemCount(0);
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    const columns = [
        {
            title: <></>,
            dataIndex: "id",
            key: "idCheckbox",
            render: (text: string, detail: any) => (
                <Checkbox
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedMachine((prev) => {
                                return [...prev, detail];
                            });
                            setSelectedItemCount((prev) => {
                                return prev + 1;
                            });
                            setMachinesToApprove(
                                !machinesToApprove.includes(Number.parseInt(text))
                                    ? [...machinesToApprove, Number.parseInt(text)]
                                    : machinesToApprove
                            );
                        } else {
                            setSelectedMachine((prev) => {
                                return prev?.filter((e) => e.id !== detail.id);
                            });
                            setSelectedItemCount((prev) => {
                                return prev - 1;
                            });
                            setMachinesToApprove(
                                machinesToApprove.filter((e) => {
                                    return e !== Number.parseInt(text);
                                })
                            );
                            setAllCheck(false);
                        }
                    }}
                    checked={machinesToApprove.includes(Number.parseInt(text))}
                    name={detail.id}
                ></Checkbox>
            ),
        },
        {
            title: t("machines.table.machineIdFull"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.review")}>
                        <Link
                            onClick={(event) => event.stopPropagation()}
                            className={styles.tooltipText}
                            to={"/sale/pending-approval/detail?macid=" + detail.id}
                        >
                            <Col>{text}</Col>
                        </Link>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) => <div className={styles.firmName}>{val.name}</div>,
        },
        {
            title: t("machines.table.adDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.forRentAd"),
            dataIndex: "rentalTypes",
            key: "rentalTypes",
            render: (val: [], record: any) => (
                <Space size="middle">
                    {val.length > 0 ? (
                        <Tooltip title={t("common.review")}>
                            <Link
                                onClick={(event) => event.stopPropagation()}
                                className={styles.tooltipText}
                                to={"/rent/machines/view?macid=" + record.id}
                            >
                                <Col>{t("common.review")}</Col>
                            </Link>
                        </Tooltip>
                    ) : (
                        <>-</>
                    )}
                </Space>
            ),
        },
        {
            title: t("common.confirmAndEdit"),
            key: "action",
            render: (text: string, record: any) => (
                <Row gutter={[16, 0]}>
                    <Col
                        className={styles.approveIcon}
                        onClick={async (event) => {
                            event.stopPropagation();
                            await doApproveSaleMachine({ machineIds: [record.id] });
                            await fillMachineList();
                            setMachinesToApprove([]);
                            setAllCheck(false);
                        }}
                    >
                        <Tooltip title={t("common.accept")}>
                            <Approve />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Link
                            onClick={(event) => event.stopPropagation()}
                            to={"/sale/pending-approval/edit?macid=" + record?.id?.toString()}
                        >
                            <Edit />
                        </Link>
                    </Col>
                </Row>
            ),
        },
    ];
    const castToTableModelArray = (data: ExtendedMachineDetailModel[] | null | undefined) => {
        const newList = data?.map((item) => {
            let attachmentsValue = item?.attachments?.map((atc) => {
                return atc.name;
            });

            let cities = item?.serviceRegions?.map((cty) => {
                return cty.name;
            });

            const tableModel: TableModel = {
                id: item.id,
                brandName: item.brand?.name ? item.brand.name : "",
                companyName: item.lessorCompany?.name,
                districtAndCity: cities?.join(", "),
                creationDate: item.creationDate,
                subCategoryName: item.subCategory?.name,
                allAttachments: attachmentsValue?.join(", "),
                ...item,
                ModifiedDate: moment(item.modifiedDate, 'DD.MM.YYYY'),
                CreationDate: moment(item.creationDate, 'DD.MM.YYYY'),
            };
            
            delete tableModel.modifiedDate;
            delete tableModel.creationDate;
            return tableModel;
        });
        return newList;
    };

    const fillMachineList = async () => {
        try {
            const result = await doSearchSaleMachine(
                {
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    filter: filterObj.filterModel,
                    sortAscending: filterObj.sortAscending,
                    sortBy: filterObj.sortBy,
                },
                false
            );
            if (result?.machines !== null && result && result.machines !== undefined) {
                setItemCount(result?.itemCount);
                setMachines(castToTableModelArray(result?.machines));

                setSelectAllIDs((oldVal) => {
                    if (result.machines) return [...result.machines.map((e) => e.id ?? -1)];
                    return [];
                });
            }
        } catch (err: any) {
            message.error(err.toString());
        }
    };

    useEffect(() => {
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        let result: SearchMachineModel | undefined = undefined;

        result = await doSearchSaleMachine(
            {
                pageNo: 1,
                pageSize: itemCount ?? pagination.current,
                filter: undefined,
                sortAscending: filterObj.sortAscending,
                sortBy: filterObj.sortBy,
            },
            false
        );

        if (result?.machines?.length !== undefined && result?.machines?.length > 0) {
            const exportData = await doGetMachineTableData(result?.data, true);
            if (exportData?.length !== undefined && exportData?.length > 0) {
                setAllRows(exportData);
                csvRef.current?.link.click();
            }
        }
    };

    return (
        <ListContainer>
            <OnSalePendingApprovalFilter
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                        sortAscending:params.sortAscending
                    });
                }}
            ></OnSalePendingApprovalFilter>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("onSalePendingApproval.title")} - <strong> ({t("common.total")} {itemCount})</strong></h3>
                    </Col>
                    <Col style={{ justifyItems: "flex-start", float: "right" }}>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <PrimaryButton
                                    onClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                                    style={{ marginRight: "0.5rem", width: "inherit", marginBottom: "0px" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <PlusB style={{ marginRight: "0.5rem" }} />
                                        {t("success.machine.middleButtonText")}
                                    </div>
                                </PrimaryButton>
                            </Col>
                            <Col>
                                <SecondaryButton onClick={exportClick} style={{ width: "initial", marginRight: "0rem", fontWeight: "700" }}>
                                    <CsvDownload style={{ display: "flex" }} />
                                </SecondaryButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between" style={{ marginBottom: "1rem" }}>
                    <Col md={4}>
                        <Select
                            style={{ width: 350 }}
                            placeholder={t("common.sortBy")}
                            allowClear
                            defaultValue={"sortByDesc"}
                            onChange={(val) => {
                                setFilterObj((filter) => {
                                    return {
                                        ...filter,
                                        sortBy: MachineSearchSortField.CreationDate,
                                        sortAscending:val === "sortByAsc",
                                    };
                                });
                            }}
                        >
                            <Option value="sortByDesc">{t("common.sortByDesc")}</Option>
                            <Option value="sortByAsc">{t("common.sortByAsc")}</Option>
                        </Select>
                        <CSVLink filename="machines.csv" ref={csvRef} data={allRows} separator={";"} />
                    </Col>
                    <Col>
                        <Row gutter={[16, 0]}>
                            <Col>
                                <Button
                                    onClick={() => {
                                        if (allCheck) {
                                            setMachinesToApprove([]);
                                            setAllCheck(false);
                                            setSelectedItemCount(0);
                                        } else {
                                            setMachinesToApprove(selectAllIDs);
                                            setAllCheck(true);
                                            setSelectedItemCount(selectAllIDs.length);
                                        }
                                    }}
                                    style={{ paddingInline: "2rem" }}
                                >
                                    <Row gutter={[8, 0]}>
                                        <Col>
                                            <Checkbox checked={allCheck}></Checkbox>
                                        </Col>
                                        <Col style={{ fontWeight: "700" }}>{t("common.selectAll")}</Col>
                                    </Row>
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    disabled={!(selectedItemCount > 0)}
                                    onClick={async () => {
                                        if (selectedItemCount > 0) {
                                            setShowApproveSaleMachinesModal(true);
                                        }
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <Approve />
                                        </Col>
                                        <Col style={{ fontWeight: "700" }}>{t("common.approve.selected")}</Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    <DataTable
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    navigate("/sale/pending-approval/detail?macid=" + record.id);
                                },
                            };
                        }}
                        onChange={handleChange}
                        columns={columns}
                        dataSource={machines}
                        loading={loading}
                        pagination={{ ...pagination, total: itemCount, showSizeChanger: false }}
                        isSecondary
                    />
                }
            </BoxContainer>
            <SelectCompany
                isForSale
                onCloseClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                show={selectcompanyVisible}
            ></SelectCompany>
            <ApproveSaleMachinesModal
                show={showApproveSaleMachinesModal}
                onCloseClick={() => setShowApproveSaleMachinesModal(!showApproveSaleMachinesModal)}
            >
                <Row>
                    <Col style={{ textAlign: "center" }} md={24}>
                        {formatString(t("approve.sale.machines.modal.text"), selectedItemCount)}
                    </Col>
                    <Col md={24}>
                        <Row wrap={false} gutter={[16, 0]}>
                            <Col>
                                <SecondaryButton
                                    onClick={() => {
                                        setShowApproveSaleMachinesModal(false);
                                    }}
                                >
                                    {t("common.dismiss")}
                                </SecondaryButton>
                            </Col>
                            <Col>
                                <PrimaryButton
                                    loading={loading}
                                    onClick={async () => {
                                        let result = await doApproveSaleMachine({ machineIds: machinesToApprove });
                                        await fillMachineList();
                                        setMachinesToApprove([]);
                                        setAllCheck(false);
                                        if (result) {
                                            setShowApproveSaleMachinesModal(false);
                                            setShowIsApprovedSaleMachinesModal(true);
                                        }
                                    }}
                                >
                                    {t("common.accept")}
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ApproveSaleMachinesModal>
            <SuccessApproveSaleMachinesModal
                show={showIsApprovedSaleMachinesModal}
                onCloseClick={() => setShowIsApprovedSaleMachinesModal(!showIsApprovedSaleMachinesModal)}
            >
                <Row align="middle" justify="center">
                    <Col style={{ textAlign: "center" }} md={20}>
                        {formatString(t("success.approve.sale.machines.modal.text"), selectedItemCount)}
                    </Col>
                    <Col md={12}>
                        <PrimaryButton
                            onClick={() => {
                                setShowIsApprovedSaleMachinesModal(false);
                                navigate("/sale/machines");
                            }}
                        >
                            {t("common.ok")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </SuccessApproveSaleMachinesModal>
        </ListContainer>
    );
};

export default OnSalePendingApproval;
